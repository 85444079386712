/* eslint-disable @typescript-eslint/no-misused-promises */
import { faAt, faDownload, faFileImport, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Alert, Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import
{
  namedOperations,
  useDeleteEmployeeUserMutation,
  useInsertUserInviteMutation,
  useListEmployeesByEmployerQuery,
  useProcessUserInvitesMutation,
  User_Invite_Insert_Input
} from 'src/api/generated/graphql';
import ListInvitations from 'src/components/employer/listInvitations';
import CsvReader from 'src/components/ui/csvReader';
import Layout, { ToastVariant } from 'src/components/ui/layout';
import Table from 'src/components/ui/table/table';
import useUser from 'src/helpers/useUser';
import { v4 as uuid } from 'uuid';

type ListEmployeesProps = {
  title: string;
  employerId: string;
};

const columns = [
  {
    field: 'id',
    text: 'ID',
    numeric: true,
    hidden: true,
  },
  {
    field: 'surname',
    text: 'Nachname',
    sort: 'asc',
  },
  {
    field: 'given_name',
    text: 'Vorname',
  },
];

const emailCsvData = [
  ['given_name', 'surname', 'email', 'street', 'houseNumber', 'zipCode', 'locality'],
  ['Max', 'Muster', 'max.muster@example.com', '', '', '', ''],
];

const pdfCsvData = [
  ['given_name', 'surname', 'email', 'street', 'houseNumber', 'zipCode', 'locality'],
  ['Max', 'Muster', '', 'Musterstraße', '1', '12345', 'Musterort'],
];

interface Row
{
  given_name: string;
  surname: string;
  email?: string;
  street?: string;
  houseNumber?: string;
  zipCode?: string;
  locality?: string;
}

interface EmailRow
{
  given_name: string;
  surname: string;
  email: string;
}

interface PdfRow
{
  given_name: string;
  surname: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  locality: string;
}

function ListEmployees({ title, employerId }: ListEmployeesProps)
{
  const navigate = useNavigate();
  const user = useUser();

  const { loading, error, data } = useListEmployeesByEmployerQuery({ variables: { employerId } });

  const [insertUserInvite] = useInsertUserInviteMutation({ refetchQueries: [namedOperations.Query.ListActiveUserInvitesByEmployer] });
  const [processUserInvites] = useProcessUserInvitesMutation();
  const [deleteEmployee] = useDeleteEmployeeUserMutation({ refetchQueries: [namedOperations.Query.ListEmployeesByEmployer] });

  // Local state
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<ToastVariant>();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [importedRows, setImportedRows] = useState<Array<Row>>([]);

  const isRow = (row: unknown): row is Row =>
  {
    return typeof row === 'object' && row !== null
      ? 'given_name' in row && 'surname' in row && 'email' in row && 'street' in row && 'houseNumber' in row && 'zipCode' in row && 'locality' in row
      : false;
  };

  const importRows = (rows: Array<unknown>) =>
  {
    if (!rows.every(isRow))
    {
      toast('Schema der CSV Datei ist nicht korrekt.', true);
      return;
    }
    setImportedRows(rows);
  };

  const isEmailRow = (row: Row): row is EmailRow =>
  {
    return !!row.email;
  };

  const createEmailInvitations = async () =>
  {
    setInProgress(true);
    if (!importedRows.every(isEmailRow))
    {
      toast('Spalte "email" muss für jede Zeile einen Wert enthalten', true);
      finishImport();
      return;
    }

    const userInvites: Array<User_Invite_Insert_Input> = importedRows.map(r =>
    {
      const inviteId = uuid();
      return {
        id: inviteId,
        given_name: r.given_name,
        surname: r.surname,
        email: r.email,
        employer_id: user?.role === 'admin' ? employerId : undefined,
        role: 'employee',
        is_valid: user?.role === 'admin' ? true : undefined,
        type: 0,
      };
    });

    let newInvites;
    try
    {
      newInvites = await createUserInvites(userInvites);
    } catch {
      toast('Fehler beim Erstellen der Einladung', true);
      finishImport();
      return;
    }

    if (!newInvites || newInvites.length < 1)
    {
      toast('Fehler beim Erstellen der Einladung', true);
      finishImport();
      return;
    }

    try
    {
      await processUserInvites({ variables: { ids: newInvites.map(i => i.id as string) } });
    } catch {
      toast('Fehler beim Verarbeiten der Einladung', true);
      return;
    }
    toast('Einladungen erfolgreich erstellt.');
    finishImport();
  };

  const isPdfRow = (row: Row): row is PdfRow =>
  {
    return !(!row.street && !row.houseNumber && !row.zipCode && !row.locality);
  };

  const createPdfInvitations = async () =>
  {
    setInProgress(true);
    if (!importedRows.every(isPdfRow))
    {
      toast('Spalten "street", "houseNumber", "zipCode" und "locality" müssen für jede Zeile einen Wert enthalten', true);
      finishImport();
      return;
    }

    const userInvites: Array<User_Invite_Insert_Input> = importedRows.map(r =>
    {
      const inviteId = uuid();
      const addressId = uuid();
      return {
        id: inviteId,
        given_name: r.given_name,
        surname: r.surname,
        employer_id: user?.role === 'admin' ? employerId : undefined,
        role: 'employee',
        is_valid: user?.role === 'admin' ? true : undefined,
        type: 1,
        address: {
          data: {
            id: addressId,
            user_invite_id: inviteId,
            address_line_1: `${r.given_name} ${r.surname}`,
            address_line_2: `${r.street} ${r.houseNumber}`,
            address_line_3: `${r.zipCode} ${r.locality}`,
            locality: r.locality,
            zip_code: r.zipCode,
          },
        },
      };
    });

    let newInvites;
    try
    {
      newInvites = await createUserInvites(userInvites);
    } catch {
      toast('Fehler beim Erstellen der Einladung', true);
      finishImport();
      return;
    }

    if (!newInvites || newInvites.length < 1)
    {
      toast('Fehler beim Erstellen der Einladung', true);
      finishImport();
      return;
    }

    try
    {
      await processUserInvites({ variables: { ids: newInvites.map(i => i.id as string) } });
    } catch {
      toast('Fehler beim Verarbeiten der Einladung', true);
      return;
    }

    toast('Einladungen erfolgreich erstellt.');
    finishImport();
  };

  const createUserInvites = async (userInvites: Array<User_Invite_Insert_Input>) =>
  {
    const insertInvitesResult = await insertUserInvite({ variables: { userInvites } });
    return insertInvitesResult?.data?.insert_user_invite?.returning;
  };

  const toast = (message: string, error = false): void =>
  {
    setToastVariant(error ? 'danger' : 'success');
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() =>
    {
      setShowToast(false);
      setToastMessage('');
    }, 5000);
  };

  const finishImport = () =>
  {
    setInProgress(false);
    setShowUploadModal(false);
    setImportedRows([]);
  };

  const deleteCallback = async (employeeId: string): Promise<void> =>
  {
    let deleteResult;
    try
    {
      deleteResult = await deleteEmployee({ variables: { employeeId } });
    } catch {
      toast('Teilnehmer Person konnte nicht gelöscht werden', true);
      return;
    }
    if (!deleteResult) return;
    toast('Teilnehmer Person wurde gelöscht.');
  };

  return (
    <>
      <Layout title={title} showToast={showToast} toastMessage={toastMessage} toastVariant={toastVariant}>
        <Modal show={showUploadModal} backdrop="static" keyboard={false}>
          <Modal.Body>
            <Row>
              <CsvReader uploadAccepted={importRows} />
            </Row>
            <Row className="text-center">
              <Col>
                <CSVLink data={emailCsvData} filename="teilnehmer-import-email.csv">
                  <FontAwesomeIcon icon={faDownload} />
                  <span className="button-padding">Beispiel CSV für E-Mail</span>
                </CSVLink>
              </Col>
              <Col>
                <CSVLink data={pdfCsvData} filename="teilnehmer-import-pdf.csv">
                  <FontAwesomeIcon icon={faDownload} />
                  <span className="button-padding">Beispiel CSV für PDF</span>
                </CSVLink>
              </Col>
            </Row>
            {importedRows.length > 0 && (
              <Row>
                {importedRows.length} {importedRows.length > 1 ? 'Einladungen' : 'Einladung'} importiert
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={createEmailInvitations} disabled={importedRows.length < 1}>
              {inProgress && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
              <FontAwesomeIcon icon={faAt} />
              <span className="button-padding">E-Mail</span>
            </Button>
            <Button variant="primary" onClick={createPdfInvitations} disabled={importedRows.length < 1}>
              {inProgress && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
              <FontAwesomeIcon icon={faFilePdf} />
              <span className="button-padding">PDF</span>
            </Button>
          </Modal.Footer>
        </Modal>
        {error && <Alert variant="danger">{error.message}</Alert>}
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!error && !loading && (
          <>
            <Button variant="primary" onClick={() => navigate('inviteEmployee?sendType=email')}>
              <FontAwesomeIcon icon={faAt} />
              <span className="button-padding">Einladen (E-Mail)</span>
            </Button>
            <Button variant="primary" style={{ marginLeft: '0.5vw' }} onClick={() => navigate('inviteEmployee?sendType=pdf')}>
              <FontAwesomeIcon icon={faFilePdf} />
              <span className="button-padding">Einladen (PDF)</span>
            </Button>
            <Button variant="primary" style={{ marginLeft: '0.5vw' }} onClick={() => setShowUploadModal(true)}>
              <FontAwesomeIcon icon={faFileImport} />
              <span className="button-padding">Importieren (CSV)</span>
            </Button>
          </>
        )}
        {data && <Table columns={columns} data={data.Employee} hasDelete deleteCallback={deleteCallback} />}
      </Layout>
      {data && <ListInvitations employerId={employerId} title="Ausstehende Einladungen" />}
    </>
  );
}

export default ListEmployees;
