import {faArrowLeft, faFloppyDisk} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PropsWithChildren, useEffect, useState} from 'react';
import {Button, Image, Modal, Row, Toast, ToastContainer} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import favicon from 'src/media/favicon.ico';

export type ToastVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

type LayoutProps = PropsWithChildren<{
  title: string;
  isForm?: boolean;
  submitButtonText?: string;
  showToast?: boolean;
  toastMessage?: string;
  toastVariant?: ToastVariant;
  onSubmit?(this: void): void;
}>;

function Layout(props: LayoutProps) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const navigate = useNavigate();
  const [showNotSavedModal, setShowNotSavedModal] = useState(false);

  return (
    <>
      <Modal show={showNotSavedModal} backdrop="static" keyboard={false}>
        <Modal.Body>
          <span>Nicht gespeicherte Änderungen gehen verloren!</span>
          <br />
          <span>Trotzdem fortfahren?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNotSavedModal(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={() => navigate(-1)}>
            Fortfahren
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{padding: '25px'}}>
        <div style={{marginBottom: '25px'}}>
          <span className="h4">{props.title}</span>
        </div>
        <div style={{padding: '50px'}} className="bg-white rounded shadow-lg">
          {props.children}
          {props.isForm && (
            <Row>
              <div>
                <Button variant="secondary" className="float-start" onClick={() => setShowNotSavedModal(true)}>
                  <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                  <span className="button-padding">Zurück</span>
                </Button>
                <Button onClick={props.onSubmit} className="float-end">
                  <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>
                  <span className="button-padding">{props.submitButtonText ?? 'Speichern'}</span>
                </Button>
              </div>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer className="p-3 position-fixed" position="bottom-end">
        <Toast style={{border: 'none'}} show={props.showToast ?? false} bg={props.toastVariant}>
          <Toast.Header closeButton={false}>
            <Image src={favicon as string} alt="JUMP! Icon" height="20" width="20px" className="me-2" />
            <strong>JUMP!</strong>
          </Toast.Header>
          <Toast.Body
            className={props.toastVariant === 'dark' || props.toastVariant === 'danger' || props.toastVariant === 'success' ? 'text-white' : ''}>
            {props.toastMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default Layout;
