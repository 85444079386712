import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  float8: any;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  smallint: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'Address';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_line_4?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  employer?: Maybe<Employer>;
  employer_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  event?: Maybe<Event>;
  /** An object relationship */
  event_date?: Maybe<EventDate>;
  event_date_id?: Maybe<Scalars['uuid']>;
  event_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  gym?: Maybe<Gym>;
  gym_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  locality?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geography']>;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user_invite?: Maybe<User_Invite>;
  user_invite_id?: Maybe<Scalars['uuid']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'Address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

export type Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'Address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Address_Max_Order_By>;
  min?: InputMaybe<Address_Min_Order_By>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  address_line_1?: InputMaybe<String_Comparison_Exp>;
  address_line_2?: InputMaybe<String_Comparison_Exp>;
  address_line_3?: InputMaybe<String_Comparison_Exp>;
  address_line_4?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_date?: InputMaybe<EventDate_Bool_Exp>;
  event_date_id?: InputMaybe<Uuid_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  gym?: InputMaybe<Gym_Bool_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_invite?: InputMaybe<User_Invite_Bool_Exp>;
  user_invite_id?: InputMaybe<Uuid_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey'
}

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  address_line_3?: InputMaybe<Scalars['String']>;
  address_line_4?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_date?: InputMaybe<EventDate_Obj_Rel_Insert_Input>;
  event_date_id?: InputMaybe<Scalars['uuid']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  gym?: InputMaybe<Gym_Obj_Rel_Insert_Input>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invite?: InputMaybe<User_Invite_Obj_Rel_Insert_Input>;
  user_invite_id?: InputMaybe<Scalars['uuid']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'Address_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_line_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employer_id?: Maybe<Scalars['uuid']>;
  event_date_id?: Maybe<Scalars['uuid']>;
  event_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_invite_id?: Maybe<Scalars['uuid']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  address_line_1?: InputMaybe<Order_By>;
  address_line_2?: InputMaybe<Order_By>;
  address_line_3?: InputMaybe<Order_By>;
  address_line_4?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  event_date_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_invite_id?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'Address_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_line_4?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employer_id?: Maybe<Scalars['uuid']>;
  event_date_id?: Maybe<Scalars['uuid']>;
  event_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_invite_id?: Maybe<Scalars['uuid']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  address_line_1?: InputMaybe<Order_By>;
  address_line_2?: InputMaybe<Order_By>;
  address_line_3?: InputMaybe<Order_By>;
  address_line_4?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  event_date_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_invite_id?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'Address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  address_line_1?: InputMaybe<Order_By>;
  address_line_2?: InputMaybe<Order_By>;
  address_line_3?: InputMaybe<Order_By>;
  address_line_4?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_id?: InputMaybe<Order_By>;
  event?: InputMaybe<Event_Order_By>;
  event_date?: InputMaybe<EventDate_Order_By>;
  event_date_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  gym?: InputMaybe<Gym_Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_invite?: InputMaybe<User_Invite_Order_By>;
  user_invite_id?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AddressLine_3 = 'address_line_3',
  /** column name */
  AddressLine_4 = 'address_line_4',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  EventDateId = 'event_date_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Location = 'location',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInviteId = 'user_invite_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  address_line_3?: InputMaybe<Scalars['String']>;
  address_line_4?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  event_date_id?: InputMaybe<Scalars['uuid']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invite_id?: InputMaybe<Scalars['uuid']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  address_line_3?: InputMaybe<Scalars['String']>;
  address_line_4?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  event_date_id?: InputMaybe<Scalars['uuid']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invite_id?: InputMaybe<Scalars['uuid']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AddressLine_3 = 'address_line_3',
  /** column name */
  AddressLine_4 = 'address_line_4',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  EventDateId = 'event_date_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Location = 'location',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInviteId = 'user_invite_id',
  /** column name */
  ZipCode = 'zip_code'
}

export type Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};

/** columns and relationships of "article" */
export type Article = {
  __typename?: 'Article';
  /** An object relationship */
  Employer?: Maybe<Employer>;
  /** An object relationship */
  Gym?: Maybe<Gym>;
  content?: Maybe<Scalars['String']>;
  content_images?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  employer_id?: Maybe<Scalars['uuid']>;
  global: Scalars['Boolean'];
  gym_id?: Maybe<Scalars['uuid']>;
  header_image?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "article" */
export type ArticleContent_ImagesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "article" */
export type ArticleHeader_ImageArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "article" */
export type Article_Aggregate = {
  __typename?: 'Article_aggregate';
  aggregate?: Maybe<Article_Aggregate_Fields>;
  nodes: Array<Article>;
};

export type Article_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Article_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Article_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Article_Aggregate_Bool_Exp_Count>;
};

export type Article_Aggregate_Bool_Exp_Bool_And = {
  arguments: Article_Select_Column_Article_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Article_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Article_Select_Column_Article_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Article_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Article_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Article_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "article" */
export type Article_Aggregate_Fields = {
  __typename?: 'Article_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Article_Max_Fields>;
  min?: Maybe<Article_Min_Fields>;
};


/** aggregate fields of "article" */
export type Article_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Article_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "article" */
export type Article_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Article_Max_Order_By>;
  min?: InputMaybe<Article_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Article_Append_Input = {
  content_images?: InputMaybe<Scalars['jsonb']>;
  header_image?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "article" */
export type Article_Arr_Rel_Insert_Input = {
  data: Array<Article_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Article_On_Conflict>;
};

/** Boolean expression to filter rows from the table "article". All fields are combined with a logical 'AND'. */
export type Article_Bool_Exp = {
  Employer?: InputMaybe<Employer_Bool_Exp>;
  Gym?: InputMaybe<Gym_Bool_Exp>;
  _and?: InputMaybe<Array<Article_Bool_Exp>>;
  _not?: InputMaybe<Article_Bool_Exp>;
  _or?: InputMaybe<Array<Article_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  content_images?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  global?: InputMaybe<Boolean_Comparison_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
  header_image?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "article" */
export enum Article_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArticlePkey = 'Article_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Article_Delete_At_Path_Input = {
  content_images?: InputMaybe<Array<Scalars['String']>>;
  header_image?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Article_Delete_Elem_Input = {
  content_images?: InputMaybe<Scalars['Int']>;
  header_image?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Article_Delete_Key_Input = {
  content_images?: InputMaybe<Scalars['String']>;
  header_image?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "article" */
export type Article_Insert_Input = {
  Employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  Gym?: InputMaybe<Gym_Obj_Rel_Insert_Input>;
  content?: InputMaybe<Scalars['String']>;
  content_images?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  global?: InputMaybe<Scalars['Boolean']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  header_image?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Article_Max_Fields = {
  __typename?: 'Article_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employer_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "article" */
export type Article_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Article_Min_Fields = {
  __typename?: 'Article_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employer_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "article" */
export type Article_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "article" */
export type Article_Mutation_Response = {
  __typename?: 'Article_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Article>;
};

/** on_conflict condition type for table "article" */
export type Article_On_Conflict = {
  constraint: Article_Constraint;
  update_columns?: Array<Article_Update_Column>;
  where?: InputMaybe<Article_Bool_Exp>;
};

/** Ordering options when selecting data from "article". */
export type Article_Order_By = {
  Employer?: InputMaybe<Employer_Order_By>;
  Gym?: InputMaybe<Gym_Order_By>;
  content?: InputMaybe<Order_By>;
  content_images?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  global?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  header_image?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: article */
export type Article_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Article_Prepend_Input = {
  content_images?: InputMaybe<Scalars['jsonb']>;
  header_image?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "article" */
export enum Article_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  ContentImages = 'content_images',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  Global = 'global',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  HeaderImage = 'header_image',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Article_aggregate_bool_exp_bool_and_arguments_columns" columns of table "article" */
export enum Article_Select_Column_Article_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Global = 'global'
}

/** select "Article_aggregate_bool_exp_bool_or_arguments_columns" columns of table "article" */
export enum Article_Select_Column_Article_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Global = 'global'
}

/** input type for updating data in table "article" */
export type Article_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  content_images?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  global?: InputMaybe<Scalars['Boolean']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  header_image?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Article" */
export type Article_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Article_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Article_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']>;
  content_images?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  global?: InputMaybe<Scalars['Boolean']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  header_image?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "article" */
export enum Article_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  ContentImages = 'content_images',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  Global = 'global',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  HeaderImage = 'header_image',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Article_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Article_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Article_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Article_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Article_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Article_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Article_Set_Input>;
  where: Article_Bool_Exp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "employee" */
export type Employee = {
  __typename?: 'Employee';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  employee_gym: Array<EmployeeGymCheckin>;
  /** An aggregate relationship */
  employee_gym_aggregate: EmployeeGymCheckin_Aggregate;
  /** An object relationship */
  employer: Employer;
  employer_id: Scalars['uuid'];
  given_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  membership?: Maybe<EmployeeGym>;
  surname?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "employee" */
export type EmployeeEmployee_GymArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGymCheckin_Order_By>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};


/** columns and relationships of "employee" */
export type EmployeeEmployee_Gym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGymCheckin_Order_By>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};

/** columns and relationships of "employee_event_date" */
export type EmployeeEventDate = {
  __typename?: 'EmployeeEventDate';
  /** An object relationship */
  employee: Employee;
  employee_id: Scalars['uuid'];
  /** An object relationship */
  event_date: EventDate;
  event_date_id: Scalars['uuid'];
};

/** aggregated selection of "employee_event_date" */
export type EmployeeEventDate_Aggregate = {
  __typename?: 'EmployeeEventDate_aggregate';
  aggregate?: Maybe<EmployeeEventDate_Aggregate_Fields>;
  nodes: Array<EmployeeEventDate>;
};

export type EmployeeEventDate_Aggregate_Bool_Exp = {
  count?: InputMaybe<EmployeeEventDate_Aggregate_Bool_Exp_Count>;
};

export type EmployeeEventDate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmployeeEventDate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_event_date" */
export type EmployeeEventDate_Aggregate_Fields = {
  __typename?: 'EmployeeEventDate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmployeeEventDate_Max_Fields>;
  min?: Maybe<EmployeeEventDate_Min_Fields>;
};


/** aggregate fields of "employee_event_date" */
export type EmployeeEventDate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_event_date" */
export type EmployeeEventDate_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EmployeeEventDate_Max_Order_By>;
  min?: InputMaybe<EmployeeEventDate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "employee_event_date" */
export type EmployeeEventDate_Arr_Rel_Insert_Input = {
  data: Array<EmployeeEventDate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeeEventDate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "employee_event_date". All fields are combined with a logical 'AND'. */
export type EmployeeEventDate_Bool_Exp = {
  _and?: InputMaybe<Array<EmployeeEventDate_Bool_Exp>>;
  _not?: InputMaybe<EmployeeEventDate_Bool_Exp>;
  _or?: InputMaybe<Array<EmployeeEventDate_Bool_Exp>>;
  employee?: InputMaybe<Employee_Bool_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  event_date?: InputMaybe<EventDate_Bool_Exp>;
  event_date_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee_event_date" */
export enum EmployeeEventDate_Constraint {
  /** unique or primary key constraint on columns "employee_id", "event_date_id" */
  EmployeeEventDatePkey = 'employee_event_date_pkey'
}

/** input type for inserting data into table "employee_event_date" */
export type EmployeeEventDate_Insert_Input = {
  employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  event_date?: InputMaybe<EventDate_Obj_Rel_Insert_Input>;
  event_date_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmployeeEventDate_Max_Fields = {
  __typename?: 'EmployeeEventDate_max_fields';
  employee_id?: Maybe<Scalars['uuid']>;
  event_date_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "employee_event_date" */
export type EmployeeEventDate_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_date_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EmployeeEventDate_Min_Fields = {
  __typename?: 'EmployeeEventDate_min_fields';
  employee_id?: Maybe<Scalars['uuid']>;
  event_date_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "employee_event_date" */
export type EmployeeEventDate_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_date_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee_event_date" */
export type EmployeeEventDate_Mutation_Response = {
  __typename?: 'EmployeeEventDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmployeeEventDate>;
};

/** on_conflict condition type for table "employee_event_date" */
export type EmployeeEventDate_On_Conflict = {
  constraint: EmployeeEventDate_Constraint;
  update_columns?: Array<EmployeeEventDate_Update_Column>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};

/** Ordering options when selecting data from "employee_event_date". */
export type EmployeeEventDate_Order_By = {
  employee?: InputMaybe<Employee_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  event_date?: InputMaybe<EventDate_Order_By>;
  event_date_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee_event_date */
export type EmployeeEventDate_Pk_Columns_Input = {
  employee_id: Scalars['uuid'];
  event_date_id: Scalars['uuid'];
};

/** select columns of table "employee_event_date" */
export enum EmployeeEventDate_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EventDateId = 'event_date_id'
}

/** input type for updating data in table "employee_event_date" */
export type EmployeeEventDate_Set_Input = {
  employee_id?: InputMaybe<Scalars['uuid']>;
  event_date_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "EmployeeEventDate" */
export type EmployeeEventDate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmployeeEventDate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmployeeEventDate_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['uuid']>;
  event_date_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "employee_event_date" */
export enum EmployeeEventDate_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EventDateId = 'event_date_id'
}

export type EmployeeEventDate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmployeeEventDate_Set_Input>;
  where: EmployeeEventDate_Bool_Exp;
};

/** columns and relationships of "employee_gym" */
export type EmployeeGym = {
  __typename?: 'EmployeeGym';
  /** A computed field, executes function "f_employee_gym_checkin_blocked" */
  blocked?: Maybe<Scalars['Boolean']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee: Employee;
  employee_id: Scalars['uuid'];
  /** An object relationship */
  gym: Gym;
  gym_id: Scalars['uuid'];
  last_checkin: Scalars['timestamptz'];
  member_since: Scalars['timestamptz'];
};

/** columns and relationships of "employee_gym_checkin" */
export type EmployeeGymCheckin = {
  __typename?: 'EmployeeGymCheckin';
  /** An object relationship */
  Employee?: Maybe<Employee>;
  date?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id: Scalars['uuid'];
  /** An object relationship */
  gym: Gym;
  gym_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "employee_gym_checkin" */
export type EmployeeGymCheckin_Aggregate = {
  __typename?: 'EmployeeGymCheckin_aggregate';
  aggregate?: Maybe<EmployeeGymCheckin_Aggregate_Fields>;
  nodes: Array<EmployeeGymCheckin>;
};

export type EmployeeGymCheckin_Aggregate_Bool_Exp = {
  count?: InputMaybe<EmployeeGymCheckin_Aggregate_Bool_Exp_Count>;
};

export type EmployeeGymCheckin_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_gym_checkin" */
export type EmployeeGymCheckin_Aggregate_Fields = {
  __typename?: 'EmployeeGymCheckin_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmployeeGymCheckin_Max_Fields>;
  min?: Maybe<EmployeeGymCheckin_Min_Fields>;
};


/** aggregate fields of "employee_gym_checkin" */
export type EmployeeGymCheckin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_gym_checkin" */
export type EmployeeGymCheckin_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EmployeeGymCheckin_Max_Order_By>;
  min?: InputMaybe<EmployeeGymCheckin_Min_Order_By>;
};

/** input type for inserting array relation for remote table "employee_gym_checkin" */
export type EmployeeGymCheckin_Arr_Rel_Insert_Input = {
  data: Array<EmployeeGymCheckin_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeeGymCheckin_On_Conflict>;
};

/** Boolean expression to filter rows from the table "employee_gym_checkin". All fields are combined with a logical 'AND'. */
export type EmployeeGymCheckin_Bool_Exp = {
  Employee?: InputMaybe<Employee_Bool_Exp>;
  _and?: InputMaybe<Array<EmployeeGymCheckin_Bool_Exp>>;
  _not?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
  _or?: InputMaybe<Array<EmployeeGymCheckin_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  gym?: InputMaybe<Gym_Bool_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee_gym_checkin" */
export enum EmployeeGymCheckin_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeGymCheckinPkey = 'employee_gym_checkin_pkey'
}

/** input type for inserting data into table "employee_gym_checkin" */
export type EmployeeGymCheckin_Insert_Input = {
  Employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  date?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  gym?: InputMaybe<Gym_Obj_Rel_Insert_Input>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type EmployeeGymCheckin_Max_Fields = {
  __typename?: 'EmployeeGymCheckin_max_fields';
  date?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "employee_gym_checkin" */
export type EmployeeGymCheckin_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EmployeeGymCheckin_Min_Fields = {
  __typename?: 'EmployeeGymCheckin_min_fields';
  date?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "employee_gym_checkin" */
export type EmployeeGymCheckin_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee_gym_checkin" */
export type EmployeeGymCheckin_Mutation_Response = {
  __typename?: 'EmployeeGymCheckin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmployeeGymCheckin>;
};

/** on_conflict condition type for table "employee_gym_checkin" */
export type EmployeeGymCheckin_On_Conflict = {
  constraint: EmployeeGymCheckin_Constraint;
  update_columns?: Array<EmployeeGymCheckin_Update_Column>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};

/** Ordering options when selecting data from "employee_gym_checkin". */
export type EmployeeGymCheckin_Order_By = {
  Employee?: InputMaybe<Employee_Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  gym?: InputMaybe<Gym_Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee_gym_checkin */
export type EmployeeGymCheckin_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "employee_gym_checkin" */
export enum EmployeeGymCheckin_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "employee_gym_checkin" */
export type EmployeeGymCheckin_Set_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "EmployeeGymCheckin" */
export type EmployeeGymCheckin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmployeeGymCheckin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmployeeGymCheckin_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "employee_gym_checkin" */
export enum EmployeeGymCheckin_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id'
}

export type EmployeeGymCheckin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmployeeGymCheckin_Set_Input>;
  where: EmployeeGymCheckin_Bool_Exp;
};

/** aggregated selection of "employee_gym" */
export type EmployeeGym_Aggregate = {
  __typename?: 'EmployeeGym_aggregate';
  aggregate?: Maybe<EmployeeGym_Aggregate_Fields>;
  nodes: Array<EmployeeGym>;
};

export type EmployeeGym_Aggregate_Bool_Exp = {
  count?: InputMaybe<EmployeeGym_Aggregate_Bool_Exp_Count>;
};

export type EmployeeGym_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EmployeeGym_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_gym" */
export type EmployeeGym_Aggregate_Fields = {
  __typename?: 'EmployeeGym_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmployeeGym_Max_Fields>;
  min?: Maybe<EmployeeGym_Min_Fields>;
};


/** aggregate fields of "employee_gym" */
export type EmployeeGym_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_gym" */
export type EmployeeGym_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EmployeeGym_Max_Order_By>;
  min?: InputMaybe<EmployeeGym_Min_Order_By>;
};

/** input type for inserting array relation for remote table "employee_gym" */
export type EmployeeGym_Arr_Rel_Insert_Input = {
  data: Array<EmployeeGym_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeeGym_On_Conflict>;
};

/** Boolean expression to filter rows from the table "employee_gym". All fields are combined with a logical 'AND'. */
export type EmployeeGym_Bool_Exp = {
  _and?: InputMaybe<Array<EmployeeGym_Bool_Exp>>;
  _not?: InputMaybe<EmployeeGym_Bool_Exp>;
  _or?: InputMaybe<Array<EmployeeGym_Bool_Exp>>;
  blocked?: InputMaybe<Boolean_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employee_Bool_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  gym?: InputMaybe<Gym_Bool_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
  last_checkin?: InputMaybe<Timestamptz_Comparison_Exp>;
  member_since?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee_gym" */
export enum EmployeeGym_Constraint {
  /** unique or primary key constraint on columns "employee_id" */
  EmployeeGymPkey = 'employee_gym_pkey'
}

/** input type for inserting data into table "employee_gym" */
export type EmployeeGym_Insert_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  gym?: InputMaybe<Gym_Obj_Rel_Insert_Input>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  last_checkin?: InputMaybe<Scalars['timestamptz']>;
  member_since?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmployeeGym_Max_Fields = {
  __typename?: 'EmployeeGym_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  last_checkin?: Maybe<Scalars['timestamptz']>;
  member_since?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "employee_gym" */
export type EmployeeGym_Max_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  last_checkin?: InputMaybe<Order_By>;
  member_since?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EmployeeGym_Min_Fields = {
  __typename?: 'EmployeeGym_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  last_checkin?: Maybe<Scalars['timestamptz']>;
  member_since?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "employee_gym" */
export type EmployeeGym_Min_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  last_checkin?: InputMaybe<Order_By>;
  member_since?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee_gym" */
export type EmployeeGym_Mutation_Response = {
  __typename?: 'EmployeeGym_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmployeeGym>;
};

/** input type for inserting object relation for remote table "employee_gym" */
export type EmployeeGym_Obj_Rel_Insert_Input = {
  data: EmployeeGym_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EmployeeGym_On_Conflict>;
};

/** on_conflict condition type for table "employee_gym" */
export type EmployeeGym_On_Conflict = {
  constraint: EmployeeGym_Constraint;
  update_columns?: Array<EmployeeGym_Update_Column>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};

/** Ordering options when selecting data from "employee_gym". */
export type EmployeeGym_Order_By = {
  blocked?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employee_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  gym?: InputMaybe<Gym_Order_By>;
  gym_id?: InputMaybe<Order_By>;
  last_checkin?: InputMaybe<Order_By>;
  member_since?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee_gym */
export type EmployeeGym_Pk_Columns_Input = {
  employee_id: Scalars['uuid'];
};

/** select columns of table "employee_gym" */
export enum EmployeeGym_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  LastCheckin = 'last_checkin',
  /** column name */
  MemberSince = 'member_since'
}

/** input type for updating data in table "employee_gym" */
export type EmployeeGym_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  last_checkin?: InputMaybe<Scalars['timestamptz']>;
  member_since?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "EmployeeGym" */
export type EmployeeGym_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EmployeeGym_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EmployeeGym_Stream_Cursor_Value_Input = {
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  last_checkin?: InputMaybe<Scalars['timestamptz']>;
  member_since?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "employee_gym" */
export enum EmployeeGym_Update_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  LastCheckin = 'last_checkin',
  /** column name */
  MemberSince = 'member_since'
}

export type EmployeeGym_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmployeeGym_Set_Input>;
  where: EmployeeGym_Bool_Exp;
};

/** aggregated selection of "employee" */
export type Employee_Aggregate = {
  __typename?: 'Employee_aggregate';
  aggregate?: Maybe<Employee_Aggregate_Fields>;
  nodes: Array<Employee>;
};

export type Employee_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employee_Aggregate_Bool_Exp_Count>;
};

export type Employee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee" */
export type Employee_Aggregate_Fields = {
  __typename?: 'Employee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Employee_Max_Fields>;
  min?: Maybe<Employee_Min_Fields>;
};


/** aggregate fields of "employee" */
export type Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee" */
export type Employee_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Max_Order_By>;
  min?: InputMaybe<Employee_Min_Order_By>;
};

/** input type for inserting array relation for remote table "employee" */
export type Employee_Arr_Rel_Insert_Input = {
  data: Array<Employee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};

/** Boolean expression to filter rows from the table "employee". All fields are combined with a logical 'AND'. */
export type Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Bool_Exp>>;
  _not?: InputMaybe<Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employee_gym?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
  employee_gym_aggregate?: InputMaybe<EmployeeGymCheckin_Aggregate_Bool_Exp>;
  employer?: InputMaybe<Employer_Bool_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  given_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  membership?: InputMaybe<EmployeeGym_Bool_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee" */
export enum Employee_Constraint {
  /** unique or primary key constraint on columns "email" */
  EmployeeEmailKey = 'employee_email_key',
  /** unique or primary key constraint on columns "id" */
  EmployeePkey = 'employee_pkey'
}

/** input type for inserting data into table "employee" */
export type Employee_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employee_gym?: InputMaybe<EmployeeGymCheckin_Arr_Rel_Insert_Input>;
  employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  given_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  membership?: InputMaybe<EmployeeGym_Obj_Rel_Insert_Input>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Employee_Max_Fields = {
  __typename?: 'Employee_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "employee" */
export type Employee_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Min_Fields = {
  __typename?: 'Employee_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  surname?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "employee" */
export type Employee_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee" */
export type Employee_Mutation_Response = {
  __typename?: 'Employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee>;
};

/** input type for inserting object relation for remote table "employee" */
export type Employee_Obj_Rel_Insert_Input = {
  data: Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};

/** on_conflict condition type for table "employee" */
export type Employee_On_Conflict = {
  constraint: Employee_Constraint;
  update_columns?: Array<Employee_Update_Column>;
  where?: InputMaybe<Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "employee". */
export type Employee_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employee_gym_aggregate?: InputMaybe<EmployeeGymCheckin_Aggregate_Order_By>;
  employer?: InputMaybe<Employer_Order_By>;
  employer_id?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership?: InputMaybe<EmployeeGym_Order_By>;
  surname?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee */
export type Employee_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "employee" */
export enum Employee_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  Id = 'id',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "employee" */
export type Employee_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  given_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Employee" */
export type Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  given_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  surname?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "employee" */
export enum Employee_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  Id = 'id',
  /** column name */
  Surname = 'surname',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Employee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Set_Input>;
  where: Employee_Bool_Exp;
};

/** columns and relationships of "employer" */
export type Employer = {
  __typename?: 'Employer';
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  /** An array relationship */
  articles: Array<Article>;
  /** An aggregate relationship */
  articles_aggregate: Article_Aggregate;
  contact_given_name?: Maybe<Scalars['String']>;
  contact_surname?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  /** An array relationship */
  employees: Array<Employee>;
  /** An aggregate relationship */
  employees_aggregate: Employee_Aggregate;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  homepage_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['jsonb']>;
  logo_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_invite_archive_blob_url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "employer" */
export type EmployerAddressesArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerArticlesArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerArticles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerEmployees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "employer" */
export type EmployerImageArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "employer" */
export type Employer_Aggregate = {
  __typename?: 'Employer_aggregate';
  aggregate?: Maybe<Employer_Aggregate_Fields>;
  nodes: Array<Employer>;
};

/** aggregate fields of "employer" */
export type Employer_Aggregate_Fields = {
  __typename?: 'Employer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Employer_Max_Fields>;
  min?: Maybe<Employer_Min_Fields>;
};


/** aggregate fields of "employer" */
export type Employer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Employer_Append_Input = {
  image?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "employer". All fields are combined with a logical 'AND'. */
export type Employer_Bool_Exp = {
  _and?: InputMaybe<Array<Employer_Bool_Exp>>;
  _not?: InputMaybe<Employer_Bool_Exp>;
  _or?: InputMaybe<Array<Employer_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  articles?: InputMaybe<Article_Bool_Exp>;
  articles_aggregate?: InputMaybe<Article_Aggregate_Bool_Exp>;
  contact_given_name?: InputMaybe<String_Comparison_Exp>;
  contact_surname?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  employees?: InputMaybe<Employee_Bool_Exp>;
  employees_aggregate?: InputMaybe<Employee_Aggregate_Bool_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  homepage_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<Jsonb_Comparison_Exp>;
  logo_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_invite_archive_blob_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "employer" */
export enum Employer_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployerPkey = 'Employer_pkey',
  /** unique or primary key constraint on columns "address_id" */
  EmployerAddressIdKey = 'employer_address_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Employer_Delete_At_Path_Input = {
  image?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Employer_Delete_Elem_Input = {
  image?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Employer_Delete_Key_Input = {
  image?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "employer" */
export type Employer_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  articles?: InputMaybe<Article_Arr_Rel_Insert_Input>;
  contact_given_name?: InputMaybe<Scalars['String']>;
  contact_surname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  employees?: InputMaybe<Employee_Arr_Rel_Insert_Input>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  homepage_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invite_archive_blob_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Employer_Max_Fields = {
  __typename?: 'Employer_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  contact_given_name?: Maybe<Scalars['String']>;
  contact_surname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  homepage_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_invite_archive_blob_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Employer_Min_Fields = {
  __typename?: 'Employer_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  contact_given_name?: Maybe<Scalars['String']>;
  contact_surname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  homepage_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_invite_archive_blob_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "employer" */
export type Employer_Mutation_Response = {
  __typename?: 'Employer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employer>;
};

/** input type for inserting object relation for remote table "employer" */
export type Employer_Obj_Rel_Insert_Input = {
  data: Employer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employer_On_Conflict>;
};

/** on_conflict condition type for table "employer" */
export type Employer_On_Conflict = {
  constraint: Employer_Constraint;
  update_columns?: Array<Employer_Update_Column>;
  where?: InputMaybe<Employer_Bool_Exp>;
};

/** Ordering options when selecting data from "employer". */
export type Employer_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  articles_aggregate?: InputMaybe<Article_Aggregate_Order_By>;
  contact_given_name?: InputMaybe<Order_By>;
  contact_surname?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  employees_aggregate?: InputMaybe<Employee_Aggregate_Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  homepage_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_invite_archive_blob_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employer */
export type Employer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Employer_Prepend_Input = {
  image?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "employer" */
export enum Employer_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ContactGivenName = 'contact_given_name',
  /** column name */
  ContactSurname = 'contact_surname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  HomepageUrl = 'homepage_url',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInviteArchiveBlobUrl = 'user_invite_archive_blob_url'
}

/** input type for updating data in table "employer" */
export type Employer_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  contact_given_name?: InputMaybe<Scalars['String']>;
  contact_surname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  homepage_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invite_archive_blob_url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "Employer" */
export type Employer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employer_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  contact_given_name?: InputMaybe<Scalars['String']>;
  contact_surname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  homepage_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invite_archive_blob_url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "employer" */
export enum Employer_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ContactGivenName = 'contact_given_name',
  /** column name */
  ContactSurname = 'contact_surname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  HomepageUrl = 'homepage_url',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInviteArchiveBlobUrl = 'user_invite_archive_blob_url'
}

export type Employer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Employer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Employer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Employer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Employer_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Employer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employer_Set_Input>;
  where: Employer_Bool_Exp;
};

/** columns and relationships of "event" */
export type Event = {
  __typename?: 'Event';
  /** An object relationship */
  Employer?: Maybe<Employer>;
  /** An array relationship */
  EventDates: Array<EventDate>;
  /** An aggregate relationship */
  EventDates_aggregate: EventDate_Aggregate;
  /** An object relationship */
  Gym?: Maybe<Gym>;
  /** An array relationship */
  NextEventDates: Array<V_Event_Date>;
  /** An aggregate relationship */
  NextEventDates_aggregate: V_Event_Date_Aggregate;
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  employer_id?: Maybe<Scalars['uuid']>;
  global: Scalars['Boolean'];
  gym_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['jsonb']>;
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tags: Scalars['jsonb'];
  /** A computed field, executes function "event_tags_agg" */
  tags_agg?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "event" */
export type EventEventDatesArgs = {
  distinct_on?: InputMaybe<Array<EventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDate_Order_By>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEventDates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDate_Order_By>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventNextEventDatesArgs = {
  distinct_on?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<V_Event_Date_Order_By>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventNextEventDates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<V_Event_Date_Order_By>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventImageArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "event" */
export type EventTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "event_date" */
export type EventDate = {
  __typename?: 'EventDate';
  /** An object relationship */
  Event: Event;
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  /** An array relationship */
  employee_event_dates: Array<EmployeeEventDate>;
  /** An aggregate relationship */
  employee_event_dates_aggregate: EmployeeEventDate_Aggregate;
  event_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** A computed field, executes function "event_future" */
  is_future?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "event_next_date" */
  next_date?: Maybe<Scalars['timestamptz']>;
  rrule?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "event_date" */
export type EventDateEmployee_Event_DatesArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


/** columns and relationships of "event_date" */
export type EventDateEmployee_Event_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};

/** aggregated selection of "event_date" */
export type EventDate_Aggregate = {
  __typename?: 'EventDate_aggregate';
  aggregate?: Maybe<EventDate_Aggregate_Fields>;
  nodes: Array<EventDate>;
};

export type EventDate_Aggregate_Bool_Exp = {
  count?: InputMaybe<EventDate_Aggregate_Bool_Exp_Count>;
};

export type EventDate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EventDate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventDate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_date" */
export type EventDate_Aggregate_Fields = {
  __typename?: 'EventDate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EventDate_Max_Fields>;
  min?: Maybe<EventDate_Min_Fields>;
};


/** aggregate fields of "event_date" */
export type EventDate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<EventDate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_date" */
export type EventDate_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<EventDate_Max_Order_By>;
  min?: InputMaybe<EventDate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_date" */
export type EventDate_Arr_Rel_Insert_Input = {
  data: Array<EventDate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<EventDate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_date". All fields are combined with a logical 'AND'. */
export type EventDate_Bool_Exp = {
  Event?: InputMaybe<Event_Bool_Exp>;
  _and?: InputMaybe<Array<EventDate_Bool_Exp>>;
  _not?: InputMaybe<EventDate_Bool_Exp>;
  _or?: InputMaybe<Array<EventDate_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee_event_dates?: InputMaybe<EmployeeEventDate_Bool_Exp>;
  employee_event_dates_aggregate?: InputMaybe<EmployeeEventDate_Aggregate_Bool_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_future?: InputMaybe<Boolean_Comparison_Exp>;
  next_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  rrule?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_date" */
export enum EventDate_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventDatePkey = 'EventDate_pkey',
  /** unique or primary key constraint on columns "address_id" */
  EventDateAddressIdKey = 'event_date_address_id_key'
}

/** input type for inserting data into table "event_date" */
export type EventDate_Insert_Input = {
  Event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  employee_event_dates?: InputMaybe<EmployeeEventDate_Arr_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  rrule?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EventDate_Max_Fields = {
  __typename?: 'EventDate_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "event_date" */
export type EventDate_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type EventDate_Min_Fields = {
  __typename?: 'EventDate_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  rrule?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "event_date" */
export type EventDate_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_date" */
export type EventDate_Mutation_Response = {
  __typename?: 'EventDate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EventDate>;
};

/** input type for inserting object relation for remote table "event_date" */
export type EventDate_Obj_Rel_Insert_Input = {
  data: EventDate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<EventDate_On_Conflict>;
};

/** on_conflict condition type for table "event_date" */
export type EventDate_On_Conflict = {
  constraint: EventDate_Constraint;
  update_columns?: Array<EventDate_Update_Column>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};

/** Ordering options when selecting data from "event_date". */
export type EventDate_Order_By = {
  Event?: InputMaybe<Event_Order_By>;
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  employee_event_dates_aggregate?: InputMaybe<EmployeeEventDate_Aggregate_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_future?: InputMaybe<Order_By>;
  next_date?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_date */
export type EventDate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "event_date" */
export enum EventDate_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "event_date" */
export type EventDate_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  rrule?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "EventDate" */
export type EventDate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EventDate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EventDate_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  rrule?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "event_date" */
export enum EventDate_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type EventDate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventDate_Set_Input>;
  where: EventDate_Bool_Exp;
};

/** aggregated selection of "event" */
export type Event_Aggregate = {
  __typename?: 'Event_aggregate';
  aggregate?: Maybe<Event_Aggregate_Fields>;
  nodes: Array<Event>;
};

export type Event_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Event_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Event_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Event_Aggregate_Bool_Exp_Count>;
};

export type Event_Aggregate_Bool_Exp_Bool_And = {
  arguments: Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Event_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event" */
export type Event_Aggregate_Fields = {
  __typename?: 'Event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Max_Fields>;
  min?: Maybe<Event_Min_Fields>;
};


/** aggregate fields of "event" */
export type Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event" */
export type Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Max_Order_By>;
  min?: InputMaybe<Event_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Append_Input = {
  image?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "event" */
export type Event_Arr_Rel_Insert_Input = {
  data: Array<Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  Employer?: InputMaybe<Employer_Bool_Exp>;
  EventDates?: InputMaybe<EventDate_Bool_Exp>;
  EventDates_aggregate?: InputMaybe<EventDate_Aggregate_Bool_Exp>;
  Gym?: InputMaybe<Gym_Bool_Exp>;
  NextEventDates?: InputMaybe<V_Event_Date_Bool_Exp>;
  NextEventDates_aggregate?: InputMaybe<V_Event_Date_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Event_Bool_Exp>>;
  _not?: InputMaybe<Event_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  global?: InputMaybe<Boolean_Comparison_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<Jsonb_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  tags_agg?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event" */
export enum Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventPkey = 'Event_pkey',
  /** unique or primary key constraint on columns "address_id" */
  EventAddressIdKey = 'event_address_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Delete_At_Path_Input = {
  image?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Delete_Elem_Input = {
  image?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Delete_Key_Input = {
  image?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "event" */
export type Event_Insert_Input = {
  Employer?: InputMaybe<Employer_Obj_Rel_Insert_Input>;
  EventDates?: InputMaybe<EventDate_Arr_Rel_Insert_Input>;
  Gym?: InputMaybe<Gym_Obj_Rel_Insert_Input>;
  NextEventDates?: InputMaybe<V_Event_Date_Arr_Rel_Insert_Input>;
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  global?: InputMaybe<Scalars['Boolean']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Max_Fields = {
  __typename?: 'Event_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "event" */
export type Event_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Min_Fields = {
  __typename?: 'Event_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "event" */
export type Event_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event" */
export type Event_Mutation_Response = {
  __typename?: 'Event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event>;
};

/** input type for inserting object relation for remote table "event" */
export type Event_Obj_Rel_Insert_Input = {
  data: Event_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_On_Conflict>;
};

/** on_conflict condition type for table "event" */
export type Event_On_Conflict = {
  constraint: Event_Constraint;
  update_columns?: Array<Event_Update_Column>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** Ordering options when selecting data from "event". */
export type Event_Order_By = {
  Employer?: InputMaybe<Employer_Order_By>;
  EventDates_aggregate?: InputMaybe<EventDate_Aggregate_Order_By>;
  Gym?: InputMaybe<Gym_Order_By>;
  NextEventDates_aggregate?: InputMaybe<V_Event_Date_Aggregate_Order_By>;
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  global?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  tags_agg?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event */
export type Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Prepend_Input = {
  image?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "event" */
export enum Event_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  Global = 'global',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "Event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "event" */
export enum Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Global = 'global'
}

/** select "Event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "event" */
export enum Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Global = 'global'
}

/** input type for updating data in table "event" */
export type Event_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  global?: InputMaybe<Scalars['Boolean']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "Event" */
export type Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  global?: InputMaybe<Scalars['Boolean']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  image_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "event" */
export enum Event_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  Global = 'global',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Set_Input>;
  where: Event_Bool_Exp;
};

export type GetContainerUriOutput = {
  __typename?: 'GetContainerUriOutput';
  blobName: Scalars['String'];
  sasUri: Scalars['String'];
};

export type GetUsersOutput = {
  __typename?: 'GetUsersOutput';
  users: Array<Maybe<User>>;
};

/** columns and relationships of "gym" */
export type Gym = {
  __typename?: 'Gym';
  /** An array relationship */
  Articles: Array<Article>;
  /** An aggregate relationship */
  Articles_aggregate: Article_Aggregate;
  /** An array relationship */
  Events: Array<Event>;
  /** An aggregate relationship */
  Events_aggregate: Event_Aggregate;
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  background_image?: Maybe<Scalars['jsonb']>;
  background_image_url?: Maybe<Scalars['String']>;
  billing_rate?: Maybe<Scalars['float8']>;
  checkin_code_blob_url?: Maybe<Scalars['String']>;
  contact_given_name?: Maybe<Scalars['String']>;
  contact_surname?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  /** An array relationship */
  employee_gyms: Array<EmployeeGym>;
  /** An aggregate relationship */
  employee_gyms_aggregate: EmployeeGym_Aggregate;
  group?: Maybe<Scalars['String']>;
  homepage_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['jsonb']>;
  logo_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  tags: Scalars['jsonb'];
  /** A computed field, executes function "gym_tags_agg" */
  tags_agg?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "gym" */
export type GymArticlesArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


/** columns and relationships of "gym" */
export type GymArticles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


/** columns and relationships of "gym" */
export type GymEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "gym" */
export type GymEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "gym" */
export type GymBackground_ImageArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "gym" */
export type GymEmployee_GymsArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGym_Order_By>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


/** columns and relationships of "gym" */
export type GymEmployee_Gyms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGym_Order_By>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


/** columns and relationships of "gym" */
export type GymImageArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "gym" */
export type GymTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "v_gym_distance" */
export type GymDistance = {
  __typename?: 'GymDistance';
  /** An object relationship */
  Gym?: Maybe<Gym>;
  distance?: Maybe<Scalars['float8']>;
  gym_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "v_gym_distance" */
export type GymDistance_Aggregate = {
  __typename?: 'GymDistance_aggregate';
  aggregate?: Maybe<GymDistance_Aggregate_Fields>;
  nodes: Array<GymDistance>;
};

/** aggregate fields of "v_gym_distance" */
export type GymDistance_Aggregate_Fields = {
  __typename?: 'GymDistance_aggregate_fields';
  avg?: Maybe<GymDistance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GymDistance_Max_Fields>;
  min?: Maybe<GymDistance_Min_Fields>;
  stddev?: Maybe<GymDistance_Stddev_Fields>;
  stddev_pop?: Maybe<GymDistance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GymDistance_Stddev_Samp_Fields>;
  sum?: Maybe<GymDistance_Sum_Fields>;
  var_pop?: Maybe<GymDistance_Var_Pop_Fields>;
  var_samp?: Maybe<GymDistance_Var_Samp_Fields>;
  variance?: Maybe<GymDistance_Variance_Fields>;
};


/** aggregate fields of "v_gym_distance" */
export type GymDistance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GymDistance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GymDistance_Avg_Fields = {
  __typename?: 'GymDistance_avg_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "v_gym_distance". All fields are combined with a logical 'AND'. */
export type GymDistance_Bool_Exp = {
  Gym?: InputMaybe<Gym_Bool_Exp>;
  _and?: InputMaybe<Array<GymDistance_Bool_Exp>>;
  _not?: InputMaybe<GymDistance_Bool_Exp>;
  _or?: InputMaybe<Array<GymDistance_Bool_Exp>>;
  distance?: InputMaybe<Float8_Comparison_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "v_gym_distance" */
export type GymDistance_Inc_Input = {
  distance?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "v_gym_distance" */
export type GymDistance_Insert_Input = {
  Gym?: InputMaybe<Gym_Obj_Rel_Insert_Input>;
  distance?: InputMaybe<Scalars['float8']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GymDistance_Max_Fields = {
  __typename?: 'GymDistance_max_fields';
  distance?: Maybe<Scalars['float8']>;
  gym_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type GymDistance_Min_Fields = {
  __typename?: 'GymDistance_min_fields';
  distance?: Maybe<Scalars['float8']>;
  gym_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "v_gym_distance" */
export type GymDistance_Mutation_Response = {
  __typename?: 'GymDistance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GymDistance>;
};

/** Ordering options when selecting data from "v_gym_distance". */
export type GymDistance_Order_By = {
  Gym?: InputMaybe<Gym_Order_By>;
  distance?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
};

/** select columns of table "v_gym_distance" */
export enum GymDistance_Select_Column {
  /** column name */
  Distance = 'distance',
  /** column name */
  GymId = 'gym_id'
}

/** input type for updating data in table "v_gym_distance" */
export type GymDistance_Set_Input = {
  distance?: InputMaybe<Scalars['float8']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GymDistance_Stddev_Fields = {
  __typename?: 'GymDistance_stddev_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GymDistance_Stddev_Pop_Fields = {
  __typename?: 'GymDistance_stddev_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GymDistance_Stddev_Samp_Fields = {
  __typename?: 'GymDistance_stddev_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "GymDistance" */
export type GymDistance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GymDistance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GymDistance_Stream_Cursor_Value_Input = {
  distance?: InputMaybe<Scalars['float8']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type GymDistance_Sum_Fields = {
  __typename?: 'GymDistance_sum_fields';
  distance?: Maybe<Scalars['float8']>;
};

export type GymDistance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GymDistance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GymDistance_Set_Input>;
  where: GymDistance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type GymDistance_Var_Pop_Fields = {
  __typename?: 'GymDistance_var_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GymDistance_Var_Samp_Fields = {
  __typename?: 'GymDistance_var_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GymDistance_Variance_Fields = {
  __typename?: 'GymDistance_variance_fields';
  distance?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "gym" */
export type Gym_Aggregate = {
  __typename?: 'Gym_aggregate';
  aggregate?: Maybe<Gym_Aggregate_Fields>;
  nodes: Array<Gym>;
};

/** aggregate fields of "gym" */
export type Gym_Aggregate_Fields = {
  __typename?: 'Gym_aggregate_fields';
  avg?: Maybe<Gym_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gym_Max_Fields>;
  min?: Maybe<Gym_Min_Fields>;
  stddev?: Maybe<Gym_Stddev_Fields>;
  stddev_pop?: Maybe<Gym_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gym_Stddev_Samp_Fields>;
  sum?: Maybe<Gym_Sum_Fields>;
  var_pop?: Maybe<Gym_Var_Pop_Fields>;
  var_samp?: Maybe<Gym_Var_Samp_Fields>;
  variance?: Maybe<Gym_Variance_Fields>;
};


/** aggregate fields of "gym" */
export type Gym_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gym_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gym_Append_Input = {
  background_image?: InputMaybe<Scalars['jsonb']>;
  image?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Gym_Avg_Fields = {
  __typename?: 'Gym_avg_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gym". All fields are combined with a logical 'AND'. */
export type Gym_Bool_Exp = {
  Articles?: InputMaybe<Article_Bool_Exp>;
  Articles_aggregate?: InputMaybe<Article_Aggregate_Bool_Exp>;
  Events?: InputMaybe<Event_Bool_Exp>;
  Events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Gym_Bool_Exp>>;
  _not?: InputMaybe<Gym_Bool_Exp>;
  _or?: InputMaybe<Array<Gym_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  background_image?: InputMaybe<Jsonb_Comparison_Exp>;
  background_image_url?: InputMaybe<String_Comparison_Exp>;
  billing_rate?: InputMaybe<Float8_Comparison_Exp>;
  checkin_code_blob_url?: InputMaybe<String_Comparison_Exp>;
  contact_given_name?: InputMaybe<String_Comparison_Exp>;
  contact_surname?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  employee_gyms?: InputMaybe<EmployeeGym_Bool_Exp>;
  employee_gyms_aggregate?: InputMaybe<EmployeeGym_Aggregate_Bool_Exp>;
  group?: InputMaybe<String_Comparison_Exp>;
  homepage_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<Jsonb_Comparison_Exp>;
  logo_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  tags_agg?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gym" */
export enum Gym_Constraint {
  /** unique or primary key constraint on columns "id" */
  GymPkey = 'Gym_pkey',
  /** unique or primary key constraint on columns "address_id" */
  GymAddressIdKey = 'gym_address_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gym_Delete_At_Path_Input = {
  background_image?: InputMaybe<Array<Scalars['String']>>;
  image?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gym_Delete_Elem_Input = {
  background_image?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gym_Delete_Key_Input = {
  background_image?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "gym" */
export type Gym_Inc_Input = {
  billing_rate?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "gym" */
export type Gym_Insert_Input = {
  Articles?: InputMaybe<Article_Arr_Rel_Insert_Input>;
  Events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  background_image?: InputMaybe<Scalars['jsonb']>;
  background_image_url?: InputMaybe<Scalars['String']>;
  billing_rate?: InputMaybe<Scalars['float8']>;
  checkin_code_blob_url?: InputMaybe<Scalars['String']>;
  contact_given_name?: InputMaybe<Scalars['String']>;
  contact_surname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  employee_gyms?: InputMaybe<EmployeeGym_Arr_Rel_Insert_Input>;
  group?: InputMaybe<Scalars['String']>;
  homepage_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gym_Max_Fields = {
  __typename?: 'Gym_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  background_image_url?: Maybe<Scalars['String']>;
  billing_rate?: Maybe<Scalars['float8']>;
  checkin_code_blob_url?: Maybe<Scalars['String']>;
  contact_given_name?: Maybe<Scalars['String']>;
  contact_surname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  homepage_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gym_Min_Fields = {
  __typename?: 'Gym_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  background_image_url?: Maybe<Scalars['String']>;
  billing_rate?: Maybe<Scalars['float8']>;
  checkin_code_blob_url?: Maybe<Scalars['String']>;
  contact_given_name?: Maybe<Scalars['String']>;
  contact_surname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  homepage_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gym" */
export type Gym_Mutation_Response = {
  __typename?: 'Gym_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gym>;
};

/** input type for inserting object relation for remote table "gym" */
export type Gym_Obj_Rel_Insert_Input = {
  data: Gym_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Gym_On_Conflict>;
};

/** on_conflict condition type for table "gym" */
export type Gym_On_Conflict = {
  constraint: Gym_Constraint;
  update_columns?: Array<Gym_Update_Column>;
  where?: InputMaybe<Gym_Bool_Exp>;
};

/** Ordering options when selecting data from "gym". */
export type Gym_Order_By = {
  Articles_aggregate?: InputMaybe<Article_Aggregate_Order_By>;
  Events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  background_image?: InputMaybe<Order_By>;
  background_image_url?: InputMaybe<Order_By>;
  billing_rate?: InputMaybe<Order_By>;
  checkin_code_blob_url?: InputMaybe<Order_By>;
  contact_given_name?: InputMaybe<Order_By>;
  contact_surname?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  employee_gyms_aggregate?: InputMaybe<EmployeeGym_Aggregate_Order_By>;
  group?: InputMaybe<Order_By>;
  homepage_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  tags_agg?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gym */
export type Gym_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gym_Prepend_Input = {
  background_image?: InputMaybe<Scalars['jsonb']>;
  image?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "gym" */
export enum Gym_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  BillingRate = 'billing_rate',
  /** column name */
  CheckinCodeBlobUrl = 'checkin_code_blob_url',
  /** column name */
  ContactGivenName = 'contact_given_name',
  /** column name */
  ContactSurname = 'contact_surname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Group = 'group',
  /** column name */
  HomepageUrl = 'homepage_url',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gym" */
export type Gym_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  background_image?: InputMaybe<Scalars['jsonb']>;
  background_image_url?: InputMaybe<Scalars['String']>;
  billing_rate?: InputMaybe<Scalars['float8']>;
  checkin_code_blob_url?: InputMaybe<Scalars['String']>;
  contact_given_name?: InputMaybe<Scalars['String']>;
  contact_surname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  homepage_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gym_Stddev_Fields = {
  __typename?: 'Gym_stddev_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gym_Stddev_Pop_Fields = {
  __typename?: 'Gym_stddev_pop_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gym_Stddev_Samp_Fields = {
  __typename?: 'Gym_stddev_samp_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Gym" */
export type Gym_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gym_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gym_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  background_image?: InputMaybe<Scalars['jsonb']>;
  background_image_url?: InputMaybe<Scalars['String']>;
  billing_rate?: InputMaybe<Scalars['float8']>;
  checkin_code_blob_url?: InputMaybe<Scalars['String']>;
  contact_given_name?: InputMaybe<Scalars['String']>;
  contact_surname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  homepage_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['jsonb']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Gym_Sum_Fields = {
  __typename?: 'Gym_sum_fields';
  billing_rate?: Maybe<Scalars['float8']>;
};

/** update columns of table "gym" */
export enum Gym_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  BackgroundImageUrl = 'background_image_url',
  /** column name */
  BillingRate = 'billing_rate',
  /** column name */
  CheckinCodeBlobUrl = 'checkin_code_blob_url',
  /** column name */
  ContactGivenName = 'contact_given_name',
  /** column name */
  ContactSurname = 'contact_surname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  Group = 'group',
  /** column name */
  HomepageUrl = 'homepage_url',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Tags = 'tags',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gym_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Gym_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Gym_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Gym_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Gym_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gym_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Gym_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gym_Set_Input>;
  where: Gym_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gym_Var_Pop_Fields = {
  __typename?: 'Gym_var_pop_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gym_Var_Samp_Fields = {
  __typename?: 'Gym_var_samp_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gym_Variance_Fields = {
  __typename?: 'Gym_variance_fields';
  billing_rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Output = {
  __typename?: 'Output';
  success: Scalars['Boolean'];
};

export type SendUserFeedbackEmailInfoInput = {
  appBuildNumber?: InputMaybe<Scalars['String']>;
  appVersion?: InputMaybe<Scalars['String']>;
  device?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
};

export type SendUserFeedbackEmailSenderInput = {
  givenName: Scalars['String'];
  id: Scalars['uuid'];
  surname: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  givenName: Scalars['String'];
  id: Scalars['String'];
  surname: Scalars['String'];
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "employee_gym_checkin_by_month" */
export type Employee_Gym_Checkin_By_Month = {
  __typename?: 'employee_gym_checkin_by_month';
  count?: Maybe<Scalars['bigint']>;
  employee?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['uuid']>;
  employer?: Maybe<Scalars['String']>;
  employerId?: Maybe<Scalars['uuid']>;
  gym?: Maybe<Scalars['String']>;
  gymId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "employee_gym_checkin_by_month" */
export type Employee_Gym_Checkin_By_Month_Aggregate = {
  __typename?: 'employee_gym_checkin_by_month_aggregate';
  aggregate?: Maybe<Employee_Gym_Checkin_By_Month_Aggregate_Fields>;
  nodes: Array<Employee_Gym_Checkin_By_Month>;
};

/** aggregate fields of "employee_gym_checkin_by_month" */
export type Employee_Gym_Checkin_By_Month_Aggregate_Fields = {
  __typename?: 'employee_gym_checkin_by_month_aggregate_fields';
  avg?: Maybe<Employee_Gym_Checkin_By_Month_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Gym_Checkin_By_Month_Max_Fields>;
  min?: Maybe<Employee_Gym_Checkin_By_Month_Min_Fields>;
  stddev?: Maybe<Employee_Gym_Checkin_By_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Gym_Checkin_By_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Gym_Checkin_By_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Gym_Checkin_By_Month_Sum_Fields>;
  var_pop?: Maybe<Employee_Gym_Checkin_By_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Gym_Checkin_By_Month_Var_Samp_Fields>;
  variance?: Maybe<Employee_Gym_Checkin_By_Month_Variance_Fields>;
};


/** aggregate fields of "employee_gym_checkin_by_month" */
export type Employee_Gym_Checkin_By_Month_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Employee_Gym_Checkin_By_Month_Avg_Fields = {
  __typename?: 'employee_gym_checkin_by_month_avg_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employee_gym_checkin_by_month". All fields are combined with a logical 'AND'. */
export type Employee_Gym_Checkin_By_Month_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Bool_Exp>>;
  _not?: InputMaybe<Employee_Gym_Checkin_By_Month_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  employee?: InputMaybe<String_Comparison_Exp>;
  employeeId?: InputMaybe<Uuid_Comparison_Exp>;
  employer?: InputMaybe<String_Comparison_Exp>;
  employerId?: InputMaybe<Uuid_Comparison_Exp>;
  gym?: InputMaybe<String_Comparison_Exp>;
  gymId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  month?: InputMaybe<Float8_Comparison_Exp>;
  year?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Employee_Gym_Checkin_By_Month_Max_Fields = {
  __typename?: 'employee_gym_checkin_by_month_max_fields';
  count?: Maybe<Scalars['bigint']>;
  employee?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['uuid']>;
  employer?: Maybe<Scalars['String']>;
  employerId?: Maybe<Scalars['uuid']>;
  gym?: Maybe<Scalars['String']>;
  gymId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Employee_Gym_Checkin_By_Month_Min_Fields = {
  __typename?: 'employee_gym_checkin_by_month_min_fields';
  count?: Maybe<Scalars['bigint']>;
  employee?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['uuid']>;
  employer?: Maybe<Scalars['String']>;
  employerId?: Maybe<Scalars['uuid']>;
  gym?: Maybe<Scalars['String']>;
  gymId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "employee_gym_checkin_by_month". */
export type Employee_Gym_Checkin_By_Month_Order_By = {
  count?: InputMaybe<Order_By>;
  employee?: InputMaybe<Order_By>;
  employeeId?: InputMaybe<Order_By>;
  employer?: InputMaybe<Order_By>;
  employerId?: InputMaybe<Order_By>;
  gym?: InputMaybe<Order_By>;
  gymId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "employee_gym_checkin_by_month" */
export enum Employee_Gym_Checkin_By_Month_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Employee = 'employee',
  /** column name */
  EmployeeId = 'employeeId',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmployerId = 'employerId',
  /** column name */
  Gym = 'gym',
  /** column name */
  GymId = 'gymId',
  /** column name */
  Id = 'id',
  /** column name */
  Month = 'month',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Employee_Gym_Checkin_By_Month_Stddev_Fields = {
  __typename?: 'employee_gym_checkin_by_month_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Employee_Gym_Checkin_By_Month_Stddev_Pop_Fields = {
  __typename?: 'employee_gym_checkin_by_month_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Employee_Gym_Checkin_By_Month_Stddev_Samp_Fields = {
  __typename?: 'employee_gym_checkin_by_month_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "employee_gym_checkin_by_month" */
export type Employee_Gym_Checkin_By_Month_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Gym_Checkin_By_Month_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Gym_Checkin_By_Month_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  employee?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['uuid']>;
  employer?: InputMaybe<Scalars['String']>;
  employerId?: InputMaybe<Scalars['uuid']>;
  gym?: InputMaybe<Scalars['String']>;
  gymId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  month?: InputMaybe<Scalars['float8']>;
  year?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Employee_Gym_Checkin_By_Month_Sum_Fields = {
  __typename?: 'employee_gym_checkin_by_month_sum_fields';
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['float8']>;
  year?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Employee_Gym_Checkin_By_Month_Var_Pop_Fields = {
  __typename?: 'employee_gym_checkin_by_month_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Employee_Gym_Checkin_By_Month_Var_Samp_Fields = {
  __typename?: 'employee_gym_checkin_by_month_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Employee_Gym_Checkin_By_Month_Variance_Fields = {
  __typename?: 'employee_gym_checkin_by_month_variance_fields';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type F_Gym_Distance_Args = {
  point?: InputMaybe<Scalars['json']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Create PDF invites archive */
  createPdfInvitesArchive: Output;
  /** Delete admin user from B2C */
  deleteAdminUser?: Maybe<Output>;
  /** Delete employee user from B2C and db */
  deleteEmployeeUser?: Maybe<Output>;
  /** Delete employer user from B2C */
  deleteEmployerUser?: Maybe<Output>;
  /** Delete gym user from B2C */
  deleteGymUser?: Maybe<Output>;
  /** Delete invite (including blob in case of PDF invite) */
  deleteInvite: Output;
  /** delete data from the table: "address" */
  delete_Address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_Address_by_pk?: Maybe<Address>;
  /** delete data from the table: "article" */
  delete_Article?: Maybe<Article_Mutation_Response>;
  /** delete single row from the table: "article" */
  delete_Article_by_pk?: Maybe<Article>;
  /** delete data from the table: "employee" */
  delete_Employee?: Maybe<Employee_Mutation_Response>;
  /** delete data from the table: "employee_event_date" */
  delete_EmployeeEventDate?: Maybe<EmployeeEventDate_Mutation_Response>;
  /** delete single row from the table: "employee_event_date" */
  delete_EmployeeEventDate_by_pk?: Maybe<EmployeeEventDate>;
  /** delete data from the table: "employee_gym" */
  delete_EmployeeGym?: Maybe<EmployeeGym_Mutation_Response>;
  /** delete data from the table: "employee_gym_checkin" */
  delete_EmployeeGymCheckin?: Maybe<EmployeeGymCheckin_Mutation_Response>;
  /** delete single row from the table: "employee_gym_checkin" */
  delete_EmployeeGymCheckin_by_pk?: Maybe<EmployeeGymCheckin>;
  /** delete single row from the table: "employee_gym" */
  delete_EmployeeGym_by_pk?: Maybe<EmployeeGym>;
  /** delete single row from the table: "employee" */
  delete_Employee_by_pk?: Maybe<Employee>;
  /** delete data from the table: "employer" */
  delete_Employer?: Maybe<Employer_Mutation_Response>;
  /** delete single row from the table: "employer" */
  delete_Employer_by_pk?: Maybe<Employer>;
  /** delete data from the table: "event" */
  delete_Event?: Maybe<Event_Mutation_Response>;
  /** delete data from the table: "event_date" */
  delete_EventDate?: Maybe<EventDate_Mutation_Response>;
  /** delete single row from the table: "event_date" */
  delete_EventDate_by_pk?: Maybe<EventDate>;
  /** delete single row from the table: "event" */
  delete_Event_by_pk?: Maybe<Event>;
  /** delete data from the table: "gym" */
  delete_Gym?: Maybe<Gym_Mutation_Response>;
  /** delete data from the table: "v_gym_distance" */
  delete_GymDistance?: Maybe<GymDistance_Mutation_Response>;
  /** delete single row from the table: "gym" */
  delete_Gym_by_pk?: Maybe<Gym>;
  /** delete data from the table: "user_invite" */
  delete_user_invite?: Maybe<User_Invite_Mutation_Response>;
  /** delete single row from the table: "user_invite" */
  delete_user_invite_by_pk?: Maybe<User_Invite>;
  /** delete data from the table: "v_event_date" */
  delete_v_event_date?: Maybe<V_Event_Date_Mutation_Response>;
  /** insert data into the table: "address" */
  insert_Address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_Address_one?: Maybe<Address>;
  /** insert data into the table: "article" */
  insert_Article?: Maybe<Article_Mutation_Response>;
  /** insert a single row into the table: "article" */
  insert_Article_one?: Maybe<Article>;
  /** insert data into the table: "employee" */
  insert_Employee?: Maybe<Employee_Mutation_Response>;
  /** insert data into the table: "employee_event_date" */
  insert_EmployeeEventDate?: Maybe<EmployeeEventDate_Mutation_Response>;
  /** insert a single row into the table: "employee_event_date" */
  insert_EmployeeEventDate_one?: Maybe<EmployeeEventDate>;
  /** insert data into the table: "employee_gym" */
  insert_EmployeeGym?: Maybe<EmployeeGym_Mutation_Response>;
  /** insert data into the table: "employee_gym_checkin" */
  insert_EmployeeGymCheckin?: Maybe<EmployeeGymCheckin_Mutation_Response>;
  /** insert a single row into the table: "employee_gym_checkin" */
  insert_EmployeeGymCheckin_one?: Maybe<EmployeeGymCheckin>;
  /** insert a single row into the table: "employee_gym" */
  insert_EmployeeGym_one?: Maybe<EmployeeGym>;
  /** insert a single row into the table: "employee" */
  insert_Employee_one?: Maybe<Employee>;
  /** insert data into the table: "employer" */
  insert_Employer?: Maybe<Employer_Mutation_Response>;
  /** insert a single row into the table: "employer" */
  insert_Employer_one?: Maybe<Employer>;
  /** insert data into the table: "event" */
  insert_Event?: Maybe<Event_Mutation_Response>;
  /** insert data into the table: "event_date" */
  insert_EventDate?: Maybe<EventDate_Mutation_Response>;
  /** insert a single row into the table: "event_date" */
  insert_EventDate_one?: Maybe<EventDate>;
  /** insert a single row into the table: "event" */
  insert_Event_one?: Maybe<Event>;
  /** insert data into the table: "gym" */
  insert_Gym?: Maybe<Gym_Mutation_Response>;
  /** insert data into the table: "v_gym_distance" */
  insert_GymDistance?: Maybe<GymDistance_Mutation_Response>;
  /** insert a single row into the table: "v_gym_distance" */
  insert_GymDistance_one?: Maybe<GymDistance>;
  /** insert a single row into the table: "gym" */
  insert_Gym_one?: Maybe<Gym>;
  /** insert data into the table: "user_invite" */
  insert_user_invite?: Maybe<User_Invite_Mutation_Response>;
  /** insert a single row into the table: "user_invite" */
  insert_user_invite_one?: Maybe<User_Invite>;
  /** insert data into the table: "v_event_date" */
  insert_v_event_date?: Maybe<V_Event_Date_Mutation_Response>;
  /** insert a single row into the table: "v_event_date" */
  insert_v_event_date_one?: Maybe<V_Event_Date>;
  /** Process user invites */
  processInvites: Output;
  /** Send user feedback email */
  sendUserFeedbackEmail?: Maybe<Output>;
  /** update data of the table: "address" */
  update_Address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_Address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_Address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "article" */
  update_Article?: Maybe<Article_Mutation_Response>;
  /** update single row of the table: "article" */
  update_Article_by_pk?: Maybe<Article>;
  /** update multiples rows of table: "article" */
  update_Article_many?: Maybe<Array<Maybe<Article_Mutation_Response>>>;
  /** update data of the table: "employee" */
  update_Employee?: Maybe<Employee_Mutation_Response>;
  /** update data of the table: "employee_event_date" */
  update_EmployeeEventDate?: Maybe<EmployeeEventDate_Mutation_Response>;
  /** update single row of the table: "employee_event_date" */
  update_EmployeeEventDate_by_pk?: Maybe<EmployeeEventDate>;
  /** update multiples rows of table: "employee_event_date" */
  update_EmployeeEventDate_many?: Maybe<Array<Maybe<EmployeeEventDate_Mutation_Response>>>;
  /** update data of the table: "employee_gym" */
  update_EmployeeGym?: Maybe<EmployeeGym_Mutation_Response>;
  /** update data of the table: "employee_gym_checkin" */
  update_EmployeeGymCheckin?: Maybe<EmployeeGymCheckin_Mutation_Response>;
  /** update single row of the table: "employee_gym_checkin" */
  update_EmployeeGymCheckin_by_pk?: Maybe<EmployeeGymCheckin>;
  /** update multiples rows of table: "employee_gym_checkin" */
  update_EmployeeGymCheckin_many?: Maybe<Array<Maybe<EmployeeGymCheckin_Mutation_Response>>>;
  /** update single row of the table: "employee_gym" */
  update_EmployeeGym_by_pk?: Maybe<EmployeeGym>;
  /** update multiples rows of table: "employee_gym" */
  update_EmployeeGym_many?: Maybe<Array<Maybe<EmployeeGym_Mutation_Response>>>;
  /** update single row of the table: "employee" */
  update_Employee_by_pk?: Maybe<Employee>;
  /** update multiples rows of table: "employee" */
  update_Employee_many?: Maybe<Array<Maybe<Employee_Mutation_Response>>>;
  /** update data of the table: "employer" */
  update_Employer?: Maybe<Employer_Mutation_Response>;
  /** update single row of the table: "employer" */
  update_Employer_by_pk?: Maybe<Employer>;
  /** update multiples rows of table: "employer" */
  update_Employer_many?: Maybe<Array<Maybe<Employer_Mutation_Response>>>;
  /** update data of the table: "event" */
  update_Event?: Maybe<Event_Mutation_Response>;
  /** update data of the table: "event_date" */
  update_EventDate?: Maybe<EventDate_Mutation_Response>;
  /** update single row of the table: "event_date" */
  update_EventDate_by_pk?: Maybe<EventDate>;
  /** update multiples rows of table: "event_date" */
  update_EventDate_many?: Maybe<Array<Maybe<EventDate_Mutation_Response>>>;
  /** update single row of the table: "event" */
  update_Event_by_pk?: Maybe<Event>;
  /** update multiples rows of table: "event" */
  update_Event_many?: Maybe<Array<Maybe<Event_Mutation_Response>>>;
  /** update data of the table: "gym" */
  update_Gym?: Maybe<Gym_Mutation_Response>;
  /** update data of the table: "v_gym_distance" */
  update_GymDistance?: Maybe<GymDistance_Mutation_Response>;
  /** update multiples rows of table: "v_gym_distance" */
  update_GymDistance_many?: Maybe<Array<Maybe<GymDistance_Mutation_Response>>>;
  /** update single row of the table: "gym" */
  update_Gym_by_pk?: Maybe<Gym>;
  /** update multiples rows of table: "gym" */
  update_Gym_many?: Maybe<Array<Maybe<Gym_Mutation_Response>>>;
  /** update data of the table: "user_invite" */
  update_user_invite?: Maybe<User_Invite_Mutation_Response>;
  /** update single row of the table: "user_invite" */
  update_user_invite_by_pk?: Maybe<User_Invite>;
  /** update multiples rows of table: "user_invite" */
  update_user_invite_many?: Maybe<Array<Maybe<User_Invite_Mutation_Response>>>;
  /** update data of the table: "v_event_date" */
  update_v_event_date?: Maybe<V_Event_Date_Mutation_Response>;
  /** update multiples rows of table: "v_event_date" */
  update_v_event_date_many?: Maybe<Array<Maybe<V_Event_Date_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootCreatePdfInvitesArchiveArgs = {
  employerId: Scalars['uuid'];
  employerName?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootDeleteAdminUserArgs = {
  userPrincipalName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteEmployeeUserArgs = {
  employeeId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteEmployerUserArgs = {
  employerId: Scalars['uuid'];
  userPrincipalName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteGymUserArgs = {
  gymId: Scalars['uuid'];
  userPrincipalName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteInviteArgs = {
  inviteId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ArticleArgs = {
  where: Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Article_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeeArgs = {
  where: Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmployeeEventDateArgs = {
  where: EmployeeEventDate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmployeeEventDate_By_PkArgs = {
  employee_id: Scalars['uuid'];
  event_date_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeeGymArgs = {
  where: EmployeeGym_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmployeeGymCheckinArgs = {
  where: EmployeeGymCheckin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EmployeeGymCheckin_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeeGym_By_PkArgs = {
  employee_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Employee_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmployerArgs = {
  where: Employer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EventArgs = {
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EventDateArgs = {
  where: EventDate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_EventDate_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GymArgs = {
  where: Gym_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GymDistanceArgs = {
  where: GymDistance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gym_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InviteArgs = {
  where: User_Invite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Invite_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_V_Event_DateArgs = {
  where: V_Event_Date_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ArticleArgs = {
  objects: Array<Article_Insert_Input>;
  on_conflict?: InputMaybe<Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Article_OneArgs = {
  object: Article_Insert_Input;
  on_conflict?: InputMaybe<Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeArgs = {
  objects: Array<Employee_Insert_Input>;
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeEventDateArgs = {
  objects: Array<EmployeeEventDate_Insert_Input>;
  on_conflict?: InputMaybe<EmployeeEventDate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeEventDate_OneArgs = {
  object: EmployeeEventDate_Insert_Input;
  on_conflict?: InputMaybe<EmployeeEventDate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeGymArgs = {
  objects: Array<EmployeeGym_Insert_Input>;
  on_conflict?: InputMaybe<EmployeeGym_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeGymCheckinArgs = {
  objects: Array<EmployeeGymCheckin_Insert_Input>;
  on_conflict?: InputMaybe<EmployeeGymCheckin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeGymCheckin_OneArgs = {
  object: EmployeeGymCheckin_Insert_Input;
  on_conflict?: InputMaybe<EmployeeGymCheckin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeGym_OneArgs = {
  object: EmployeeGym_Insert_Input;
  on_conflict?: InputMaybe<EmployeeGym_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_OneArgs = {
  object: Employee_Insert_Input;
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployerArgs = {
  objects: Array<Employer_Insert_Input>;
  on_conflict?: InputMaybe<Employer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employer_OneArgs = {
  object: Employer_Insert_Input;
  on_conflict?: InputMaybe<Employer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventArgs = {
  objects: Array<Event_Insert_Input>;
  on_conflict?: InputMaybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventDateArgs = {
  objects: Array<EventDate_Insert_Input>;
  on_conflict?: InputMaybe<EventDate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventDate_OneArgs = {
  object: EventDate_Insert_Input;
  on_conflict?: InputMaybe<EventDate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_OneArgs = {
  object: Event_Insert_Input;
  on_conflict?: InputMaybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GymArgs = {
  objects: Array<Gym_Insert_Input>;
  on_conflict?: InputMaybe<Gym_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GymDistanceArgs = {
  objects: Array<GymDistance_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_GymDistance_OneArgs = {
  object: GymDistance_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Gym_OneArgs = {
  object: Gym_Insert_Input;
  on_conflict?: InputMaybe<Gym_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InviteArgs = {
  objects: Array<User_Invite_Insert_Input>;
  on_conflict?: InputMaybe<User_Invite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invite_OneArgs = {
  object: User_Invite_Insert_Input;
  on_conflict?: InputMaybe<User_Invite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_V_Event_DateArgs = {
  objects: Array<V_Event_Date_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_V_Event_Date_OneArgs = {
  object: V_Event_Date_Insert_Input;
};


/** mutation root */
export type Mutation_RootProcessInvitesArgs = {
  inviteIds: Array<InputMaybe<Scalars['uuid']>>;
};


/** mutation root */
export type Mutation_RootSendUserFeedbackEmailArgs = {
  content: Scalars['String'];
  info: SendUserFeedbackEmailInfoInput;
  sender: SendUserFeedbackEmailSenderInput;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ArticleArgs = {
  _append?: InputMaybe<Article_Append_Input>;
  _delete_at_path?: InputMaybe<Article_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Article_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Article_Delete_Key_Input>;
  _prepend?: InputMaybe<Article_Prepend_Input>;
  _set?: InputMaybe<Article_Set_Input>;
  where: Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Article_By_PkArgs = {
  _append?: InputMaybe<Article_Append_Input>;
  _delete_at_path?: InputMaybe<Article_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Article_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Article_Delete_Key_Input>;
  _prepend?: InputMaybe<Article_Prepend_Input>;
  _set?: InputMaybe<Article_Set_Input>;
  pk_columns: Article_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Article_ManyArgs = {
  updates: Array<Article_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeArgs = {
  _set?: InputMaybe<Employee_Set_Input>;
  where: Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeEventDateArgs = {
  _set?: InputMaybe<EmployeeEventDate_Set_Input>;
  where: EmployeeEventDate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeEventDate_By_PkArgs = {
  _set?: InputMaybe<EmployeeEventDate_Set_Input>;
  pk_columns: EmployeeEventDate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeEventDate_ManyArgs = {
  updates: Array<EmployeeEventDate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeGymArgs = {
  _set?: InputMaybe<EmployeeGym_Set_Input>;
  where: EmployeeGym_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeGymCheckinArgs = {
  _set?: InputMaybe<EmployeeGymCheckin_Set_Input>;
  where: EmployeeGymCheckin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeGymCheckin_By_PkArgs = {
  _set?: InputMaybe<EmployeeGymCheckin_Set_Input>;
  pk_columns: EmployeeGymCheckin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeGymCheckin_ManyArgs = {
  updates: Array<EmployeeGymCheckin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeGym_By_PkArgs = {
  _set?: InputMaybe<EmployeeGym_Set_Input>;
  pk_columns: EmployeeGym_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeGym_ManyArgs = {
  updates: Array<EmployeeGym_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_By_PkArgs = {
  _set?: InputMaybe<Employee_Set_Input>;
  pk_columns: Employee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_ManyArgs = {
  updates: Array<Employee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployerArgs = {
  _append?: InputMaybe<Employer_Append_Input>;
  _delete_at_path?: InputMaybe<Employer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employer_Delete_Key_Input>;
  _prepend?: InputMaybe<Employer_Prepend_Input>;
  _set?: InputMaybe<Employer_Set_Input>;
  where: Employer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employer_By_PkArgs = {
  _append?: InputMaybe<Employer_Append_Input>;
  _delete_at_path?: InputMaybe<Employer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employer_Delete_Key_Input>;
  _prepend?: InputMaybe<Employer_Prepend_Input>;
  _set?: InputMaybe<Employer_Set_Input>;
  pk_columns: Employer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employer_ManyArgs = {
  updates: Array<Employer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventArgs = {
  _append?: InputMaybe<Event_Append_Input>;
  _delete_at_path?: InputMaybe<Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Event_Prepend_Input>;
  _set?: InputMaybe<Event_Set_Input>;
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EventDateArgs = {
  _set?: InputMaybe<EventDate_Set_Input>;
  where: EventDate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_EventDate_By_PkArgs = {
  _set?: InputMaybe<EventDate_Set_Input>;
  pk_columns: EventDate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventDate_ManyArgs = {
  updates: Array<EventDate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_By_PkArgs = {
  _append?: InputMaybe<Event_Append_Input>;
  _delete_at_path?: InputMaybe<Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Event_Prepend_Input>;
  _set?: InputMaybe<Event_Set_Input>;
  pk_columns: Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ManyArgs = {
  updates: Array<Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GymArgs = {
  _append?: InputMaybe<Gym_Append_Input>;
  _delete_at_path?: InputMaybe<Gym_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Gym_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Gym_Delete_Key_Input>;
  _inc?: InputMaybe<Gym_Inc_Input>;
  _prepend?: InputMaybe<Gym_Prepend_Input>;
  _set?: InputMaybe<Gym_Set_Input>;
  where: Gym_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GymDistanceArgs = {
  _inc?: InputMaybe<GymDistance_Inc_Input>;
  _set?: InputMaybe<GymDistance_Set_Input>;
  where: GymDistance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GymDistance_ManyArgs = {
  updates: Array<GymDistance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gym_By_PkArgs = {
  _append?: InputMaybe<Gym_Append_Input>;
  _delete_at_path?: InputMaybe<Gym_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Gym_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Gym_Delete_Key_Input>;
  _inc?: InputMaybe<Gym_Inc_Input>;
  _prepend?: InputMaybe<Gym_Prepend_Input>;
  _set?: InputMaybe<Gym_Set_Input>;
  pk_columns: Gym_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gym_ManyArgs = {
  updates: Array<Gym_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InviteArgs = {
  _inc?: InputMaybe<User_Invite_Inc_Input>;
  _set?: InputMaybe<User_Invite_Set_Input>;
  where: User_Invite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invite_By_PkArgs = {
  _inc?: InputMaybe<User_Invite_Inc_Input>;
  _set?: InputMaybe<User_Invite_Set_Input>;
  pk_columns: User_Invite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invite_ManyArgs = {
  updates: Array<User_Invite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_V_Event_DateArgs = {
  _set?: InputMaybe<V_Event_Date_Set_Input>;
  where: V_Event_Date_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_V_Event_Date_ManyArgs = {
  updates: Array<V_Event_Date_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  Address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  Address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  Address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "article" */
  Article: Array<Article>;
  /** fetch aggregated fields from the table: "article" */
  Article_aggregate: Article_Aggregate;
  /** fetch data from the table: "article" using primary key columns */
  Article_by_pk?: Maybe<Article>;
  /** fetch data from the table: "employee" */
  Employee: Array<Employee>;
  /** fetch data from the table: "employee_event_date" */
  EmployeeEventDate: Array<EmployeeEventDate>;
  /** fetch aggregated fields from the table: "employee_event_date" */
  EmployeeEventDate_aggregate: EmployeeEventDate_Aggregate;
  /** fetch data from the table: "employee_event_date" using primary key columns */
  EmployeeEventDate_by_pk?: Maybe<EmployeeEventDate>;
  /** fetch data from the table: "employee_gym" */
  EmployeeGym: Array<EmployeeGym>;
  /** fetch data from the table: "employee_gym_checkin" */
  EmployeeGymCheckin: Array<EmployeeGymCheckin>;
  /** fetch aggregated fields from the table: "employee_gym_checkin" */
  EmployeeGymCheckin_aggregate: EmployeeGymCheckin_Aggregate;
  /** fetch data from the table: "employee_gym_checkin" using primary key columns */
  EmployeeGymCheckin_by_pk?: Maybe<EmployeeGymCheckin>;
  /** fetch aggregated fields from the table: "employee_gym" */
  EmployeeGym_aggregate: EmployeeGym_Aggregate;
  /** fetch data from the table: "employee_gym" using primary key columns */
  EmployeeGym_by_pk?: Maybe<EmployeeGym>;
  /** fetch aggregated fields from the table: "employee" */
  Employee_aggregate: Employee_Aggregate;
  /** fetch data from the table: "employee" using primary key columns */
  Employee_by_pk?: Maybe<Employee>;
  /** fetch data from the table: "employer" */
  Employer: Array<Employer>;
  /** fetch aggregated fields from the table: "employer" */
  Employer_aggregate: Employer_Aggregate;
  /** fetch data from the table: "employer" using primary key columns */
  Employer_by_pk?: Maybe<Employer>;
  /** fetch data from the table: "event" */
  Event: Array<Event>;
  /** fetch data from the table: "event_date" */
  EventDate: Array<EventDate>;
  /** fetch aggregated fields from the table: "event_date" */
  EventDate_aggregate: EventDate_Aggregate;
  /** fetch data from the table: "event_date" using primary key columns */
  EventDate_by_pk?: Maybe<EventDate>;
  /** fetch aggregated fields from the table: "event" */
  Event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  Event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "gym" */
  Gym: Array<Gym>;
  /** fetch data from the table: "v_gym_distance" */
  GymDistance: Array<GymDistance>;
  /** fetch aggregated fields from the table: "v_gym_distance" */
  GymDistance_aggregate: GymDistance_Aggregate;
  /** fetch aggregated fields from the table: "gym" */
  Gym_aggregate: Gym_Aggregate;
  /** fetch data from the table: "gym" using primary key columns */
  Gym_by_pk?: Maybe<Gym>;
  /** fetch data from the table: "employee_gym_checkin_by_month" */
  employee_gym_checkin_by_month: Array<Employee_Gym_Checkin_By_Month>;
  /** fetch aggregated fields from the table: "employee_gym_checkin_by_month" */
  employee_gym_checkin_by_month_aggregate: Employee_Gym_Checkin_By_Month_Aggregate;
  /** execute function "f_gym_distance" which returns "v_gym_distance" */
  f_gym_distance: Array<GymDistance>;
  /** execute function "f_gym_distance" and query aggregates on result of table type "v_gym_distance" */
  f_gym_distance_aggregate: GymDistance_Aggregate;
  /** Get (B2C) users by admin role */
  getAdminUsers?: Maybe<GetUsersOutput>;
  /** Get SAS URI for Azure Storage Container */
  getContainerUri?: Maybe<GetContainerUriOutput>;
  /** Get (B2C) users by employer id */
  getEmployerUsers?: Maybe<GetUsersOutput>;
  /** Get gym users from b2c */
  getGymUsers?: Maybe<GetUsersOutput>;
  /** fetch data from the table: "user_invite" */
  user_invite: Array<User_Invite>;
  /** fetch aggregated fields from the table: "user_invite" */
  user_invite_aggregate: User_Invite_Aggregate;
  /** fetch data from the table: "user_invite" using primary key columns */
  user_invite_by_pk?: Maybe<User_Invite>;
  /** fetch data from the table: "v_event_date" */
  v_event_date: Array<V_Event_Date>;
  /** fetch aggregated fields from the table: "v_event_date" */
  v_event_date_aggregate: V_Event_Date_Aggregate;
};


export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootArticleArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


export type Query_RootArticle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


export type Query_RootArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployeeArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Query_RootEmployeeEventDateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


export type Query_RootEmployeeEventDate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


export type Query_RootEmployeeEventDate_By_PkArgs = {
  employee_id: Scalars['uuid'];
  event_date_id: Scalars['uuid'];
};


export type Query_RootEmployeeGymArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGym_Order_By>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


export type Query_RootEmployeeGymCheckinArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGymCheckin_Order_By>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};


export type Query_RootEmployeeGymCheckin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGymCheckin_Order_By>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};


export type Query_RootEmployeeGymCheckin_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployeeGym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGym_Order_By>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


export type Query_RootEmployeeGym_By_PkArgs = {
  employee_id: Scalars['uuid'];
};


export type Query_RootEmployee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Query_RootEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployerArgs = {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
};


export type Query_RootEmployer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
};


export type Query_RootEmployer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEventArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEventDateArgs = {
  distinct_on?: InputMaybe<Array<EventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDate_Order_By>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


export type Query_RootEventDate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDate_Order_By>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


export type Query_RootEventDate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGymArgs = {
  distinct_on?: InputMaybe<Array<Gym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gym_Order_By>>;
  where?: InputMaybe<Gym_Bool_Exp>;
};


export type Query_RootGymDistanceArgs = {
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Query_RootGymDistance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Query_RootGym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gym_Order_By>>;
  where?: InputMaybe<Gym_Bool_Exp>;
};


export type Query_RootGym_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployee_Gym_Checkin_By_MonthArgs = {
  distinct_on?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Order_By>>;
  where?: InputMaybe<Employee_Gym_Checkin_By_Month_Bool_Exp>;
};


export type Query_RootEmployee_Gym_Checkin_By_Month_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Order_By>>;
  where?: InputMaybe<Employee_Gym_Checkin_By_Month_Bool_Exp>;
};


export type Query_RootF_Gym_DistanceArgs = {
  args: F_Gym_Distance_Args;
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Query_RootF_Gym_Distance_AggregateArgs = {
  args: F_Gym_Distance_Args;
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Query_RootGetAdminUsersArgs = {
  _?: InputMaybe<Scalars['String']>;
};


export type Query_RootGetContainerUriArgs = {
  fileName: Scalars['String'];
};


export type Query_RootGetEmployerUsersArgs = {
  employerId: Scalars['uuid'];
};


export type Query_RootGetGymUsersArgs = {
  gymId: Scalars['uuid'];
};


export type Query_RootUser_InviteArgs = {
  distinct_on?: InputMaybe<Array<User_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invite_Order_By>>;
  where?: InputMaybe<User_Invite_Bool_Exp>;
};


export type Query_RootUser_Invite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invite_Order_By>>;
  where?: InputMaybe<User_Invite_Bool_Exp>;
};


export type Query_RootUser_Invite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootV_Event_DateArgs = {
  distinct_on?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<V_Event_Date_Order_By>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};


export type Query_RootV_Event_Date_AggregateArgs = {
  distinct_on?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<V_Event_Date_Order_By>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  Address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  Address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  Address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  Address_stream: Array<Address>;
  /** fetch data from the table: "article" */
  Article: Array<Article>;
  /** fetch aggregated fields from the table: "article" */
  Article_aggregate: Article_Aggregate;
  /** fetch data from the table: "article" using primary key columns */
  Article_by_pk?: Maybe<Article>;
  /** fetch data from the table in a streaming manner: "article" */
  Article_stream: Array<Article>;
  /** fetch data from the table: "employee" */
  Employee: Array<Employee>;
  /** fetch data from the table: "employee_event_date" */
  EmployeeEventDate: Array<EmployeeEventDate>;
  /** fetch aggregated fields from the table: "employee_event_date" */
  EmployeeEventDate_aggregate: EmployeeEventDate_Aggregate;
  /** fetch data from the table: "employee_event_date" using primary key columns */
  EmployeeEventDate_by_pk?: Maybe<EmployeeEventDate>;
  /** fetch data from the table in a streaming manner: "employee_event_date" */
  EmployeeEventDate_stream: Array<EmployeeEventDate>;
  /** fetch data from the table: "employee_gym" */
  EmployeeGym: Array<EmployeeGym>;
  /** fetch data from the table: "employee_gym_checkin" */
  EmployeeGymCheckin: Array<EmployeeGymCheckin>;
  /** fetch aggregated fields from the table: "employee_gym_checkin" */
  EmployeeGymCheckin_aggregate: EmployeeGymCheckin_Aggregate;
  /** fetch data from the table: "employee_gym_checkin" using primary key columns */
  EmployeeGymCheckin_by_pk?: Maybe<EmployeeGymCheckin>;
  /** fetch data from the table in a streaming manner: "employee_gym_checkin" */
  EmployeeGymCheckin_stream: Array<EmployeeGymCheckin>;
  /** fetch aggregated fields from the table: "employee_gym" */
  EmployeeGym_aggregate: EmployeeGym_Aggregate;
  /** fetch data from the table: "employee_gym" using primary key columns */
  EmployeeGym_by_pk?: Maybe<EmployeeGym>;
  /** fetch data from the table in a streaming manner: "employee_gym" */
  EmployeeGym_stream: Array<EmployeeGym>;
  /** fetch aggregated fields from the table: "employee" */
  Employee_aggregate: Employee_Aggregate;
  /** fetch data from the table: "employee" using primary key columns */
  Employee_by_pk?: Maybe<Employee>;
  /** fetch data from the table in a streaming manner: "employee" */
  Employee_stream: Array<Employee>;
  /** fetch data from the table: "employer" */
  Employer: Array<Employer>;
  /** fetch aggregated fields from the table: "employer" */
  Employer_aggregate: Employer_Aggregate;
  /** fetch data from the table: "employer" using primary key columns */
  Employer_by_pk?: Maybe<Employer>;
  /** fetch data from the table in a streaming manner: "employer" */
  Employer_stream: Array<Employer>;
  /** fetch data from the table: "event" */
  Event: Array<Event>;
  /** fetch data from the table: "event_date" */
  EventDate: Array<EventDate>;
  /** fetch aggregated fields from the table: "event_date" */
  EventDate_aggregate: EventDate_Aggregate;
  /** fetch data from the table: "event_date" using primary key columns */
  EventDate_by_pk?: Maybe<EventDate>;
  /** fetch data from the table in a streaming manner: "event_date" */
  EventDate_stream: Array<EventDate>;
  /** fetch aggregated fields from the table: "event" */
  Event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  Event_by_pk?: Maybe<Event>;
  /** fetch data from the table in a streaming manner: "event" */
  Event_stream: Array<Event>;
  /** fetch data from the table: "gym" */
  Gym: Array<Gym>;
  /** fetch data from the table: "v_gym_distance" */
  GymDistance: Array<GymDistance>;
  /** fetch aggregated fields from the table: "v_gym_distance" */
  GymDistance_aggregate: GymDistance_Aggregate;
  /** fetch data from the table in a streaming manner: "v_gym_distance" */
  GymDistance_stream: Array<GymDistance>;
  /** fetch aggregated fields from the table: "gym" */
  Gym_aggregate: Gym_Aggregate;
  /** fetch data from the table: "gym" using primary key columns */
  Gym_by_pk?: Maybe<Gym>;
  /** fetch data from the table in a streaming manner: "gym" */
  Gym_stream: Array<Gym>;
  /** fetch data from the table: "employee_gym_checkin_by_month" */
  employee_gym_checkin_by_month: Array<Employee_Gym_Checkin_By_Month>;
  /** fetch aggregated fields from the table: "employee_gym_checkin_by_month" */
  employee_gym_checkin_by_month_aggregate: Employee_Gym_Checkin_By_Month_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_gym_checkin_by_month" */
  employee_gym_checkin_by_month_stream: Array<Employee_Gym_Checkin_By_Month>;
  /** execute function "f_gym_distance" which returns "v_gym_distance" */
  f_gym_distance: Array<GymDistance>;
  /** execute function "f_gym_distance" and query aggregates on result of table type "v_gym_distance" */
  f_gym_distance_aggregate: GymDistance_Aggregate;
  /** fetch data from the table: "user_invite" */
  user_invite: Array<User_Invite>;
  /** fetch aggregated fields from the table: "user_invite" */
  user_invite_aggregate: User_Invite_Aggregate;
  /** fetch data from the table: "user_invite" using primary key columns */
  user_invite_by_pk?: Maybe<User_Invite>;
  /** fetch data from the table in a streaming manner: "user_invite" */
  user_invite_stream: Array<User_Invite>;
  /** fetch data from the table: "v_event_date" */
  v_event_date: Array<V_Event_Date>;
  /** fetch aggregated fields from the table: "v_event_date" */
  v_event_date_aggregate: V_Event_Date_Aggregate;
  /** fetch data from the table in a streaming manner: "v_event_date" */
  v_event_date_stream: Array<V_Event_Date>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootArticleArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


export type Subscription_RootArticle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Article_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Article_Order_By>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


export type Subscription_RootArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootArticle_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Article_Stream_Cursor_Input>>;
  where?: InputMaybe<Article_Bool_Exp>;
};


export type Subscription_RootEmployeeArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployeeEventDateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


export type Subscription_RootEmployeeEventDate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


export type Subscription_RootEmployeeEventDate_By_PkArgs = {
  employee_id: Scalars['uuid'];
  event_date_id: Scalars['uuid'];
};


export type Subscription_RootEmployeeEventDate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmployeeEventDate_Stream_Cursor_Input>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


export type Subscription_RootEmployeeGymArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGym_Order_By>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


export type Subscription_RootEmployeeGymCheckinArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGymCheckin_Order_By>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};


export type Subscription_RootEmployeeGymCheckin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGymCheckin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGymCheckin_Order_By>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};


export type Subscription_RootEmployeeGymCheckin_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmployeeGymCheckin_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmployeeGymCheckin_Stream_Cursor_Input>>;
  where?: InputMaybe<EmployeeGymCheckin_Bool_Exp>;
};


export type Subscription_RootEmployeeGym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeGym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeGym_Order_By>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


export type Subscription_RootEmployeeGym_By_PkArgs = {
  employee_id: Scalars['uuid'];
};


export type Subscription_RootEmployeeGym_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmployeeGym_Stream_Cursor_Input>>;
  where?: InputMaybe<EmployeeGym_Bool_Exp>;
};


export type Subscription_RootEmployee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmployee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployerArgs = {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
};


export type Subscription_RootEmployer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employer_Order_By>>;
  where?: InputMaybe<Employer_Bool_Exp>;
};


export type Subscription_RootEmployer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmployer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employer_Stream_Cursor_Input>>;
  where?: InputMaybe<Employer_Bool_Exp>;
};


export type Subscription_RootEventArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEventDateArgs = {
  distinct_on?: InputMaybe<Array<EventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDate_Order_By>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


export type Subscription_RootEventDate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EventDate_Order_By>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


export type Subscription_RootEventDate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEventDate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EventDate_Stream_Cursor_Input>>;
  where?: InputMaybe<EventDate_Bool_Exp>;
};


export type Subscription_RootEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootGymArgs = {
  distinct_on?: InputMaybe<Array<Gym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gym_Order_By>>;
  where?: InputMaybe<Gym_Bool_Exp>;
};


export type Subscription_RootGymDistanceArgs = {
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Subscription_RootGymDistance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Subscription_RootGymDistance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GymDistance_Stream_Cursor_Input>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Subscription_RootGym_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gym_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gym_Order_By>>;
  where?: InputMaybe<Gym_Bool_Exp>;
};


export type Subscription_RootGym_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGym_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gym_Stream_Cursor_Input>>;
  where?: InputMaybe<Gym_Bool_Exp>;
};


export type Subscription_RootEmployee_Gym_Checkin_By_MonthArgs = {
  distinct_on?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Order_By>>;
  where?: InputMaybe<Employee_Gym_Checkin_By_Month_Bool_Exp>;
};


export type Subscription_RootEmployee_Gym_Checkin_By_Month_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Gym_Checkin_By_Month_Order_By>>;
  where?: InputMaybe<Employee_Gym_Checkin_By_Month_Bool_Exp>;
};


export type Subscription_RootEmployee_Gym_Checkin_By_Month_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Gym_Checkin_By_Month_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Gym_Checkin_By_Month_Bool_Exp>;
};


export type Subscription_RootF_Gym_DistanceArgs = {
  args: F_Gym_Distance_Args;
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Subscription_RootF_Gym_Distance_AggregateArgs = {
  args: F_Gym_Distance_Args;
  distinct_on?: InputMaybe<Array<GymDistance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GymDistance_Order_By>>;
  where?: InputMaybe<GymDistance_Bool_Exp>;
};


export type Subscription_RootUser_InviteArgs = {
  distinct_on?: InputMaybe<Array<User_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invite_Order_By>>;
  where?: InputMaybe<User_Invite_Bool_Exp>;
};


export type Subscription_RootUser_Invite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invite_Order_By>>;
  where?: InputMaybe<User_Invite_Bool_Exp>;
};


export type Subscription_RootUser_Invite_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Invite_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Invite_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Invite_Bool_Exp>;
};


export type Subscription_RootV_Event_DateArgs = {
  distinct_on?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<V_Event_Date_Order_By>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};


export type Subscription_RootV_Event_Date_AggregateArgs = {
  distinct_on?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<V_Event_Date_Order_By>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};


export type Subscription_RootV_Event_Date_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<V_Event_Date_Stream_Cursor_Input>>;
  where?: InputMaybe<V_Event_Date_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Send Types: 0 (email), 1 (pdf) / Status Codes: 0 (open), 1 (processed), 2 (error)  */
export type User_Invite = {
  __typename?: 'user_invite';
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  blob_url?: Maybe<Scalars['String']>;
  code: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  error_message?: Maybe<Scalars['String']>;
  given_name: Scalars['String'];
  gym_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_valid: Scalars['Boolean'];
  role: Scalars['String'];
  status: Scalars['smallint'];
  surname: Scalars['String'];
  type: Scalars['smallint'];
};

/** aggregated selection of "user_invite" */
export type User_Invite_Aggregate = {
  __typename?: 'user_invite_aggregate';
  aggregate?: Maybe<User_Invite_Aggregate_Fields>;
  nodes: Array<User_Invite>;
};

/** aggregate fields of "user_invite" */
export type User_Invite_Aggregate_Fields = {
  __typename?: 'user_invite_aggregate_fields';
  avg?: Maybe<User_Invite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Invite_Max_Fields>;
  min?: Maybe<User_Invite_Min_Fields>;
  stddev?: Maybe<User_Invite_Stddev_Fields>;
  stddev_pop?: Maybe<User_Invite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Invite_Stddev_Samp_Fields>;
  sum?: Maybe<User_Invite_Sum_Fields>;
  var_pop?: Maybe<User_Invite_Var_Pop_Fields>;
  var_samp?: Maybe<User_Invite_Var_Samp_Fields>;
  variance?: Maybe<User_Invite_Variance_Fields>;
};


/** aggregate fields of "user_invite" */
export type User_Invite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Invite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Invite_Avg_Fields = {
  __typename?: 'user_invite_avg_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_invite". All fields are combined with a logical 'AND'. */
export type User_Invite_Bool_Exp = {
  _and?: InputMaybe<Array<User_Invite_Bool_Exp>>;
  _not?: InputMaybe<User_Invite_Bool_Exp>;
  _or?: InputMaybe<Array<User_Invite_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  blob_url?: InputMaybe<String_Comparison_Exp>;
  code?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employer_id?: InputMaybe<Uuid_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  given_name?: InputMaybe<String_Comparison_Exp>;
  gym_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_valid?: InputMaybe<Boolean_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Smallint_Comparison_Exp>;
  surname?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invite" */
export enum User_Invite_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeInvitePkey = 'employee_invite_pkey',
  /** unique or primary key constraint on columns "address_id" */
  UserInviteAddressIdKey = 'user_invite_address_id_key',
  /** unique or primary key constraint on columns "email" */
  UserInviteEmailKey = 'user_invite_email_key'
}

/** input type for incrementing numeric columns in table "user_invite" */
export type User_Invite_Inc_Input = {
  status?: InputMaybe<Scalars['smallint']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "user_invite" */
export type User_Invite_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  blob_url?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  error_message?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['smallint']>;
  surname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type User_Invite_Max_Fields = {
  __typename?: 'user_invite_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  blob_url?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  error_message?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  surname?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type User_Invite_Min_Fields = {
  __typename?: 'user_invite_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  blob_url?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  employer_id?: Maybe<Scalars['uuid']>;
  error_message?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  gym_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  surname?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "user_invite" */
export type User_Invite_Mutation_Response = {
  __typename?: 'user_invite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Invite>;
};

/** input type for inserting object relation for remote table "user_invite" */
export type User_Invite_Obj_Rel_Insert_Input = {
  data: User_Invite_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Invite_On_Conflict>;
};

/** on_conflict condition type for table "user_invite" */
export type User_Invite_On_Conflict = {
  constraint: User_Invite_Constraint;
  update_columns?: Array<User_Invite_Update_Column>;
  where?: InputMaybe<User_Invite_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invite". */
export type User_Invite_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  blob_url?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employer_id?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  given_name?: InputMaybe<Order_By>;
  gym_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_valid?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  surname?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invite */
export type User_Invite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_invite" */
export enum User_Invite_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BlobUrl = 'blob_url',
  /** column name */
  Code = 'code',
  /** column name */
  Email = 'email',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'is_valid',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  Surname = 'surname',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "user_invite" */
export type User_Invite_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  blob_url?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  error_message?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['smallint']>;
  surname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type User_Invite_Stddev_Fields = {
  __typename?: 'user_invite_stddev_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Invite_Stddev_Pop_Fields = {
  __typename?: 'user_invite_stddev_pop_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Invite_Stddev_Samp_Fields = {
  __typename?: 'user_invite_stddev_samp_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_invite" */
export type User_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Invite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Invite_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  blob_url?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['uuid']>;
  error_message?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  gym_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['smallint']>;
  surname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['smallint']>;
};

/** aggregate sum on columns */
export type User_Invite_Sum_Fields = {
  __typename?: 'user_invite_sum_fields';
  status?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "user_invite" */
export enum User_Invite_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BlobUrl = 'blob_url',
  /** column name */
  Code = 'code',
  /** column name */
  Email = 'email',
  /** column name */
  EmployerId = 'employer_id',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  GivenName = 'given_name',
  /** column name */
  GymId = 'gym_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'is_valid',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  Surname = 'surname',
  /** column name */
  Type = 'type'
}

export type User_Invite_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Invite_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Invite_Set_Input>;
  where: User_Invite_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Invite_Var_Pop_Fields = {
  __typename?: 'user_invite_var_pop_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Invite_Var_Samp_Fields = {
  __typename?: 'user_invite_var_samp_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Invite_Variance_Fields = {
  __typename?: 'user_invite_variance_fields';
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "v_event_date" */
export type V_Event_Date = {
  __typename?: 'v_event_date';
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  employee_event_dates: Array<EmployeeEventDate>;
  /** An aggregate relationship */
  employee_event_dates_aggregate: EmployeeEventDate_Aggregate;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  next_date?: Maybe<Scalars['timestamptz']>;
  rrule?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "v_event_date" */
export type V_Event_DateEmployee_Event_DatesArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};


/** columns and relationships of "v_event_date" */
export type V_Event_DateEmployee_Event_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<EmployeeEventDate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmployeeEventDate_Order_By>>;
  where?: InputMaybe<EmployeeEventDate_Bool_Exp>;
};

/** aggregated selection of "v_event_date" */
export type V_Event_Date_Aggregate = {
  __typename?: 'v_event_date_aggregate';
  aggregate?: Maybe<V_Event_Date_Aggregate_Fields>;
  nodes: Array<V_Event_Date>;
};

export type V_Event_Date_Aggregate_Bool_Exp = {
  count?: InputMaybe<V_Event_Date_Aggregate_Bool_Exp_Count>;
};

export type V_Event_Date_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<V_Event_Date_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "v_event_date" */
export type V_Event_Date_Aggregate_Fields = {
  __typename?: 'v_event_date_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<V_Event_Date_Max_Fields>;
  min?: Maybe<V_Event_Date_Min_Fields>;
};


/** aggregate fields of "v_event_date" */
export type V_Event_Date_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<V_Event_Date_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "v_event_date" */
export type V_Event_Date_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<V_Event_Date_Max_Order_By>;
  min?: InputMaybe<V_Event_Date_Min_Order_By>;
};

/** input type for inserting array relation for remote table "v_event_date" */
export type V_Event_Date_Arr_Rel_Insert_Input = {
  data: Array<V_Event_Date_Insert_Input>;
};

/** Boolean expression to filter rows from the table "v_event_date". All fields are combined with a logical 'AND'. */
export type V_Event_Date_Bool_Exp = {
  _and?: InputMaybe<Array<V_Event_Date_Bool_Exp>>;
  _not?: InputMaybe<V_Event_Date_Bool_Exp>;
  _or?: InputMaybe<Array<V_Event_Date_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee_event_dates?: InputMaybe<EmployeeEventDate_Bool_Exp>;
  employee_event_dates_aggregate?: InputMaybe<EmployeeEventDate_Aggregate_Bool_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  next_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  rrule?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "v_event_date" */
export type V_Event_Date_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  employee_event_dates?: InputMaybe<EmployeeEventDate_Arr_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  next_date?: InputMaybe<Scalars['timestamptz']>;
  rrule?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type V_Event_Date_Max_Fields = {
  __typename?: 'v_event_date_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  next_date?: Maybe<Scalars['timestamptz']>;
  rrule?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "v_event_date" */
export type V_Event_Date_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_date?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type V_Event_Date_Min_Fields = {
  __typename?: 'v_event_date_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  next_date?: Maybe<Scalars['timestamptz']>;
  rrule?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "v_event_date" */
export type V_Event_Date_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_date?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "v_event_date" */
export type V_Event_Date_Mutation_Response = {
  __typename?: 'v_event_date_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<V_Event_Date>;
};

/** Ordering options when selecting data from "v_event_date". */
export type V_Event_Date_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  employee_event_dates_aggregate?: InputMaybe<EmployeeEventDate_Aggregate_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_date?: InputMaybe<Order_By>;
  rrule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "v_event_date" */
export enum V_Event_Date_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  NextDate = 'next_date',
  /** column name */
  Rrule = 'rrule',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "v_event_date" */
export type V_Event_Date_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  next_date?: InputMaybe<Scalars['timestamptz']>;
  rrule?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "v_event_date" */
export type V_Event_Date_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: V_Event_Date_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type V_Event_Date_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  next_date?: InputMaybe<Scalars['timestamptz']>;
  rrule?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type V_Event_Date_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<V_Event_Date_Set_Input>;
  where: V_Event_Date_Bool_Exp;
};

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<Address_Set_Input>;
}>;


export type UpdateAddressMutation = { __typename?: 'mutation_root', update_Address_by_pk?: { __typename?: 'Address', id: any } | null };

export type ListArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListArticlesQuery = { __typename?: 'query_root', Article: Array<{ __typename?: 'Article', id: any, name: string, global: boolean, header_image?: any | null, content_images?: any | null }> };

export type ListAdminArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminArticlesQuery = { __typename?: 'query_root', Article: Array<{ __typename?: 'Article', id: any, name: string, global: boolean, header_image?: any | null, content_images?: any | null, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListGlobalArticlesQueryVariables = Exact<{
  global?: InputMaybe<Scalars['Boolean']>;
}>;


export type ListGlobalArticlesQuery = { __typename?: 'query_root', Article: Array<{ __typename?: 'Article', id: any, name: string, global: boolean, header_image?: any | null, content_images?: any | null, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListArticlesByGymQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type ListArticlesByGymQuery = { __typename?: 'query_root', Article: Array<{ __typename?: 'Article', id: any, name: string, global: boolean, header_image?: any | null, content_images?: any | null, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListArticlesByEmployerQueryVariables = Exact<{
  employerId: Scalars['uuid'];
}>;


export type ListArticlesByEmployerQuery = { __typename?: 'query_root', Article: Array<{ __typename?: 'Article', id: any, name: string, global: boolean, header_image?: any | null, content_images?: any | null, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListArticlesForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ListArticlesForDashboardQuery = { __typename?: 'query_root', Article: Array<{ __typename?: 'Article', id: any, name: string }> };

export type GetArticleQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetArticleQuery = { __typename?: 'query_root', Article_by_pk?: { __typename?: 'Article', id: any, name: string, global: boolean, gym_id?: any | null, employer_id?: any | null, image_url?: string | null, header_image?: any | null, content_images?: any | null, content?: string | null } | null };

export type InsertArticleMutationVariables = Exact<{
  article: Article_Insert_Input;
}>;


export type InsertArticleMutation = { __typename?: 'mutation_root', insert_Article?: { __typename?: 'Article_mutation_response', returning: Array<{ __typename?: 'Article', id: any }> } | null };

export type UpdateArticleMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<Article_Set_Input>;
}>;


export type UpdateArticleMutation = { __typename?: 'mutation_root', update_Article_by_pk?: { __typename?: 'Article', id: any } | null };

export type RemoveArticleMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveArticleMutation = { __typename?: 'mutation_root', delete_Article?: { __typename?: 'Article_mutation_response', returning: Array<{ __typename?: 'Article', id: any }> } | null };

export type ListEmployeesByEmployerQueryVariables = Exact<{
  employerId: Scalars['uuid'];
}>;


export type ListEmployeesByEmployerQuery = { __typename?: 'query_root', Employee: Array<{ __typename?: 'Employee', id: any, given_name?: string | null, surname?: string | null }> };

export type ListCheckedInEmployeesQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type ListCheckedInEmployeesQuery = { __typename?: 'query_root', EmployeeGymCheckin: Array<{ __typename?: 'EmployeeGymCheckin', Employee?: { __typename?: 'Employee', id: any, given_name?: string | null, surname?: string | null } | null }> };

export type GetEmployeeQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetEmployeeQuery = { __typename?: 'query_root', Employee_by_pk?: { __typename?: 'Employee', id: any, given_name?: string | null, surname?: string | null, email?: string | null, employer_id: any } | null };

export type InsertEmployeeMutationVariables = Exact<{
  employee: Employee_Insert_Input;
}>;


export type InsertEmployeeMutation = { __typename?: 'mutation_root', insert_Employee?: { __typename?: 'Employee_mutation_response', returning: Array<{ __typename?: 'Employee', id: any }> } | null };

export type UpdateEmployeeMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<Employee_Set_Input>;
}>;


export type UpdateEmployeeMutation = { __typename?: 'mutation_root', update_Employee_by_pk?: { __typename?: 'Employee', id: any } | null };

export type DeleteEmployeeUserMutationVariables = Exact<{
  employeeId: Scalars['uuid'];
}>;


export type DeleteEmployeeUserMutation = { __typename?: 'mutation_root', deleteEmployeeUser?: { __typename?: 'Output', success: boolean } | null };

export type ListEmployersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEmployersQuery = { __typename?: 'query_root', Employer: Array<{ __typename?: 'Employer', id: any, name: string, address?: { __typename?: 'Address', locality?: string | null } | null }> };

export type ListEmployersForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEmployersForDashboardQuery = { __typename?: 'query_root', Employer: Array<{ __typename?: 'Employer', id: any, name: string }> };

export type GetEmployerQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetEmployerQuery = { __typename?: 'query_root', Employer_by_pk?: { __typename?: 'Employer', email_address?: string | null, contact_given_name?: string | null, contact_surname?: string | null, homepage_url?: string | null, id: any, name: string, phone_number?: string | null, image?: any | null, user_invite_archive_blob_url?: string | null, address?: { __typename?: 'Address', id: any, address_line_1?: string | null, address_line_2?: string | null, address_line_3?: string | null, address_line_4?: string | null, zip_code?: string | null, locality?: string | null, state?: string | null, location?: any | null } | null } | null };

export type ListEmployerUsersQueryVariables = Exact<{
  employerId: Scalars['uuid'];
}>;


export type ListEmployerUsersQuery = { __typename?: 'query_root', getEmployerUsers?: { __typename?: 'GetUsersOutput', users: Array<{ __typename?: 'User', id: string, givenName: string, surname: string } | null> } | null };

export type InsertEmployerMutationVariables = Exact<{
  employer: Employer_Insert_Input;
}>;


export type InsertEmployerMutation = { __typename?: 'mutation_root', insert_Employer?: { __typename?: 'Employer_mutation_response', returning: Array<{ __typename?: 'Employer', id: any, name: string }> } | null };

export type UpdateEmployerMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<Employer_Set_Input>;
}>;


export type UpdateEmployerMutation = { __typename?: 'mutation_root', update_Employer_by_pk?: { __typename?: 'Employer', id: any } | null };

export type DeleteEmployerMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteEmployerMutation = { __typename?: 'mutation_root', update_Employer_by_pk?: { __typename?: 'Employer', id: any } | null };

export type DeleteEmployerUserMutationVariables = Exact<{
  employerId: Scalars['uuid'];
  userPrincipalName: Scalars['String'];
}>;


export type DeleteEmployerUserMutation = { __typename?: 'mutation_root', deleteEmployerUser?: { __typename?: 'Output', success: boolean } | null };

export type ListEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEventsQuery = { __typename?: 'query_root', Event: Array<{ __typename?: 'Event', id: any, name: string, image?: any | null, global: boolean }> };

export type ListAdminEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminEventsQuery = { __typename?: 'query_root', Event: Array<{ __typename?: 'Event', id: any, name: string, image?: any | null, global: boolean, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListGlobalEventsQueryVariables = Exact<{
  global?: InputMaybe<Scalars['Boolean']>;
}>;


export type ListGlobalEventsQuery = { __typename?: 'query_root', Event: Array<{ __typename?: 'Event', id: any, name: string, image?: any | null, global: boolean, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListEventsByGymQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type ListEventsByGymQuery = { __typename?: 'query_root', Event: Array<{ __typename?: 'Event', id: any, name: string, image?: any | null, global: boolean, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListEventsByEmployerQueryVariables = Exact<{
  employerId: Scalars['uuid'];
}>;


export type ListEventsByEmployerQuery = { __typename?: 'query_root', Event: Array<{ __typename?: 'Event', id: any, name: string, image?: any | null, global: boolean, Gym?: { __typename?: 'Gym', name: string } | null, Employer?: { __typename?: 'Employer', name: string } | null }> };

export type ListEventsForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEventsForDashboardQuery = { __typename?: 'query_root', Event: Array<{ __typename?: 'Event', id: any, name: string }> };

export type GetEventQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetEventQuery = { __typename?: 'query_root', Event_by_pk?: { __typename?: 'Event', id: any, name: string, global: boolean, gym_id?: any | null, employer_id?: any | null, description: string, tags: any, image?: any | null, address?: { __typename?: 'Address', id: any, address_line_1?: string | null, address_line_2?: string | null, address_line_3?: string | null, address_line_4?: string | null, zip_code?: string | null, locality?: string | null, state?: string | null, location?: any | null } | null } | null };

export type InsertEventMutationVariables = Exact<{
  event: Event_Insert_Input;
}>;


export type InsertEventMutation = { __typename?: 'mutation_root', insert_Event?: { __typename?: 'Event_mutation_response', returning: Array<{ __typename?: 'Event', id: any }> } | null };

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<Event_Set_Input>;
}>;


export type UpdateEventMutation = { __typename?: 'mutation_root', update_Event_by_pk?: { __typename?: 'Event', id: any } | null };

export type RemoveEventMutationVariables = Exact<{
  event: Scalars['uuid'];
}>;


export type RemoveEventMutation = { __typename?: 'mutation_root', delete_Event?: { __typename?: 'Event_mutation_response', returning: Array<{ __typename?: 'Event', id: any }> } | null };

export type ListEventDatesByEventQueryVariables = Exact<{
  eventId: Scalars['uuid'];
}>;


export type ListEventDatesByEventQuery = { __typename?: 'query_root', EventDate: Array<{ __typename?: 'EventDate', id: any, date: any, rrule?: string | null }> };

export type ListEventDateEmployeesQueryVariables = Exact<{
  eventDateId: Scalars['uuid'];
}>;


export type ListEventDateEmployeesQuery = { __typename?: 'query_root', EmployeeEventDate: Array<{ __typename?: 'EmployeeEventDate', employee: { __typename?: 'Employee', id: any, given_name?: string | null, surname?: string | null } }> };

export type GetEventDateQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetEventDateQuery = { __typename?: 'query_root', EventDate_by_pk?: { __typename?: 'EventDate', id: any, date: any, rrule?: string | null, address?: { __typename?: 'Address', id: any, address_line_1?: string | null, address_line_2?: string | null, address_line_3?: string | null, address_line_4?: string | null, zip_code?: string | null, locality?: string | null, state?: string | null, location?: any | null } | null } | null };

export type InsertEventDateMutationVariables = Exact<{
  eventDate: EventDate_Insert_Input;
}>;


export type InsertEventDateMutation = { __typename?: 'mutation_root', insert_EventDate?: { __typename?: 'EventDate_mutation_response', returning: Array<{ __typename?: 'EventDate', id: any }> } | null };

export type UpdateEventDateMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<EventDate_Set_Input>;
}>;


export type UpdateEventDateMutation = { __typename?: 'mutation_root', update_EventDate_by_pk?: { __typename?: 'EventDate', id: any } | null };

export type RemoveEventDateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveEventDateMutation = { __typename?: 'mutation_root', delete_EventDate?: { __typename?: 'EventDate_mutation_response', returning: Array<{ __typename?: 'EventDate', id: any }> } | null };

export type ListGymsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListGymsQuery = { __typename?: 'query_root', Gym: Array<{ __typename?: 'Gym', id: any, name: string, address?: { __typename?: 'Address', locality?: string | null } | null }> };

export type ListGymsForDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ListGymsForDashboardQuery = { __typename?: 'query_root', Gym: Array<{ __typename?: 'Gym', id: any, name: string }> };

export type GetGymQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetGymQuery = { __typename?: 'query_root', Gym_by_pk?: { __typename?: 'Gym', description?: string | null, email_address?: string | null, group?: string | null, homepage_url?: string | null, id: any, contact_given_name?: string | null, contact_surname?: string | null, name: string, phone_number?: string | null, tags: any, image?: any | null, checkin_code_blob_url?: string | null, background_image?: any | null, address?: { __typename?: 'Address', id: any, address_line_1?: string | null, address_line_2?: string | null, address_line_3?: string | null, address_line_4?: string | null, zip_code?: string | null, locality?: string | null, state?: string | null, location?: any | null } | null } | null };

export type ListGymUsersQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type ListGymUsersQuery = { __typename?: 'query_root', getGymUsers?: { __typename?: 'GetUsersOutput', users: Array<{ __typename?: 'User', id: string, givenName: string, surname: string } | null> } | null };

export type InsertGymMutationVariables = Exact<{
  gym: Gym_Insert_Input;
}>;


export type InsertGymMutation = { __typename?: 'mutation_root', insert_Gym?: { __typename?: 'Gym_mutation_response', returning: Array<{ __typename?: 'Gym', id: any }> } | null };

export type UpdateGymMutationVariables = Exact<{
  id: Scalars['uuid'];
  changes?: InputMaybe<Gym_Set_Input>;
}>;


export type UpdateGymMutation = { __typename?: 'mutation_root', update_Gym_by_pk?: { __typename?: 'Gym', id: any } | null };

export type DeleteGymMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteGymMutation = { __typename?: 'mutation_root', update_Gym_by_pk?: { __typename?: 'Gym', id: any } | null };

export type DeleteGymUserMutationVariables = Exact<{
  gymId: Scalars['uuid'];
  userPrincipalName: Scalars['String'];
}>;


export type DeleteGymUserMutation = { __typename?: 'mutation_root', deleteGymUser?: { __typename?: 'Output', success: boolean } | null };

export type GetContainerUriQueryVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type GetContainerUriQuery = { __typename?: 'query_root', getContainerUri?: { __typename?: 'GetContainerUriOutput', blobName: string, sasUri: string } | null };

export type GetAdminReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminReportQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportBetweenQueryVariables = Exact<{
  yearFrom: Scalars['float8'];
  yearUntil: Scalars['float8'];
  monthFrom: Scalars['float8'];
  monthUntil: Scalars['float8'];
}>;


export type GetAdminReportBetweenQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportByEmployerQueryVariables = Exact<{
  employerId: Scalars['uuid'];
}>;


export type GetAdminReportByEmployerQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportByEmployerBetweenQueryVariables = Exact<{
  employerId: Scalars['uuid'];
  yearFrom: Scalars['float8'];
  yearUntil: Scalars['float8'];
  monthFrom: Scalars['float8'];
  monthUntil?: InputMaybe<Scalars['float8']>;
}>;


export type GetAdminReportByEmployerBetweenQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportByGymQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type GetAdminReportByGymQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportByGymBetweenQueryVariables = Exact<{
  gymId: Scalars['uuid'];
  yearFrom: Scalars['float8'];
  yearUntil: Scalars['float8'];
  monthFrom: Scalars['float8'];
  monthUntil?: InputMaybe<Scalars['float8']>;
}>;


export type GetAdminReportByGymBetweenQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportByEmployerAndGymQueryVariables = Exact<{
  employerId: Scalars['uuid'];
  gymId: Scalars['uuid'];
}>;


export type GetAdminReportByEmployerAndGymQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetAdminReportByEmployerAndGymBetweenQueryVariables = Exact<{
  employerId: Scalars['uuid'];
  gymId: Scalars['uuid'];
  yearFrom: Scalars['float8'];
  yearUntil: Scalars['float8'];
  monthFrom: Scalars['float8'];
  monthUntil?: InputMaybe<Scalars['float8']>;
}>;


export type GetAdminReportByEmployerAndGymBetweenQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, employerId?: any | null, employer?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetGymReportQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type GetGymReportQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type GetGymReportBetweenQueryVariables = Exact<{
  gymId: Scalars['uuid'];
  yearFrom: Scalars['float8'];
  yearUntil: Scalars['float8'];
  monthFrom: Scalars['float8'];
  monthUntil?: InputMaybe<Scalars['float8']>;
}>;


export type GetGymReportBetweenQuery = { __typename?: 'query_root', employee_gym_checkin_by_month: Array<{ __typename?: 'employee_gym_checkin_by_month', employeeId?: any | null, employee?: string | null, gymId?: any | null, gym?: string | null, month?: any | null, year?: any | null, count?: any | null }> };

export type ListAdminUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminUsersQuery = { __typename?: 'query_root', getAdminUsers?: { __typename?: 'GetUsersOutput', users: Array<{ __typename?: 'User', id: string, givenName: string, surname: string } | null> } | null };

export type DeleteAdminUserMutationVariables = Exact<{
  userPrincipalName: Scalars['String'];
}>;


export type DeleteAdminUserMutation = { __typename?: 'mutation_root', deleteAdminUser?: { __typename?: 'Output', success: boolean } | null };

export type ListActiveUserInvitesByEmployerQueryVariables = Exact<{
  employerId: Scalars['uuid'];
}>;


export type ListActiveUserInvitesByEmployerQuery = { __typename?: 'query_root', user_invite: Array<{ __typename?: 'user_invite', id: any, given_name: string, surname: string, type: any, status: any, blob_url?: string | null }> };

export type ListActiveUserInvitesByGymQueryVariables = Exact<{
  gymId: Scalars['uuid'];
}>;


export type ListActiveUserInvitesByGymQuery = { __typename?: 'query_root', user_invite: Array<{ __typename?: 'user_invite', id: any, given_name: string, surname: string }> };

export type InsertUserInviteMutationVariables = Exact<{
  userInvites: Array<User_Invite_Insert_Input> | User_Invite_Insert_Input;
}>;


export type InsertUserInviteMutation = { __typename?: 'mutation_root', insert_user_invite?: { __typename?: 'user_invite_mutation_response', returning: Array<{ __typename?: 'user_invite', id: any, code: any, email?: string | null, given_name: string, surname: string }> } | null };

export type RemoveUserInviteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveUserInviteMutation = { __typename?: 'mutation_root', deleteInvite: { __typename?: 'Output', success: boolean } };

export type ProcessUserInvitesMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['uuid']>> | InputMaybe<Scalars['uuid']>;
}>;


export type ProcessUserInvitesMutation = { __typename?: 'mutation_root', processInvites: { __typename?: 'Output', success: boolean } };

export type CreatePdfInvitesArchiveMutationVariables = Exact<{
  employerId: Scalars['uuid'];
  employerName?: InputMaybe<Scalars['String']>;
}>;


export type CreatePdfInvitesArchiveMutation = { __typename?: 'mutation_root', createPdfInvitesArchive: { __typename?: 'Output', success: boolean } };


export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: uuid!, $changes: Address_set_input) {
  update_Address_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const ListArticlesDocument = gql`
    query ListArticles {
  Article {
    id
    name
    global
    header_image
    content_images
  }
}
    `;

/**
 * __useListArticlesQuery__
 *
 * To run a query within a React component, call `useListArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ListArticlesQuery, ListArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListArticlesQuery, ListArticlesQueryVariables>(ListArticlesDocument, options);
      }
export function useListArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListArticlesQuery, ListArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListArticlesQuery, ListArticlesQueryVariables>(ListArticlesDocument, options);
        }
export type ListArticlesQueryHookResult = ReturnType<typeof useListArticlesQuery>;
export type ListArticlesLazyQueryHookResult = ReturnType<typeof useListArticlesLazyQuery>;
export type ListArticlesQueryResult = Apollo.QueryResult<ListArticlesQuery, ListArticlesQueryVariables>;
export const ListAdminArticlesDocument = gql`
    query ListAdminArticles {
  Article {
    id
    name
    global
    Gym {
      name
    }
    Employer {
      name
    }
    header_image
    content_images
  }
}
    `;

/**
 * __useListAdminArticlesQuery__
 *
 * To run a query within a React component, call `useListAdminArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminArticlesQuery, ListAdminArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminArticlesQuery, ListAdminArticlesQueryVariables>(ListAdminArticlesDocument, options);
      }
export function useListAdminArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminArticlesQuery, ListAdminArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminArticlesQuery, ListAdminArticlesQueryVariables>(ListAdminArticlesDocument, options);
        }
export type ListAdminArticlesQueryHookResult = ReturnType<typeof useListAdminArticlesQuery>;
export type ListAdminArticlesLazyQueryHookResult = ReturnType<typeof useListAdminArticlesLazyQuery>;
export type ListAdminArticlesQueryResult = Apollo.QueryResult<ListAdminArticlesQuery, ListAdminArticlesQueryVariables>;
export const ListGlobalArticlesDocument = gql`
    query ListGlobalArticles($global: Boolean) {
  Article(where: {global: {_eq: $global}}) {
    id
    name
    global
    Gym {
      name
    }
    Employer {
      name
    }
    header_image
    content_images
  }
}
    `;

/**
 * __useListGlobalArticlesQuery__
 *
 * To run a query within a React component, call `useListGlobalArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalArticlesQuery({
 *   variables: {
 *      global: // value for 'global'
 *   },
 * });
 */
export function useListGlobalArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ListGlobalArticlesQuery, ListGlobalArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGlobalArticlesQuery, ListGlobalArticlesQueryVariables>(ListGlobalArticlesDocument, options);
      }
export function useListGlobalArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGlobalArticlesQuery, ListGlobalArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGlobalArticlesQuery, ListGlobalArticlesQueryVariables>(ListGlobalArticlesDocument, options);
        }
export type ListGlobalArticlesQueryHookResult = ReturnType<typeof useListGlobalArticlesQuery>;
export type ListGlobalArticlesLazyQueryHookResult = ReturnType<typeof useListGlobalArticlesLazyQuery>;
export type ListGlobalArticlesQueryResult = Apollo.QueryResult<ListGlobalArticlesQuery, ListGlobalArticlesQueryVariables>;
export const ListArticlesByGymDocument = gql`
    query ListArticlesByGym($gymId: uuid!) {
  Article(where: {gym_id: {_eq: $gymId}}) {
    id
    name
    global
    Gym {
      name
    }
    Employer {
      name
    }
    header_image
    content_images
  }
}
    `;

/**
 * __useListArticlesByGymQuery__
 *
 * To run a query within a React component, call `useListArticlesByGymQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesByGymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesByGymQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useListArticlesByGymQuery(baseOptions: Apollo.QueryHookOptions<ListArticlesByGymQuery, ListArticlesByGymQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListArticlesByGymQuery, ListArticlesByGymQueryVariables>(ListArticlesByGymDocument, options);
      }
export function useListArticlesByGymLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListArticlesByGymQuery, ListArticlesByGymQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListArticlesByGymQuery, ListArticlesByGymQueryVariables>(ListArticlesByGymDocument, options);
        }
export type ListArticlesByGymQueryHookResult = ReturnType<typeof useListArticlesByGymQuery>;
export type ListArticlesByGymLazyQueryHookResult = ReturnType<typeof useListArticlesByGymLazyQuery>;
export type ListArticlesByGymQueryResult = Apollo.QueryResult<ListArticlesByGymQuery, ListArticlesByGymQueryVariables>;
export const ListArticlesByEmployerDocument = gql`
    query ListArticlesByEmployer($employerId: uuid!) {
  Article(where: {employer_id: {_eq: $employerId}}) {
    id
    name
    global
    Gym {
      name
    }
    Employer {
      name
    }
    header_image
    content_images
  }
}
    `;

/**
 * __useListArticlesByEmployerQuery__
 *
 * To run a query within a React component, call `useListArticlesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useListArticlesByEmployerQuery(baseOptions: Apollo.QueryHookOptions<ListArticlesByEmployerQuery, ListArticlesByEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListArticlesByEmployerQuery, ListArticlesByEmployerQueryVariables>(ListArticlesByEmployerDocument, options);
      }
export function useListArticlesByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListArticlesByEmployerQuery, ListArticlesByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListArticlesByEmployerQuery, ListArticlesByEmployerQueryVariables>(ListArticlesByEmployerDocument, options);
        }
export type ListArticlesByEmployerQueryHookResult = ReturnType<typeof useListArticlesByEmployerQuery>;
export type ListArticlesByEmployerLazyQueryHookResult = ReturnType<typeof useListArticlesByEmployerLazyQuery>;
export type ListArticlesByEmployerQueryResult = Apollo.QueryResult<ListArticlesByEmployerQuery, ListArticlesByEmployerQueryVariables>;
export const ListArticlesForDashboardDocument = gql`
    query ListArticlesForDashboard {
  Article(limit: 5) {
    id
    name
  }
}
    `;

/**
 * __useListArticlesForDashboardQuery__
 *
 * To run a query within a React component, call `useListArticlesForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useListArticlesForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListArticlesForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useListArticlesForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<ListArticlesForDashboardQuery, ListArticlesForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListArticlesForDashboardQuery, ListArticlesForDashboardQueryVariables>(ListArticlesForDashboardDocument, options);
      }
export function useListArticlesForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListArticlesForDashboardQuery, ListArticlesForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListArticlesForDashboardQuery, ListArticlesForDashboardQueryVariables>(ListArticlesForDashboardDocument, options);
        }
export type ListArticlesForDashboardQueryHookResult = ReturnType<typeof useListArticlesForDashboardQuery>;
export type ListArticlesForDashboardLazyQueryHookResult = ReturnType<typeof useListArticlesForDashboardLazyQuery>;
export type ListArticlesForDashboardQueryResult = Apollo.QueryResult<ListArticlesForDashboardQuery, ListArticlesForDashboardQueryVariables>;
export const GetArticleDocument = gql`
    query GetArticle($id: uuid!) {
  Article_by_pk(id: $id) {
    id
    name
    global
    gym_id
    employer_id
    image_url
    header_image
    content_images
    content
  }
}
    `;

/**
 * __useGetArticleQuery__
 *
 * To run a query within a React component, call `useGetArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArticleQuery(baseOptions: Apollo.QueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options);
      }
export function useGetArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleQuery, GetArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleQuery, GetArticleQueryVariables>(GetArticleDocument, options);
        }
export type GetArticleQueryHookResult = ReturnType<typeof useGetArticleQuery>;
export type GetArticleLazyQueryHookResult = ReturnType<typeof useGetArticleLazyQuery>;
export type GetArticleQueryResult = Apollo.QueryResult<GetArticleQuery, GetArticleQueryVariables>;
export const InsertArticleDocument = gql`
    mutation InsertArticle($article: Article_insert_input!) {
  insert_Article(objects: [$article]) {
    returning {
      id
    }
  }
}
    `;
export type InsertArticleMutationFn = Apollo.MutationFunction<InsertArticleMutation, InsertArticleMutationVariables>;

/**
 * __useInsertArticleMutation__
 *
 * To run a mutation, you first call `useInsertArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertArticleMutation, { data, loading, error }] = useInsertArticleMutation({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useInsertArticleMutation(baseOptions?: Apollo.MutationHookOptions<InsertArticleMutation, InsertArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertArticleMutation, InsertArticleMutationVariables>(InsertArticleDocument, options);
      }
export type InsertArticleMutationHookResult = ReturnType<typeof useInsertArticleMutation>;
export type InsertArticleMutationResult = Apollo.MutationResult<InsertArticleMutation>;
export type InsertArticleMutationOptions = Apollo.BaseMutationOptions<InsertArticleMutation, InsertArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($id: uuid!, $changes: Article_set_input) {
  update_Article_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const RemoveArticleDocument = gql`
    mutation RemoveArticle($id: uuid!) {
  delete_Article(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type RemoveArticleMutationFn = Apollo.MutationFunction<RemoveArticleMutation, RemoveArticleMutationVariables>;

/**
 * __useRemoveArticleMutation__
 *
 * To run a mutation, you first call `useRemoveArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArticleMutation, { data, loading, error }] = useRemoveArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveArticleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveArticleMutation, RemoveArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveArticleMutation, RemoveArticleMutationVariables>(RemoveArticleDocument, options);
      }
export type RemoveArticleMutationHookResult = ReturnType<typeof useRemoveArticleMutation>;
export type RemoveArticleMutationResult = Apollo.MutationResult<RemoveArticleMutation>;
export type RemoveArticleMutationOptions = Apollo.BaseMutationOptions<RemoveArticleMutation, RemoveArticleMutationVariables>;
export const ListEmployeesByEmployerDocument = gql`
    query ListEmployeesByEmployer($employerId: uuid!) {
  Employee(
    where: {_and: {employer_id: {_eq: $employerId}}, deleted_at: {_is_null: true}}
  ) {
    id
    given_name
    surname
  }
}
    `;

/**
 * __useListEmployeesByEmployerQuery__
 *
 * To run a query within a React component, call `useListEmployeesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useListEmployeesByEmployerQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeesByEmployerQuery, ListEmployeesByEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesByEmployerQuery, ListEmployeesByEmployerQueryVariables>(ListEmployeesByEmployerDocument, options);
      }
export function useListEmployeesByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesByEmployerQuery, ListEmployeesByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesByEmployerQuery, ListEmployeesByEmployerQueryVariables>(ListEmployeesByEmployerDocument, options);
        }
export type ListEmployeesByEmployerQueryHookResult = ReturnType<typeof useListEmployeesByEmployerQuery>;
export type ListEmployeesByEmployerLazyQueryHookResult = ReturnType<typeof useListEmployeesByEmployerLazyQuery>;
export type ListEmployeesByEmployerQueryResult = Apollo.QueryResult<ListEmployeesByEmployerQuery, ListEmployeesByEmployerQueryVariables>;
export const ListCheckedInEmployeesDocument = gql`
    query ListCheckedInEmployees($gymId: uuid!) {
  EmployeeGymCheckin(
    where: {_and: {gym_id: {_eq: $gymId}, deleted_at: {_is_null: true}}}
    order_by: {date: desc, employee_id: desc}
    distinct_on: employee_id
  ) {
    Employee {
      id
      given_name
      surname
    }
  }
}
    `;

/**
 * __useListCheckedInEmployeesQuery__
 *
 * To run a query within a React component, call `useListCheckedInEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCheckedInEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCheckedInEmployeesQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useListCheckedInEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListCheckedInEmployeesQuery, ListCheckedInEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCheckedInEmployeesQuery, ListCheckedInEmployeesQueryVariables>(ListCheckedInEmployeesDocument, options);
      }
export function useListCheckedInEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCheckedInEmployeesQuery, ListCheckedInEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCheckedInEmployeesQuery, ListCheckedInEmployeesQueryVariables>(ListCheckedInEmployeesDocument, options);
        }
export type ListCheckedInEmployeesQueryHookResult = ReturnType<typeof useListCheckedInEmployeesQuery>;
export type ListCheckedInEmployeesLazyQueryHookResult = ReturnType<typeof useListCheckedInEmployeesLazyQuery>;
export type ListCheckedInEmployeesQueryResult = Apollo.QueryResult<ListCheckedInEmployeesQuery, ListCheckedInEmployeesQueryVariables>;
export const GetEmployeeDocument = gql`
    query GetEmployee($id: uuid!) {
  Employee_by_pk(id: $id) {
    id
    given_name
    surname
    email
    employer_id
  }
}
    `;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
      }
export function useGetEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const InsertEmployeeDocument = gql`
    mutation InsertEmployee($employee: Employee_insert_input!) {
  insert_Employee(objects: [$employee]) {
    returning {
      id
    }
  }
}
    `;
export type InsertEmployeeMutationFn = Apollo.MutationFunction<InsertEmployeeMutation, InsertEmployeeMutationVariables>;

/**
 * __useInsertEmployeeMutation__
 *
 * To run a mutation, you first call `useInsertEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmployeeMutation, { data, loading, error }] = useInsertEmployeeMutation({
 *   variables: {
 *      employee: // value for 'employee'
 *   },
 * });
 */
export function useInsertEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmployeeMutation, InsertEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmployeeMutation, InsertEmployeeMutationVariables>(InsertEmployeeDocument, options);
      }
export type InsertEmployeeMutationHookResult = ReturnType<typeof useInsertEmployeeMutation>;
export type InsertEmployeeMutationResult = Apollo.MutationResult<InsertEmployeeMutation>;
export type InsertEmployeeMutationOptions = Apollo.BaseMutationOptions<InsertEmployeeMutation, InsertEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($id: uuid!, $changes: Employee_set_input) {
  update_Employee_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeUserDocument = gql`
    mutation DeleteEmployeeUser($employeeId: uuid!) {
  deleteEmployeeUser(employeeId: $employeeId) {
    success
  }
}
    `;
export type DeleteEmployeeUserMutationFn = Apollo.MutationFunction<DeleteEmployeeUserMutation, DeleteEmployeeUserMutationVariables>;

/**
 * __useDeleteEmployeeUserMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeUserMutation, { data, loading, error }] = useDeleteEmployeeUserMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useDeleteEmployeeUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeUserMutation, DeleteEmployeeUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeUserMutation, DeleteEmployeeUserMutationVariables>(DeleteEmployeeUserDocument, options);
      }
export type DeleteEmployeeUserMutationHookResult = ReturnType<typeof useDeleteEmployeeUserMutation>;
export type DeleteEmployeeUserMutationResult = Apollo.MutationResult<DeleteEmployeeUserMutation>;
export type DeleteEmployeeUserMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeUserMutation, DeleteEmployeeUserMutationVariables>;
export const ListEmployersDocument = gql`
    query ListEmployers {
  Employer(where: {deleted_at: {_is_null: true}}) {
    id
    name
    address {
      locality
    }
  }
}
    `;

/**
 * __useListEmployersQuery__
 *
 * To run a query within a React component, call `useListEmployersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployersQuery(baseOptions?: Apollo.QueryHookOptions<ListEmployersQuery, ListEmployersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployersQuery, ListEmployersQueryVariables>(ListEmployersDocument, options);
      }
export function useListEmployersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployersQuery, ListEmployersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployersQuery, ListEmployersQueryVariables>(ListEmployersDocument, options);
        }
export type ListEmployersQueryHookResult = ReturnType<typeof useListEmployersQuery>;
export type ListEmployersLazyQueryHookResult = ReturnType<typeof useListEmployersLazyQuery>;
export type ListEmployersQueryResult = Apollo.QueryResult<ListEmployersQuery, ListEmployersQueryVariables>;
export const ListEmployersForDashboardDocument = gql`
    query ListEmployersForDashboard {
  Employer(where: {deleted_at: {_is_null: true}}, limit: 5) {
    id
    name
  }
}
    `;

/**
 * __useListEmployersForDashboardQuery__
 *
 * To run a query within a React component, call `useListEmployersForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployersForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployersForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployersForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<ListEmployersForDashboardQuery, ListEmployersForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployersForDashboardQuery, ListEmployersForDashboardQueryVariables>(ListEmployersForDashboardDocument, options);
      }
export function useListEmployersForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployersForDashboardQuery, ListEmployersForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployersForDashboardQuery, ListEmployersForDashboardQueryVariables>(ListEmployersForDashboardDocument, options);
        }
export type ListEmployersForDashboardQueryHookResult = ReturnType<typeof useListEmployersForDashboardQuery>;
export type ListEmployersForDashboardLazyQueryHookResult = ReturnType<typeof useListEmployersForDashboardLazyQuery>;
export type ListEmployersForDashboardQueryResult = Apollo.QueryResult<ListEmployersForDashboardQuery, ListEmployersForDashboardQueryVariables>;
export const GetEmployerDocument = gql`
    query GetEmployer($id: uuid!) {
  Employer_by_pk(id: $id) {
    address {
      id
      address_line_1
      address_line_2
      address_line_3
      address_line_4
      zip_code
      locality
      state
      location
    }
    email_address
    contact_given_name
    contact_surname
    homepage_url
    id
    name
    phone_number
    image
    user_invite_archive_blob_url
  }
}
    `;

/**
 * __useGetEmployerQuery__
 *
 * To run a query within a React component, call `useGetEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployerQuery(baseOptions: Apollo.QueryHookOptions<GetEmployerQuery, GetEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployerQuery, GetEmployerQueryVariables>(GetEmployerDocument, options);
      }
export function useGetEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployerQuery, GetEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployerQuery, GetEmployerQueryVariables>(GetEmployerDocument, options);
        }
export type GetEmployerQueryHookResult = ReturnType<typeof useGetEmployerQuery>;
export type GetEmployerLazyQueryHookResult = ReturnType<typeof useGetEmployerLazyQuery>;
export type GetEmployerQueryResult = Apollo.QueryResult<GetEmployerQuery, GetEmployerQueryVariables>;
export const ListEmployerUsersDocument = gql`
    query ListEmployerUsers($employerId: uuid!) {
  getEmployerUsers(employerId: $employerId) {
    users {
      id
      givenName
      surname
    }
  }
}
    `;

/**
 * __useListEmployerUsersQuery__
 *
 * To run a query within a React component, call `useListEmployerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployerUsersQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useListEmployerUsersQuery(baseOptions: Apollo.QueryHookOptions<ListEmployerUsersQuery, ListEmployerUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployerUsersQuery, ListEmployerUsersQueryVariables>(ListEmployerUsersDocument, options);
      }
export function useListEmployerUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployerUsersQuery, ListEmployerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployerUsersQuery, ListEmployerUsersQueryVariables>(ListEmployerUsersDocument, options);
        }
export type ListEmployerUsersQueryHookResult = ReturnType<typeof useListEmployerUsersQuery>;
export type ListEmployerUsersLazyQueryHookResult = ReturnType<typeof useListEmployerUsersLazyQuery>;
export type ListEmployerUsersQueryResult = Apollo.QueryResult<ListEmployerUsersQuery, ListEmployerUsersQueryVariables>;
export const InsertEmployerDocument = gql`
    mutation InsertEmployer($employer: Employer_insert_input!) {
  insert_Employer(objects: [$employer]) {
    returning {
      id
      name
    }
  }
}
    `;
export type InsertEmployerMutationFn = Apollo.MutationFunction<InsertEmployerMutation, InsertEmployerMutationVariables>;

/**
 * __useInsertEmployerMutation__
 *
 * To run a mutation, you first call `useInsertEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmployerMutation, { data, loading, error }] = useInsertEmployerMutation({
 *   variables: {
 *      employer: // value for 'employer'
 *   },
 * });
 */
export function useInsertEmployerMutation(baseOptions?: Apollo.MutationHookOptions<InsertEmployerMutation, InsertEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEmployerMutation, InsertEmployerMutationVariables>(InsertEmployerDocument, options);
      }
export type InsertEmployerMutationHookResult = ReturnType<typeof useInsertEmployerMutation>;
export type InsertEmployerMutationResult = Apollo.MutationResult<InsertEmployerMutation>;
export type InsertEmployerMutationOptions = Apollo.BaseMutationOptions<InsertEmployerMutation, InsertEmployerMutationVariables>;
export const UpdateEmployerDocument = gql`
    mutation UpdateEmployer($id: uuid!, $changes: Employer_set_input) {
  update_Employer_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateEmployerMutationFn = Apollo.MutationFunction<UpdateEmployerMutation, UpdateEmployerMutationVariables>;

/**
 * __useUpdateEmployerMutation__
 *
 * To run a mutation, you first call `useUpdateEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployerMutation, { data, loading, error }] = useUpdateEmployerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateEmployerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployerMutation, UpdateEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployerMutation, UpdateEmployerMutationVariables>(UpdateEmployerDocument, options);
      }
export type UpdateEmployerMutationHookResult = ReturnType<typeof useUpdateEmployerMutation>;
export type UpdateEmployerMutationResult = Apollo.MutationResult<UpdateEmployerMutation>;
export type UpdateEmployerMutationOptions = Apollo.BaseMutationOptions<UpdateEmployerMutation, UpdateEmployerMutationVariables>;
export const DeleteEmployerDocument = gql`
    mutation DeleteEmployer($id: uuid!) {
  update_Employer_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteEmployerMutationFn = Apollo.MutationFunction<DeleteEmployerMutation, DeleteEmployerMutationVariables>;

/**
 * __useDeleteEmployerMutation__
 *
 * To run a mutation, you first call `useDeleteEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployerMutation, { data, loading, error }] = useDeleteEmployerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployerMutation, DeleteEmployerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployerMutation, DeleteEmployerMutationVariables>(DeleteEmployerDocument, options);
      }
export type DeleteEmployerMutationHookResult = ReturnType<typeof useDeleteEmployerMutation>;
export type DeleteEmployerMutationResult = Apollo.MutationResult<DeleteEmployerMutation>;
export type DeleteEmployerMutationOptions = Apollo.BaseMutationOptions<DeleteEmployerMutation, DeleteEmployerMutationVariables>;
export const DeleteEmployerUserDocument = gql`
    mutation DeleteEmployerUser($employerId: uuid!, $userPrincipalName: String!) {
  deleteEmployerUser(
    employerId: $employerId
    userPrincipalName: $userPrincipalName
  ) {
    success
  }
}
    `;
export type DeleteEmployerUserMutationFn = Apollo.MutationFunction<DeleteEmployerUserMutation, DeleteEmployerUserMutationVariables>;

/**
 * __useDeleteEmployerUserMutation__
 *
 * To run a mutation, you first call `useDeleteEmployerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployerUserMutation, { data, loading, error }] = useDeleteEmployerUserMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useDeleteEmployerUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployerUserMutation, DeleteEmployerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployerUserMutation, DeleteEmployerUserMutationVariables>(DeleteEmployerUserDocument, options);
      }
export type DeleteEmployerUserMutationHookResult = ReturnType<typeof useDeleteEmployerUserMutation>;
export type DeleteEmployerUserMutationResult = Apollo.MutationResult<DeleteEmployerUserMutation>;
export type DeleteEmployerUserMutationOptions = Apollo.BaseMutationOptions<DeleteEmployerUserMutation, DeleteEmployerUserMutationVariables>;
export const ListEventsDocument = gql`
    query ListEvents {
  Event {
    id
    name
    image
    global
  }
}
    `;

/**
 * __useListEventsQuery__
 *
 * To run a query within a React component, call `useListEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEventsQuery(baseOptions?: Apollo.QueryHookOptions<ListEventsQuery, ListEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEventsQuery, ListEventsQueryVariables>(ListEventsDocument, options);
      }
export function useListEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEventsQuery, ListEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEventsQuery, ListEventsQueryVariables>(ListEventsDocument, options);
        }
export type ListEventsQueryHookResult = ReturnType<typeof useListEventsQuery>;
export type ListEventsLazyQueryHookResult = ReturnType<typeof useListEventsLazyQuery>;
export type ListEventsQueryResult = Apollo.QueryResult<ListEventsQuery, ListEventsQueryVariables>;
export const ListAdminEventsDocument = gql`
    query ListAdminEvents {
  Event {
    id
    name
    image
    global
    Gym {
      name
    }
    Employer {
      name
    }
  }
}
    `;

/**
 * __useListAdminEventsQuery__
 *
 * To run a query within a React component, call `useListAdminEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminEventsQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminEventsQuery, ListAdminEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminEventsQuery, ListAdminEventsQueryVariables>(ListAdminEventsDocument, options);
      }
export function useListAdminEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminEventsQuery, ListAdminEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminEventsQuery, ListAdminEventsQueryVariables>(ListAdminEventsDocument, options);
        }
export type ListAdminEventsQueryHookResult = ReturnType<typeof useListAdminEventsQuery>;
export type ListAdminEventsLazyQueryHookResult = ReturnType<typeof useListAdminEventsLazyQuery>;
export type ListAdminEventsQueryResult = Apollo.QueryResult<ListAdminEventsQuery, ListAdminEventsQueryVariables>;
export const ListGlobalEventsDocument = gql`
    query ListGlobalEvents($global: Boolean) {
  Event(where: {global: {_eq: $global}}) {
    id
    name
    image
    global
    Gym {
      name
    }
    Employer {
      name
    }
  }
}
    `;

/**
 * __useListGlobalEventsQuery__
 *
 * To run a query within a React component, call `useListGlobalEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalEventsQuery({
 *   variables: {
 *      global: // value for 'global'
 *   },
 * });
 */
export function useListGlobalEventsQuery(baseOptions?: Apollo.QueryHookOptions<ListGlobalEventsQuery, ListGlobalEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGlobalEventsQuery, ListGlobalEventsQueryVariables>(ListGlobalEventsDocument, options);
      }
export function useListGlobalEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGlobalEventsQuery, ListGlobalEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGlobalEventsQuery, ListGlobalEventsQueryVariables>(ListGlobalEventsDocument, options);
        }
export type ListGlobalEventsQueryHookResult = ReturnType<typeof useListGlobalEventsQuery>;
export type ListGlobalEventsLazyQueryHookResult = ReturnType<typeof useListGlobalEventsLazyQuery>;
export type ListGlobalEventsQueryResult = Apollo.QueryResult<ListGlobalEventsQuery, ListGlobalEventsQueryVariables>;
export const ListEventsByGymDocument = gql`
    query ListEventsByGym($gymId: uuid!) {
  Event(where: {gym_id: {_eq: $gymId}}) {
    id
    name
    image
    global
    Gym {
      name
    }
    Employer {
      name
    }
  }
}
    `;

/**
 * __useListEventsByGymQuery__
 *
 * To run a query within a React component, call `useListEventsByGymQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventsByGymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventsByGymQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useListEventsByGymQuery(baseOptions: Apollo.QueryHookOptions<ListEventsByGymQuery, ListEventsByGymQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEventsByGymQuery, ListEventsByGymQueryVariables>(ListEventsByGymDocument, options);
      }
export function useListEventsByGymLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEventsByGymQuery, ListEventsByGymQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEventsByGymQuery, ListEventsByGymQueryVariables>(ListEventsByGymDocument, options);
        }
export type ListEventsByGymQueryHookResult = ReturnType<typeof useListEventsByGymQuery>;
export type ListEventsByGymLazyQueryHookResult = ReturnType<typeof useListEventsByGymLazyQuery>;
export type ListEventsByGymQueryResult = Apollo.QueryResult<ListEventsByGymQuery, ListEventsByGymQueryVariables>;
export const ListEventsByEmployerDocument = gql`
    query ListEventsByEmployer($employerId: uuid!) {
  Event(where: {employer_id: {_eq: $employerId}}) {
    id
    name
    image
    global
    Gym {
      name
    }
    Employer {
      name
    }
  }
}
    `;

/**
 * __useListEventsByEmployerQuery__
 *
 * To run a query within a React component, call `useListEventsByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventsByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventsByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useListEventsByEmployerQuery(baseOptions: Apollo.QueryHookOptions<ListEventsByEmployerQuery, ListEventsByEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEventsByEmployerQuery, ListEventsByEmployerQueryVariables>(ListEventsByEmployerDocument, options);
      }
export function useListEventsByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEventsByEmployerQuery, ListEventsByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEventsByEmployerQuery, ListEventsByEmployerQueryVariables>(ListEventsByEmployerDocument, options);
        }
export type ListEventsByEmployerQueryHookResult = ReturnType<typeof useListEventsByEmployerQuery>;
export type ListEventsByEmployerLazyQueryHookResult = ReturnType<typeof useListEventsByEmployerLazyQuery>;
export type ListEventsByEmployerQueryResult = Apollo.QueryResult<ListEventsByEmployerQuery, ListEventsByEmployerQueryVariables>;
export const ListEventsForDashboardDocument = gql`
    query ListEventsForDashboard {
  Event(limit: 5) {
    id
    name
  }
}
    `;

/**
 * __useListEventsForDashboardQuery__
 *
 * To run a query within a React component, call `useListEventsForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventsForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventsForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEventsForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<ListEventsForDashboardQuery, ListEventsForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEventsForDashboardQuery, ListEventsForDashboardQueryVariables>(ListEventsForDashboardDocument, options);
      }
export function useListEventsForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEventsForDashboardQuery, ListEventsForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEventsForDashboardQuery, ListEventsForDashboardQueryVariables>(ListEventsForDashboardDocument, options);
        }
export type ListEventsForDashboardQueryHookResult = ReturnType<typeof useListEventsForDashboardQuery>;
export type ListEventsForDashboardLazyQueryHookResult = ReturnType<typeof useListEventsForDashboardLazyQuery>;
export type ListEventsForDashboardQueryResult = Apollo.QueryResult<ListEventsForDashboardQuery, ListEventsForDashboardQueryVariables>;
export const GetEventDocument = gql`
    query GetEvent($id: uuid!) {
  Event_by_pk(id: $id) {
    id
    name
    global
    gym_id
    employer_id
    description
    tags
    image
    address {
      id
      address_line_1
      address_line_2
      address_line_3
      address_line_4
      zip_code
      locality
      state
      location
    }
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const InsertEventDocument = gql`
    mutation InsertEvent($event: Event_insert_input!) {
  insert_Event(objects: [$event]) {
    returning {
      id
    }
  }
}
    `;
export type InsertEventMutationFn = Apollo.MutationFunction<InsertEventMutation, InsertEventMutationVariables>;

/**
 * __useInsertEventMutation__
 *
 * To run a mutation, you first call `useInsertEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEventMutation, { data, loading, error }] = useInsertEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useInsertEventMutation(baseOptions?: Apollo.MutationHookOptions<InsertEventMutation, InsertEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEventMutation, InsertEventMutationVariables>(InsertEventDocument, options);
      }
export type InsertEventMutationHookResult = ReturnType<typeof useInsertEventMutation>;
export type InsertEventMutationResult = Apollo.MutationResult<InsertEventMutation>;
export type InsertEventMutationOptions = Apollo.BaseMutationOptions<InsertEventMutation, InsertEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($id: uuid!, $changes: Event_set_input) {
  update_Event_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const RemoveEventDocument = gql`
    mutation RemoveEvent($event: uuid!) {
  delete_Event(where: {id: {_eq: $event}}) {
    returning {
      id
    }
  }
}
    `;
export type RemoveEventMutationFn = Apollo.MutationFunction<RemoveEventMutation, RemoveEventMutationVariables>;

/**
 * __useRemoveEventMutation__
 *
 * To run a mutation, you first call `useRemoveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventMutation, { data, loading, error }] = useRemoveEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useRemoveEventMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventMutation, RemoveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventMutation, RemoveEventMutationVariables>(RemoveEventDocument, options);
      }
export type RemoveEventMutationHookResult = ReturnType<typeof useRemoveEventMutation>;
export type RemoveEventMutationResult = Apollo.MutationResult<RemoveEventMutation>;
export type RemoveEventMutationOptions = Apollo.BaseMutationOptions<RemoveEventMutation, RemoveEventMutationVariables>;
export const ListEventDatesByEventDocument = gql`
    query ListEventDatesByEvent($eventId: uuid!) {
  EventDate(where: {event_id: {_eq: $eventId}}) {
    id
    date
    rrule
  }
}
    `;

/**
 * __useListEventDatesByEventQuery__
 *
 * To run a query within a React component, call `useListEventDatesByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventDatesByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventDatesByEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useListEventDatesByEventQuery(baseOptions: Apollo.QueryHookOptions<ListEventDatesByEventQuery, ListEventDatesByEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEventDatesByEventQuery, ListEventDatesByEventQueryVariables>(ListEventDatesByEventDocument, options);
      }
export function useListEventDatesByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEventDatesByEventQuery, ListEventDatesByEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEventDatesByEventQuery, ListEventDatesByEventQueryVariables>(ListEventDatesByEventDocument, options);
        }
export type ListEventDatesByEventQueryHookResult = ReturnType<typeof useListEventDatesByEventQuery>;
export type ListEventDatesByEventLazyQueryHookResult = ReturnType<typeof useListEventDatesByEventLazyQuery>;
export type ListEventDatesByEventQueryResult = Apollo.QueryResult<ListEventDatesByEventQuery, ListEventDatesByEventQueryVariables>;
export const ListEventDateEmployeesDocument = gql`
    query ListEventDateEmployees($eventDateId: uuid!) {
  EmployeeEventDate(where: {event_date_id: {_eq: $eventDateId}}) {
    employee {
      id
      given_name
      surname
    }
  }
}
    `;

/**
 * __useListEventDateEmployeesQuery__
 *
 * To run a query within a React component, call `useListEventDateEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventDateEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventDateEmployeesQuery({
 *   variables: {
 *      eventDateId: // value for 'eventDateId'
 *   },
 * });
 */
export function useListEventDateEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListEventDateEmployeesQuery, ListEventDateEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEventDateEmployeesQuery, ListEventDateEmployeesQueryVariables>(ListEventDateEmployeesDocument, options);
      }
export function useListEventDateEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEventDateEmployeesQuery, ListEventDateEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEventDateEmployeesQuery, ListEventDateEmployeesQueryVariables>(ListEventDateEmployeesDocument, options);
        }
export type ListEventDateEmployeesQueryHookResult = ReturnType<typeof useListEventDateEmployeesQuery>;
export type ListEventDateEmployeesLazyQueryHookResult = ReturnType<typeof useListEventDateEmployeesLazyQuery>;
export type ListEventDateEmployeesQueryResult = Apollo.QueryResult<ListEventDateEmployeesQuery, ListEventDateEmployeesQueryVariables>;
export const GetEventDateDocument = gql`
    query GetEventDate($id: uuid!) {
  EventDate_by_pk(id: $id) {
    id
    date
    rrule
    address {
      id
      address_line_1
      address_line_2
      address_line_3
      address_line_4
      zip_code
      locality
      state
      location
    }
  }
}
    `;

/**
 * __useGetEventDateQuery__
 *
 * To run a query within a React component, call `useGetEventDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventDateQuery(baseOptions: Apollo.QueryHookOptions<GetEventDateQuery, GetEventDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventDateQuery, GetEventDateQueryVariables>(GetEventDateDocument, options);
      }
export function useGetEventDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventDateQuery, GetEventDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventDateQuery, GetEventDateQueryVariables>(GetEventDateDocument, options);
        }
export type GetEventDateQueryHookResult = ReturnType<typeof useGetEventDateQuery>;
export type GetEventDateLazyQueryHookResult = ReturnType<typeof useGetEventDateLazyQuery>;
export type GetEventDateQueryResult = Apollo.QueryResult<GetEventDateQuery, GetEventDateQueryVariables>;
export const InsertEventDateDocument = gql`
    mutation InsertEventDate($eventDate: EventDate_insert_input!) {
  insert_EventDate(objects: [$eventDate]) {
    returning {
      id
    }
  }
}
    `;
export type InsertEventDateMutationFn = Apollo.MutationFunction<InsertEventDateMutation, InsertEventDateMutationVariables>;

/**
 * __useInsertEventDateMutation__
 *
 * To run a mutation, you first call `useInsertEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEventDateMutation, { data, loading, error }] = useInsertEventDateMutation({
 *   variables: {
 *      eventDate: // value for 'eventDate'
 *   },
 * });
 */
export function useInsertEventDateMutation(baseOptions?: Apollo.MutationHookOptions<InsertEventDateMutation, InsertEventDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEventDateMutation, InsertEventDateMutationVariables>(InsertEventDateDocument, options);
      }
export type InsertEventDateMutationHookResult = ReturnType<typeof useInsertEventDateMutation>;
export type InsertEventDateMutationResult = Apollo.MutationResult<InsertEventDateMutation>;
export type InsertEventDateMutationOptions = Apollo.BaseMutationOptions<InsertEventDateMutation, InsertEventDateMutationVariables>;
export const UpdateEventDateDocument = gql`
    mutation UpdateEventDate($id: uuid!, $changes: EventDate_set_input) {
  update_EventDate_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateEventDateMutationFn = Apollo.MutationFunction<UpdateEventDateMutation, UpdateEventDateMutationVariables>;

/**
 * __useUpdateEventDateMutation__
 *
 * To run a mutation, you first call `useUpdateEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDateMutation, { data, loading, error }] = useUpdateEventDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateEventDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDateMutation, UpdateEventDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDateMutation, UpdateEventDateMutationVariables>(UpdateEventDateDocument, options);
      }
export type UpdateEventDateMutationHookResult = ReturnType<typeof useUpdateEventDateMutation>;
export type UpdateEventDateMutationResult = Apollo.MutationResult<UpdateEventDateMutation>;
export type UpdateEventDateMutationOptions = Apollo.BaseMutationOptions<UpdateEventDateMutation, UpdateEventDateMutationVariables>;
export const RemoveEventDateDocument = gql`
    mutation RemoveEventDate($id: uuid!) {
  delete_EventDate(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type RemoveEventDateMutationFn = Apollo.MutationFunction<RemoveEventDateMutation, RemoveEventDateMutationVariables>;

/**
 * __useRemoveEventDateMutation__
 *
 * To run a mutation, you first call `useRemoveEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventDateMutation, { data, loading, error }] = useRemoveEventDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEventDateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEventDateMutation, RemoveEventDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEventDateMutation, RemoveEventDateMutationVariables>(RemoveEventDateDocument, options);
      }
export type RemoveEventDateMutationHookResult = ReturnType<typeof useRemoveEventDateMutation>;
export type RemoveEventDateMutationResult = Apollo.MutationResult<RemoveEventDateMutation>;
export type RemoveEventDateMutationOptions = Apollo.BaseMutationOptions<RemoveEventDateMutation, RemoveEventDateMutationVariables>;
export const ListGymsDocument = gql`
    query ListGyms {
  Gym(where: {deleted_at: {_is_null: true}}) {
    id
    name
    address {
      locality
    }
  }
}
    `;

/**
 * __useListGymsQuery__
 *
 * To run a query within a React component, call `useListGymsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGymsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGymsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGymsQuery(baseOptions?: Apollo.QueryHookOptions<ListGymsQuery, ListGymsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGymsQuery, ListGymsQueryVariables>(ListGymsDocument, options);
      }
export function useListGymsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGymsQuery, ListGymsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGymsQuery, ListGymsQueryVariables>(ListGymsDocument, options);
        }
export type ListGymsQueryHookResult = ReturnType<typeof useListGymsQuery>;
export type ListGymsLazyQueryHookResult = ReturnType<typeof useListGymsLazyQuery>;
export type ListGymsQueryResult = Apollo.QueryResult<ListGymsQuery, ListGymsQueryVariables>;
export const ListGymsForDashboardDocument = gql`
    query ListGymsForDashboard {
  Gym(where: {deleted_at: {_is_null: true}}, limit: 5) {
    id
    name
  }
}
    `;

/**
 * __useListGymsForDashboardQuery__
 *
 * To run a query within a React component, call `useListGymsForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGymsForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGymsForDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useListGymsForDashboardQuery(baseOptions?: Apollo.QueryHookOptions<ListGymsForDashboardQuery, ListGymsForDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGymsForDashboardQuery, ListGymsForDashboardQueryVariables>(ListGymsForDashboardDocument, options);
      }
export function useListGymsForDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGymsForDashboardQuery, ListGymsForDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGymsForDashboardQuery, ListGymsForDashboardQueryVariables>(ListGymsForDashboardDocument, options);
        }
export type ListGymsForDashboardQueryHookResult = ReturnType<typeof useListGymsForDashboardQuery>;
export type ListGymsForDashboardLazyQueryHookResult = ReturnType<typeof useListGymsForDashboardLazyQuery>;
export type ListGymsForDashboardQueryResult = Apollo.QueryResult<ListGymsForDashboardQuery, ListGymsForDashboardQueryVariables>;
export const GetGymDocument = gql`
    query GetGym($id: uuid!) {
  Gym_by_pk(id: $id) {
    address {
      id
      address_line_1
      address_line_2
      address_line_3
      address_line_4
      zip_code
      locality
      state
      location
    }
    description
    email_address
    group
    homepage_url
    id
    contact_given_name
    contact_surname
    name
    phone_number
    tags
    image
    checkin_code_blob_url
    background_image
  }
}
    `;

/**
 * __useGetGymQuery__
 *
 * To run a query within a React component, call `useGetGymQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGymQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGymQuery(baseOptions: Apollo.QueryHookOptions<GetGymQuery, GetGymQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGymQuery, GetGymQueryVariables>(GetGymDocument, options);
      }
export function useGetGymLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGymQuery, GetGymQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGymQuery, GetGymQueryVariables>(GetGymDocument, options);
        }
export type GetGymQueryHookResult = ReturnType<typeof useGetGymQuery>;
export type GetGymLazyQueryHookResult = ReturnType<typeof useGetGymLazyQuery>;
export type GetGymQueryResult = Apollo.QueryResult<GetGymQuery, GetGymQueryVariables>;
export const ListGymUsersDocument = gql`
    query ListGymUsers($gymId: uuid!) {
  getGymUsers(gymId: $gymId) {
    users {
      id
      givenName
      surname
    }
  }
}
    `;

/**
 * __useListGymUsersQuery__
 *
 * To run a query within a React component, call `useListGymUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGymUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGymUsersQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useListGymUsersQuery(baseOptions: Apollo.QueryHookOptions<ListGymUsersQuery, ListGymUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGymUsersQuery, ListGymUsersQueryVariables>(ListGymUsersDocument, options);
      }
export function useListGymUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGymUsersQuery, ListGymUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGymUsersQuery, ListGymUsersQueryVariables>(ListGymUsersDocument, options);
        }
export type ListGymUsersQueryHookResult = ReturnType<typeof useListGymUsersQuery>;
export type ListGymUsersLazyQueryHookResult = ReturnType<typeof useListGymUsersLazyQuery>;
export type ListGymUsersQueryResult = Apollo.QueryResult<ListGymUsersQuery, ListGymUsersQueryVariables>;
export const InsertGymDocument = gql`
    mutation InsertGym($gym: Gym_insert_input!) {
  insert_Gym(objects: [$gym]) {
    returning {
      id
    }
  }
}
    `;
export type InsertGymMutationFn = Apollo.MutationFunction<InsertGymMutation, InsertGymMutationVariables>;

/**
 * __useInsertGymMutation__
 *
 * To run a mutation, you first call `useInsertGymMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGymMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGymMutation, { data, loading, error }] = useInsertGymMutation({
 *   variables: {
 *      gym: // value for 'gym'
 *   },
 * });
 */
export function useInsertGymMutation(baseOptions?: Apollo.MutationHookOptions<InsertGymMutation, InsertGymMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGymMutation, InsertGymMutationVariables>(InsertGymDocument, options);
      }
export type InsertGymMutationHookResult = ReturnType<typeof useInsertGymMutation>;
export type InsertGymMutationResult = Apollo.MutationResult<InsertGymMutation>;
export type InsertGymMutationOptions = Apollo.BaseMutationOptions<InsertGymMutation, InsertGymMutationVariables>;
export const UpdateGymDocument = gql`
    mutation UpdateGym($id: uuid!, $changes: Gym_set_input) {
  update_Gym_by_pk(pk_columns: {id: $id}, _set: $changes) {
    id
  }
}
    `;
export type UpdateGymMutationFn = Apollo.MutationFunction<UpdateGymMutation, UpdateGymMutationVariables>;

/**
 * __useUpdateGymMutation__
 *
 * To run a mutation, you first call `useUpdateGymMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGymMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGymMutation, { data, loading, error }] = useUpdateGymMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateGymMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGymMutation, UpdateGymMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGymMutation, UpdateGymMutationVariables>(UpdateGymDocument, options);
      }
export type UpdateGymMutationHookResult = ReturnType<typeof useUpdateGymMutation>;
export type UpdateGymMutationResult = Apollo.MutationResult<UpdateGymMutation>;
export type UpdateGymMutationOptions = Apollo.BaseMutationOptions<UpdateGymMutation, UpdateGymMutationVariables>;
export const DeleteGymDocument = gql`
    mutation DeleteGym($id: uuid!) {
  update_Gym_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteGymMutationFn = Apollo.MutationFunction<DeleteGymMutation, DeleteGymMutationVariables>;

/**
 * __useDeleteGymMutation__
 *
 * To run a mutation, you first call `useDeleteGymMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGymMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGymMutation, { data, loading, error }] = useDeleteGymMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGymMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGymMutation, DeleteGymMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGymMutation, DeleteGymMutationVariables>(DeleteGymDocument, options);
      }
export type DeleteGymMutationHookResult = ReturnType<typeof useDeleteGymMutation>;
export type DeleteGymMutationResult = Apollo.MutationResult<DeleteGymMutation>;
export type DeleteGymMutationOptions = Apollo.BaseMutationOptions<DeleteGymMutation, DeleteGymMutationVariables>;
export const DeleteGymUserDocument = gql`
    mutation DeleteGymUser($gymId: uuid!, $userPrincipalName: String!) {
  deleteGymUser(gymId: $gymId, userPrincipalName: $userPrincipalName) {
    success
  }
}
    `;
export type DeleteGymUserMutationFn = Apollo.MutationFunction<DeleteGymUserMutation, DeleteGymUserMutationVariables>;

/**
 * __useDeleteGymUserMutation__
 *
 * To run a mutation, you first call `useDeleteGymUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGymUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGymUserMutation, { data, loading, error }] = useDeleteGymUserMutation({
 *   variables: {
 *      gymId: // value for 'gymId'
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useDeleteGymUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGymUserMutation, DeleteGymUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGymUserMutation, DeleteGymUserMutationVariables>(DeleteGymUserDocument, options);
      }
export type DeleteGymUserMutationHookResult = ReturnType<typeof useDeleteGymUserMutation>;
export type DeleteGymUserMutationResult = Apollo.MutationResult<DeleteGymUserMutation>;
export type DeleteGymUserMutationOptions = Apollo.BaseMutationOptions<DeleteGymUserMutation, DeleteGymUserMutationVariables>;
export const GetContainerUriDocument = gql`
    query GetContainerUri($fileName: String!) {
  getContainerUri(fileName: $fileName) {
    blobName
    sasUri
  }
}
    `;

/**
 * __useGetContainerUriQuery__
 *
 * To run a query within a React component, call `useGetContainerUriQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerUriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerUriQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useGetContainerUriQuery(baseOptions: Apollo.QueryHookOptions<GetContainerUriQuery, GetContainerUriQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContainerUriQuery, GetContainerUriQueryVariables>(GetContainerUriDocument, options);
      }
export function useGetContainerUriLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContainerUriQuery, GetContainerUriQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContainerUriQuery, GetContainerUriQueryVariables>(GetContainerUriDocument, options);
        }
export type GetContainerUriQueryHookResult = ReturnType<typeof useGetContainerUriQuery>;
export type GetContainerUriLazyQueryHookResult = ReturnType<typeof useGetContainerUriLazyQuery>;
export type GetContainerUriQueryResult = Apollo.QueryResult<GetContainerUriQuery, GetContainerUriQueryVariables>;
export const GetAdminReportDocument = gql`
    query GetAdminReport {
  employee_gym_checkin_by_month(order_by: {year: desc, month: desc}) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportQuery__
 *
 * To run a query within a React component, call `useGetAdminReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminReportQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminReportQuery, GetAdminReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportQuery, GetAdminReportQueryVariables>(GetAdminReportDocument, options);
      }
export function useGetAdminReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportQuery, GetAdminReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportQuery, GetAdminReportQueryVariables>(GetAdminReportDocument, options);
        }
export type GetAdminReportQueryHookResult = ReturnType<typeof useGetAdminReportQuery>;
export type GetAdminReportLazyQueryHookResult = ReturnType<typeof useGetAdminReportLazyQuery>;
export type GetAdminReportQueryResult = Apollo.QueryResult<GetAdminReportQuery, GetAdminReportQueryVariables>;
export const GetAdminReportBetweenDocument = gql`
    query GetAdminReportBetween($yearFrom: float8!, $yearUntil: float8!, $monthFrom: float8!, $monthUntil: float8!) {
  employee_gym_checkin_by_month(
    where: {_and: {month: {_lte: $monthUntil, _gte: $monthFrom}, year: {_gte: $yearFrom, _lte: $yearUntil}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportBetweenQuery__
 *
 * To run a query within a React component, call `useGetAdminReportBetweenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportBetweenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportBetweenQuery({
 *   variables: {
 *      yearFrom: // value for 'yearFrom'
 *      yearUntil: // value for 'yearUntil'
 *      monthFrom: // value for 'monthFrom'
 *      monthUntil: // value for 'monthUntil'
 *   },
 * });
 */
export function useGetAdminReportBetweenQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportBetweenQuery, GetAdminReportBetweenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportBetweenQuery, GetAdminReportBetweenQueryVariables>(GetAdminReportBetweenDocument, options);
      }
export function useGetAdminReportBetweenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportBetweenQuery, GetAdminReportBetweenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportBetweenQuery, GetAdminReportBetweenQueryVariables>(GetAdminReportBetweenDocument, options);
        }
export type GetAdminReportBetweenQueryHookResult = ReturnType<typeof useGetAdminReportBetweenQuery>;
export type GetAdminReportBetweenLazyQueryHookResult = ReturnType<typeof useGetAdminReportBetweenLazyQuery>;
export type GetAdminReportBetweenQueryResult = Apollo.QueryResult<GetAdminReportBetweenQuery, GetAdminReportBetweenQueryVariables>;
export const GetAdminReportByEmployerDocument = gql`
    query GetAdminReportByEmployer($employerId: uuid!) {
  employee_gym_checkin_by_month(
    where: {_and: {employerId: {_eq: $employerId}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportByEmployerQuery__
 *
 * To run a query within a React component, call `useGetAdminReportByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useGetAdminReportByEmployerQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportByEmployerQuery, GetAdminReportByEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportByEmployerQuery, GetAdminReportByEmployerQueryVariables>(GetAdminReportByEmployerDocument, options);
      }
export function useGetAdminReportByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportByEmployerQuery, GetAdminReportByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportByEmployerQuery, GetAdminReportByEmployerQueryVariables>(GetAdminReportByEmployerDocument, options);
        }
export type GetAdminReportByEmployerQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerQuery>;
export type GetAdminReportByEmployerLazyQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerLazyQuery>;
export type GetAdminReportByEmployerQueryResult = Apollo.QueryResult<GetAdminReportByEmployerQuery, GetAdminReportByEmployerQueryVariables>;
export const GetAdminReportByEmployerBetweenDocument = gql`
    query GetAdminReportByEmployerBetween($employerId: uuid!, $yearFrom: float8!, $yearUntil: float8!, $monthFrom: float8!, $monthUntil: float8) {
  employee_gym_checkin_by_month(
    where: {_and: {employerId: {_eq: $employerId}, month: {_lte: $monthUntil, _gte: $monthFrom}, year: {_gte: $yearFrom, _lte: $yearUntil}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportByEmployerBetweenQuery__
 *
 * To run a query within a React component, call `useGetAdminReportByEmployerBetweenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportByEmployerBetweenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportByEmployerBetweenQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      yearFrom: // value for 'yearFrom'
 *      yearUntil: // value for 'yearUntil'
 *      monthFrom: // value for 'monthFrom'
 *      monthUntil: // value for 'monthUntil'
 *   },
 * });
 */
export function useGetAdminReportByEmployerBetweenQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportByEmployerBetweenQuery, GetAdminReportByEmployerBetweenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportByEmployerBetweenQuery, GetAdminReportByEmployerBetweenQueryVariables>(GetAdminReportByEmployerBetweenDocument, options);
      }
export function useGetAdminReportByEmployerBetweenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportByEmployerBetweenQuery, GetAdminReportByEmployerBetweenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportByEmployerBetweenQuery, GetAdminReportByEmployerBetweenQueryVariables>(GetAdminReportByEmployerBetweenDocument, options);
        }
export type GetAdminReportByEmployerBetweenQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerBetweenQuery>;
export type GetAdminReportByEmployerBetweenLazyQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerBetweenLazyQuery>;
export type GetAdminReportByEmployerBetweenQueryResult = Apollo.QueryResult<GetAdminReportByEmployerBetweenQuery, GetAdminReportByEmployerBetweenQueryVariables>;
export const GetAdminReportByGymDocument = gql`
    query GetAdminReportByGym($gymId: uuid!) {
  employee_gym_checkin_by_month(
    where: {_and: {gymId: {_eq: $gymId}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportByGymQuery__
 *
 * To run a query within a React component, call `useGetAdminReportByGymQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportByGymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportByGymQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useGetAdminReportByGymQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportByGymQuery, GetAdminReportByGymQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportByGymQuery, GetAdminReportByGymQueryVariables>(GetAdminReportByGymDocument, options);
      }
export function useGetAdminReportByGymLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportByGymQuery, GetAdminReportByGymQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportByGymQuery, GetAdminReportByGymQueryVariables>(GetAdminReportByGymDocument, options);
        }
export type GetAdminReportByGymQueryHookResult = ReturnType<typeof useGetAdminReportByGymQuery>;
export type GetAdminReportByGymLazyQueryHookResult = ReturnType<typeof useGetAdminReportByGymLazyQuery>;
export type GetAdminReportByGymQueryResult = Apollo.QueryResult<GetAdminReportByGymQuery, GetAdminReportByGymQueryVariables>;
export const GetAdminReportByGymBetweenDocument = gql`
    query GetAdminReportByGymBetween($gymId: uuid!, $yearFrom: float8!, $yearUntil: float8!, $monthFrom: float8!, $monthUntil: float8) {
  employee_gym_checkin_by_month(
    where: {_and: {gymId: {_eq: $gymId}, month: {_lte: $monthUntil, _gte: $monthFrom}, year: {_gte: $yearFrom, _lte: $yearUntil}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportByGymBetweenQuery__
 *
 * To run a query within a React component, call `useGetAdminReportByGymBetweenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportByGymBetweenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportByGymBetweenQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *      yearFrom: // value for 'yearFrom'
 *      yearUntil: // value for 'yearUntil'
 *      monthFrom: // value for 'monthFrom'
 *      monthUntil: // value for 'monthUntil'
 *   },
 * });
 */
export function useGetAdminReportByGymBetweenQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportByGymBetweenQuery, GetAdminReportByGymBetweenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportByGymBetweenQuery, GetAdminReportByGymBetweenQueryVariables>(GetAdminReportByGymBetweenDocument, options);
      }
export function useGetAdminReportByGymBetweenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportByGymBetweenQuery, GetAdminReportByGymBetweenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportByGymBetweenQuery, GetAdminReportByGymBetweenQueryVariables>(GetAdminReportByGymBetweenDocument, options);
        }
export type GetAdminReportByGymBetweenQueryHookResult = ReturnType<typeof useGetAdminReportByGymBetweenQuery>;
export type GetAdminReportByGymBetweenLazyQueryHookResult = ReturnType<typeof useGetAdminReportByGymBetweenLazyQuery>;
export type GetAdminReportByGymBetweenQueryResult = Apollo.QueryResult<GetAdminReportByGymBetweenQuery, GetAdminReportByGymBetweenQueryVariables>;
export const GetAdminReportByEmployerAndGymDocument = gql`
    query GetAdminReportByEmployerAndGym($employerId: uuid!, $gymId: uuid!) {
  employee_gym_checkin_by_month(
    where: {_and: {employerId: {_eq: $employerId}, gymId: {_eq: $gymId}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportByEmployerAndGymQuery__
 *
 * To run a query within a React component, call `useGetAdminReportByEmployerAndGymQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportByEmployerAndGymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportByEmployerAndGymQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useGetAdminReportByEmployerAndGymQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportByEmployerAndGymQuery, GetAdminReportByEmployerAndGymQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportByEmployerAndGymQuery, GetAdminReportByEmployerAndGymQueryVariables>(GetAdminReportByEmployerAndGymDocument, options);
      }
export function useGetAdminReportByEmployerAndGymLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportByEmployerAndGymQuery, GetAdminReportByEmployerAndGymQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportByEmployerAndGymQuery, GetAdminReportByEmployerAndGymQueryVariables>(GetAdminReportByEmployerAndGymDocument, options);
        }
export type GetAdminReportByEmployerAndGymQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerAndGymQuery>;
export type GetAdminReportByEmployerAndGymLazyQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerAndGymLazyQuery>;
export type GetAdminReportByEmployerAndGymQueryResult = Apollo.QueryResult<GetAdminReportByEmployerAndGymQuery, GetAdminReportByEmployerAndGymQueryVariables>;
export const GetAdminReportByEmployerAndGymBetweenDocument = gql`
    query GetAdminReportByEmployerAndGymBetween($employerId: uuid!, $gymId: uuid!, $yearFrom: float8!, $yearUntil: float8!, $monthFrom: float8!, $monthUntil: float8) {
  employee_gym_checkin_by_month(
    where: {_and: {employerId: {_eq: $employerId}, gymId: {_eq: $gymId}, month: {_lte: $monthUntil, _gte: $monthFrom}, year: {_gte: $yearFrom, _lte: $yearUntil}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    employerId
    employer
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetAdminReportByEmployerAndGymBetweenQuery__
 *
 * To run a query within a React component, call `useGetAdminReportByEmployerAndGymBetweenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminReportByEmployerAndGymBetweenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminReportByEmployerAndGymBetweenQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      gymId: // value for 'gymId'
 *      yearFrom: // value for 'yearFrom'
 *      yearUntil: // value for 'yearUntil'
 *      monthFrom: // value for 'monthFrom'
 *      monthUntil: // value for 'monthUntil'
 *   },
 * });
 */
export function useGetAdminReportByEmployerAndGymBetweenQuery(baseOptions: Apollo.QueryHookOptions<GetAdminReportByEmployerAndGymBetweenQuery, GetAdminReportByEmployerAndGymBetweenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminReportByEmployerAndGymBetweenQuery, GetAdminReportByEmployerAndGymBetweenQueryVariables>(GetAdminReportByEmployerAndGymBetweenDocument, options);
      }
export function useGetAdminReportByEmployerAndGymBetweenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminReportByEmployerAndGymBetweenQuery, GetAdminReportByEmployerAndGymBetweenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminReportByEmployerAndGymBetweenQuery, GetAdminReportByEmployerAndGymBetweenQueryVariables>(GetAdminReportByEmployerAndGymBetweenDocument, options);
        }
export type GetAdminReportByEmployerAndGymBetweenQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerAndGymBetweenQuery>;
export type GetAdminReportByEmployerAndGymBetweenLazyQueryHookResult = ReturnType<typeof useGetAdminReportByEmployerAndGymBetweenLazyQuery>;
export type GetAdminReportByEmployerAndGymBetweenQueryResult = Apollo.QueryResult<GetAdminReportByEmployerAndGymBetweenQuery, GetAdminReportByEmployerAndGymBetweenQueryVariables>;
export const GetGymReportDocument = gql`
    query GetGymReport($gymId: uuid!) {
  employee_gym_checkin_by_month(
    where: {gymId: {_eq: $gymId}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetGymReportQuery__
 *
 * To run a query within a React component, call `useGetGymReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGymReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGymReportQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useGetGymReportQuery(baseOptions: Apollo.QueryHookOptions<GetGymReportQuery, GetGymReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGymReportQuery, GetGymReportQueryVariables>(GetGymReportDocument, options);
      }
export function useGetGymReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGymReportQuery, GetGymReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGymReportQuery, GetGymReportQueryVariables>(GetGymReportDocument, options);
        }
export type GetGymReportQueryHookResult = ReturnType<typeof useGetGymReportQuery>;
export type GetGymReportLazyQueryHookResult = ReturnType<typeof useGetGymReportLazyQuery>;
export type GetGymReportQueryResult = Apollo.QueryResult<GetGymReportQuery, GetGymReportQueryVariables>;
export const GetGymReportBetweenDocument = gql`
    query GetGymReportBetween($gymId: uuid!, $yearFrom: float8!, $yearUntil: float8!, $monthFrom: float8!, $monthUntil: float8) {
  employee_gym_checkin_by_month(
    where: {_and: {gymId: {_eq: $gymId}, month: {_lte: $monthUntil, _gte: $monthFrom}, year: {_gte: $yearFrom, _lte: $yearUntil}}}
    order_by: {year: desc, month: desc}
  ) {
    employeeId
    employee
    gymId
    gym
    month
    year
    count
  }
}
    `;

/**
 * __useGetGymReportBetweenQuery__
 *
 * To run a query within a React component, call `useGetGymReportBetweenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGymReportBetweenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGymReportBetweenQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *      yearFrom: // value for 'yearFrom'
 *      yearUntil: // value for 'yearUntil'
 *      monthFrom: // value for 'monthFrom'
 *      monthUntil: // value for 'monthUntil'
 *   },
 * });
 */
export function useGetGymReportBetweenQuery(baseOptions: Apollo.QueryHookOptions<GetGymReportBetweenQuery, GetGymReportBetweenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGymReportBetweenQuery, GetGymReportBetweenQueryVariables>(GetGymReportBetweenDocument, options);
      }
export function useGetGymReportBetweenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGymReportBetweenQuery, GetGymReportBetweenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGymReportBetweenQuery, GetGymReportBetweenQueryVariables>(GetGymReportBetweenDocument, options);
        }
export type GetGymReportBetweenQueryHookResult = ReturnType<typeof useGetGymReportBetweenQuery>;
export type GetGymReportBetweenLazyQueryHookResult = ReturnType<typeof useGetGymReportBetweenLazyQuery>;
export type GetGymReportBetweenQueryResult = Apollo.QueryResult<GetGymReportBetweenQuery, GetGymReportBetweenQueryVariables>;
export const ListAdminUsersDocument = gql`
    query ListAdminUsers {
  getAdminUsers {
    users {
      id
      givenName
      surname
    }
  }
}
    `;

/**
 * __useListAdminUsersQuery__
 *
 * To run a query within a React component, call `useListAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(ListAdminUsersDocument, options);
      }
export function useListAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminUsersQuery, ListAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(ListAdminUsersDocument, options);
        }
export type ListAdminUsersQueryHookResult = ReturnType<typeof useListAdminUsersQuery>;
export type ListAdminUsersLazyQueryHookResult = ReturnType<typeof useListAdminUsersLazyQuery>;
export type ListAdminUsersQueryResult = Apollo.QueryResult<ListAdminUsersQuery, ListAdminUsersQueryVariables>;
export const DeleteAdminUserDocument = gql`
    mutation DeleteAdminUser($userPrincipalName: String!) {
  deleteAdminUser(userPrincipalName: $userPrincipalName) {
    success
  }
}
    `;
export type DeleteAdminUserMutationFn = Apollo.MutationFunction<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(DeleteAdminUserDocument, options);
      }
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>;
export type DeleteAdminUserMutationResult = Apollo.MutationResult<DeleteAdminUserMutation>;
export type DeleteAdminUserMutationOptions = Apollo.BaseMutationOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;
export const ListActiveUserInvitesByEmployerDocument = gql`
    query ListActiveUserInvitesByEmployer($employerId: uuid!) {
  user_invite(where: {employer_id: {_eq: $employerId}, is_valid: {_eq: true}}) {
    id
    given_name
    surname
    type
    status
    blob_url
  }
}
    `;

/**
 * __useListActiveUserInvitesByEmployerQuery__
 *
 * To run a query within a React component, call `useListActiveUserInvitesByEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActiveUserInvitesByEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActiveUserInvitesByEmployerQuery({
 *   variables: {
 *      employerId: // value for 'employerId'
 *   },
 * });
 */
export function useListActiveUserInvitesByEmployerQuery(baseOptions: Apollo.QueryHookOptions<ListActiveUserInvitesByEmployerQuery, ListActiveUserInvitesByEmployerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActiveUserInvitesByEmployerQuery, ListActiveUserInvitesByEmployerQueryVariables>(ListActiveUserInvitesByEmployerDocument, options);
      }
export function useListActiveUserInvitesByEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActiveUserInvitesByEmployerQuery, ListActiveUserInvitesByEmployerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActiveUserInvitesByEmployerQuery, ListActiveUserInvitesByEmployerQueryVariables>(ListActiveUserInvitesByEmployerDocument, options);
        }
export type ListActiveUserInvitesByEmployerQueryHookResult = ReturnType<typeof useListActiveUserInvitesByEmployerQuery>;
export type ListActiveUserInvitesByEmployerLazyQueryHookResult = ReturnType<typeof useListActiveUserInvitesByEmployerLazyQuery>;
export type ListActiveUserInvitesByEmployerQueryResult = Apollo.QueryResult<ListActiveUserInvitesByEmployerQuery, ListActiveUserInvitesByEmployerQueryVariables>;
export const ListActiveUserInvitesByGymDocument = gql`
    query ListActiveUserInvitesByGym($gymId: uuid!) {
  user_invite(where: {gym_id: {_eq: $gymId}, is_valid: {_eq: true}}) {
    id
    given_name
    surname
  }
}
    `;

/**
 * __useListActiveUserInvitesByGymQuery__
 *
 * To run a query within a React component, call `useListActiveUserInvitesByGymQuery` and pass it any options that fit your needs.
 * When your component renders, `useListActiveUserInvitesByGymQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListActiveUserInvitesByGymQuery({
 *   variables: {
 *      gymId: // value for 'gymId'
 *   },
 * });
 */
export function useListActiveUserInvitesByGymQuery(baseOptions: Apollo.QueryHookOptions<ListActiveUserInvitesByGymQuery, ListActiveUserInvitesByGymQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListActiveUserInvitesByGymQuery, ListActiveUserInvitesByGymQueryVariables>(ListActiveUserInvitesByGymDocument, options);
      }
export function useListActiveUserInvitesByGymLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListActiveUserInvitesByGymQuery, ListActiveUserInvitesByGymQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListActiveUserInvitesByGymQuery, ListActiveUserInvitesByGymQueryVariables>(ListActiveUserInvitesByGymDocument, options);
        }
export type ListActiveUserInvitesByGymQueryHookResult = ReturnType<typeof useListActiveUserInvitesByGymQuery>;
export type ListActiveUserInvitesByGymLazyQueryHookResult = ReturnType<typeof useListActiveUserInvitesByGymLazyQuery>;
export type ListActiveUserInvitesByGymQueryResult = Apollo.QueryResult<ListActiveUserInvitesByGymQuery, ListActiveUserInvitesByGymQueryVariables>;
export const InsertUserInviteDocument = gql`
    mutation InsertUserInvite($userInvites: [user_invite_insert_input!]!) {
  insert_user_invite(objects: $userInvites) {
    returning {
      id
      code
      email
      given_name
      surname
    }
  }
}
    `;
export type InsertUserInviteMutationFn = Apollo.MutationFunction<InsertUserInviteMutation, InsertUserInviteMutationVariables>;

/**
 * __useInsertUserInviteMutation__
 *
 * To run a mutation, you first call `useInsertUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserInviteMutation, { data, loading, error }] = useInsertUserInviteMutation({
 *   variables: {
 *      userInvites: // value for 'userInvites'
 *   },
 * });
 */
export function useInsertUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertUserInviteMutation, InsertUserInviteMutationVariables>(InsertUserInviteDocument, options);
      }
export type InsertUserInviteMutationHookResult = ReturnType<typeof useInsertUserInviteMutation>;
export type InsertUserInviteMutationResult = Apollo.MutationResult<InsertUserInviteMutation>;
export type InsertUserInviteMutationOptions = Apollo.BaseMutationOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>;
export const RemoveUserInviteDocument = gql`
    mutation RemoveUserInvite($id: uuid!) {
  deleteInvite(inviteId: $id) {
    success
  }
}
    `;
export type RemoveUserInviteMutationFn = Apollo.MutationFunction<RemoveUserInviteMutation, RemoveUserInviteMutationVariables>;

/**
 * __useRemoveUserInviteMutation__
 *
 * To run a mutation, you first call `useRemoveUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserInviteMutation, { data, loading, error }] = useRemoveUserInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserInviteMutation, RemoveUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserInviteMutation, RemoveUserInviteMutationVariables>(RemoveUserInviteDocument, options);
      }
export type RemoveUserInviteMutationHookResult = ReturnType<typeof useRemoveUserInviteMutation>;
export type RemoveUserInviteMutationResult = Apollo.MutationResult<RemoveUserInviteMutation>;
export type RemoveUserInviteMutationOptions = Apollo.BaseMutationOptions<RemoveUserInviteMutation, RemoveUserInviteMutationVariables>;
export const ProcessUserInvitesDocument = gql`
    mutation ProcessUserInvites($ids: [uuid]!) {
  processInvites(inviteIds: $ids) {
    success
  }
}
    `;
export type ProcessUserInvitesMutationFn = Apollo.MutationFunction<ProcessUserInvitesMutation, ProcessUserInvitesMutationVariables>;

/**
 * __useProcessUserInvitesMutation__
 *
 * To run a mutation, you first call `useProcessUserInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessUserInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processUserInvitesMutation, { data, loading, error }] = useProcessUserInvitesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useProcessUserInvitesMutation(baseOptions?: Apollo.MutationHookOptions<ProcessUserInvitesMutation, ProcessUserInvitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessUserInvitesMutation, ProcessUserInvitesMutationVariables>(ProcessUserInvitesDocument, options);
      }
export type ProcessUserInvitesMutationHookResult = ReturnType<typeof useProcessUserInvitesMutation>;
export type ProcessUserInvitesMutationResult = Apollo.MutationResult<ProcessUserInvitesMutation>;
export type ProcessUserInvitesMutationOptions = Apollo.BaseMutationOptions<ProcessUserInvitesMutation, ProcessUserInvitesMutationVariables>;
export const CreatePdfInvitesArchiveDocument = gql`
    mutation CreatePdfInvitesArchive($employerId: uuid!, $employerName: String) {
  createPdfInvitesArchive(employerId: $employerId, employerName: $employerName) {
    success
  }
}
    `;
export type CreatePdfInvitesArchiveMutationFn = Apollo.MutationFunction<CreatePdfInvitesArchiveMutation, CreatePdfInvitesArchiveMutationVariables>;

/**
 * __useCreatePdfInvitesArchiveMutation__
 *
 * To run a mutation, you first call `useCreatePdfInvitesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePdfInvitesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPdfInvitesArchiveMutation, { data, loading, error }] = useCreatePdfInvitesArchiveMutation({
 *   variables: {
 *      employerId: // value for 'employerId'
 *      employerName: // value for 'employerName'
 *   },
 * });
 */
export function useCreatePdfInvitesArchiveMutation(baseOptions?: Apollo.MutationHookOptions<CreatePdfInvitesArchiveMutation, CreatePdfInvitesArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePdfInvitesArchiveMutation, CreatePdfInvitesArchiveMutationVariables>(CreatePdfInvitesArchiveDocument, options);
      }
export type CreatePdfInvitesArchiveMutationHookResult = ReturnType<typeof useCreatePdfInvitesArchiveMutation>;
export type CreatePdfInvitesArchiveMutationResult = Apollo.MutationResult<CreatePdfInvitesArchiveMutation>;
export type CreatePdfInvitesArchiveMutationOptions = Apollo.BaseMutationOptions<CreatePdfInvitesArchiveMutation, CreatePdfInvitesArchiveMutationVariables>;
export const namedOperations = {
  Query: {
    ListArticles: 'ListArticles',
    ListAdminArticles: 'ListAdminArticles',
    ListGlobalArticles: 'ListGlobalArticles',
    ListArticlesByGym: 'ListArticlesByGym',
    ListArticlesByEmployer: 'ListArticlesByEmployer',
    ListArticlesForDashboard: 'ListArticlesForDashboard',
    GetArticle: 'GetArticle',
    ListEmployeesByEmployer: 'ListEmployeesByEmployer',
    ListCheckedInEmployees: 'ListCheckedInEmployees',
    GetEmployee: 'GetEmployee',
    ListEmployers: 'ListEmployers',
    ListEmployersForDashboard: 'ListEmployersForDashboard',
    GetEmployer: 'GetEmployer',
    ListEmployerUsers: 'ListEmployerUsers',
    ListEvents: 'ListEvents',
    ListAdminEvents: 'ListAdminEvents',
    ListGlobalEvents: 'ListGlobalEvents',
    ListEventsByGym: 'ListEventsByGym',
    ListEventsByEmployer: 'ListEventsByEmployer',
    ListEventsForDashboard: 'ListEventsForDashboard',
    GetEvent: 'GetEvent',
    ListEventDatesByEvent: 'ListEventDatesByEvent',
    ListEventDateEmployees: 'ListEventDateEmployees',
    GetEventDate: 'GetEventDate',
    ListGyms: 'ListGyms',
    ListGymsForDashboard: 'ListGymsForDashboard',
    GetGym: 'GetGym',
    ListGymUsers: 'ListGymUsers',
    GetContainerUri: 'GetContainerUri',
    GetAdminReport: 'GetAdminReport',
    GetAdminReportBetween: 'GetAdminReportBetween',
    GetAdminReportByEmployer: 'GetAdminReportByEmployer',
    GetAdminReportByEmployerBetween: 'GetAdminReportByEmployerBetween',
    GetAdminReportByGym: 'GetAdminReportByGym',
    GetAdminReportByGymBetween: 'GetAdminReportByGymBetween',
    GetAdminReportByEmployerAndGym: 'GetAdminReportByEmployerAndGym',
    GetAdminReportByEmployerAndGymBetween: 'GetAdminReportByEmployerAndGymBetween',
    GetGymReport: 'GetGymReport',
    GetGymReportBetween: 'GetGymReportBetween',
    ListAdminUsers: 'ListAdminUsers',
    ListActiveUserInvitesByEmployer: 'ListActiveUserInvitesByEmployer',
    ListActiveUserInvitesByGym: 'ListActiveUserInvitesByGym'
  },
  Mutation: {
    UpdateAddress: 'UpdateAddress',
    InsertArticle: 'InsertArticle',
    UpdateArticle: 'UpdateArticle',
    RemoveArticle: 'RemoveArticle',
    InsertEmployee: 'InsertEmployee',
    UpdateEmployee: 'UpdateEmployee',
    DeleteEmployeeUser: 'DeleteEmployeeUser',
    InsertEmployer: 'InsertEmployer',
    UpdateEmployer: 'UpdateEmployer',
    DeleteEmployer: 'DeleteEmployer',
    DeleteEmployerUser: 'DeleteEmployerUser',
    InsertEvent: 'InsertEvent',
    UpdateEvent: 'UpdateEvent',
    RemoveEvent: 'RemoveEvent',
    InsertEventDate: 'InsertEventDate',
    UpdateEventDate: 'UpdateEventDate',
    RemoveEventDate: 'RemoveEventDate',
    InsertGym: 'InsertGym',
    UpdateGym: 'UpdateGym',
    DeleteGym: 'DeleteGym',
    DeleteGymUser: 'DeleteGymUser',
    DeleteAdminUser: 'DeleteAdminUser',
    InsertUserInvite: 'InsertUserInvite',
    RemoveUserInvite: 'RemoveUserInvite',
    ProcessUserInvites: 'ProcessUserInvites',
    CreatePdfInvitesArchive: 'CreatePdfInvitesArchive'
  }
}