import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { msalConfig } from 'src/authConfig';
import { Register } from 'src/components/auth/register';
import { RegisterFailed } from 'src/components/auth/register/failed';
import RegisterSuccess from 'src/components/auth/register/success';
import Unauthenticated from 'src/components/auth/unauthenticated';
import Dashboard from 'src/components/dashboard';
import Layout from 'src/components/layout';

const ListGyms = lazy(() => import('src/components/gym'));
const CreateGym = lazy(() => import('src/components/gym/create'));
const EditGym = lazy(() => import('src/components/gym/edit'));
const InviteGymManager = lazy(() => import('src/components/gym/inviteManager'));

const ListEmployers = lazy(() => import('src/components/employer'));
const CreateEmployer = lazy(() => import('src/components/employer/create'));
const EditEmployer = lazy(() => import('src/components/employer/edit'));
const InviteEmployerManager = lazy(() => import('src/components/employer/inviteManager'));
const InviteEmployee = lazy(() => import('src/components/employer/inviteEmployee'));

const ListEmployees = lazy(() => import('src/components/employee'));

const ListEvents = lazy(() => import('src/components/event'));
const CreateEvent = lazy(() => import('src/components/event/create'));
const EditEvent = lazy(() => import('src/components/event/edit'));
const CreateEventDate = lazy(() => import('src/components/event/eventDate/create'));
const EditEventDate = lazy(() => import('src/components/event/eventDate/edit'));

const ListArticles = lazy(() => import('src/components/article'));
const CreateArticle = lazy(() => import('src/components/article/create'));
const EditArticle = lazy(() => import('src/components/article/edit'));

const Reporting = lazy(() => import('src/components/reporting'));

const ListManagers = lazy(() => import('src/components/manager'));
const InviteManager = lazy(() => import('src/components/manager/inviteManager'));

const msalInstance = new PublicClientApplication(msalConfig);

function App()
{
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/inviteEmployee" element={<InviteEmployee />} />
            <Route path="/gyms">
              <Route index element={<ListGyms title="Studios" />} />
              <Route path="create" element={<CreateGym />} />
              <Route path=":id" element={<EditGym />} />
              <Route path=":id/inviteManager" element={<InviteGymManager />} />
            </Route>
            <Route path="/employers">
              <Route index element={<ListEmployers title="Arbeitgeber" />} />
              <Route path="create" element={<CreateEmployer />} />
              <Route path=":id" element={<EditEmployer />} />
              <Route path=":id/inviteManager" element={<InviteEmployerManager />} />
              <Route path=":id/inviteEmployee" element={<InviteEmployee />} />
            </Route>
            <Route path="/employees">
              <Route index element={<ListEmployees />} />
              <Route path="inviteEmployee" element={<InviteEmployee />} />
            </Route>
            <Route path="/events">
              <Route index element={<ListEvents />} />
              <Route path="create" element={<CreateEvent />} />
              <Route path=":id" element={<EditEvent />} />
              <Route path=":id/eventDates">
                <Route path="create" element={<CreateEventDate />} />
                <Route path=":id" element={<EditEventDate />} />
              </Route>
            </Route>
            <Route path="/articles">
              <Route index element={<ListArticles />} />
              <Route path="create" element={<CreateArticle />} />
              <Route path=":id" element={<EditArticle />} />F
            </Route>
            <Route path="/reporting">
              <Route index element={<Reporting title="Reporting" />} />
            </Route>
            <Route path="/managers">
              <Route index element={<ListManagers />} />
              <Route path="inviteManager" element={<InviteManager />} />
            </Route>
          </Route>
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route element={<Layout />}>
            <Route path="*" element={<Unauthenticated />} />
            <Route path="/register">
              <Route index element={<Register />} />
              <Route path="success" element={<RegisterSuccess />} />
              <Route path="failed" element={<RegisterFailed />} />
            </Route>
          </Route>
        </Routes>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
