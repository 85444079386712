import Unauthenticated from 'src/components/auth/unauthenticated';
import AdminDashboard from 'src/components/dashboard/admin';
import ListEmployerEmployees from 'src/components/employer/listEmployees';
import ListGymEmployees from 'src/components/gym/listEmployees';
import useUser from 'src/helpers/useUser';

function Dashboard() {
  const user = useUser();
  if (user && user?.role === 'admin') return <AdminDashboard title="Dashboard" />;
  else if (user && user?.role === 'gym') return <ListGymEmployees gymId={user.gymId ?? ''} title="Teilnehmer" />;
  else if (user && user?.role === 'employer') return <ListEmployerEmployees employerId={user.employerId ?? ''} title="Teilnehmer" />;
  else return <Unauthenticated />;
}

export default Dashboard;
