import {Alert, Spinner} from 'react-bootstrap';
import {useListCheckedInEmployeesQuery} from 'src/api/generated/graphql';
import Layout from 'src/components/ui/layout';
import Table from 'src/components/ui/table/table';

type ListEmployeesProps = {
  title: string;
  gymId: string;
};

const columns = [
  {
    field: 'id',
    text: 'ID',
    numeric: true,
    hidden: true,
  },
  {
    field: 'surname',
    text: 'Nachname',
    sort: 'asc',
  },
  {
    field: 'given_name',
    text: 'Vorname',
  },
];

function ListEmployees({title, gymId}: ListEmployeesProps) {
  const {loading, error, data} = useListCheckedInEmployeesQuery({variables: {gymId}});
  return (
    <Layout title={title}>
      {error && <Alert variant="danger">{error.message}</Alert>}
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {data && <Table columns={columns} data={data.EmployeeGymCheckin.map(egc => egc.Employee)} />}
    </Layout>
  );
}

export default ListEmployees;
