import {faBuildings, faCalendar, faChartArea, faChartLine, faDumbbell, faNewspaper, faUser} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

function AdminNavigation() {
  return (
    <Nav className="me-auto">
      <Nav.Link as={Link} to="/">
        <FontAwesomeIcon icon={faChartLine} />
        <span> Dashboard</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/gyms">
        <FontAwesomeIcon icon={faDumbbell} />
        <span> Studios</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/employers">
        <FontAwesomeIcon icon={faBuildings} />
        <span> Arbeitgeber</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/events">
        <FontAwesomeIcon icon={faCalendar} />
        <span> Veranstaltungen</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/articles">
        <FontAwesomeIcon icon={faNewspaper} />
        <span> Artikel</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/reporting">
        <FontAwesomeIcon icon={faChartArea} />
        <span> Reporting</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/managers">
        <FontAwesomeIcon icon={faUser} />
        <span> Administratoren</span>
      </Nav.Link>
    </Nav>
  );
}

export default AdminNavigation;
