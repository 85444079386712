import {faBriefcase, faCalendar, faNewspaper, faPeopleGroup} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

function EmployerNavigation() {
  return (
    <Nav className="me-auto">
      <Nav.Link as={Link} to="/employees">
        <FontAwesomeIcon icon={faPeopleGroup} />
        <span> Teilnehmer</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/managers">
        <FontAwesomeIcon icon={faBriefcase} />
        <span> Administratoren</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/events">
        <FontAwesomeIcon icon={faCalendar} />
        <span> Veranstaltungen</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/articles">
        <FontAwesomeIcon icon={faNewspaper} />
        <span> Artikel</span>
      </Nav.Link>
    </Nav>
  );
}

export default EmployerNavigation;
