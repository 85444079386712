import {faBuildings, faCalendar, faDumbbell, faNewspaper} from '@fortawesome/pro-duotone-svg-icons';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Spinner} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Row from 'react-bootstrap/Row';
import {Link, useNavigate} from 'react-router-dom';
import {
  useListArticlesForDashboardQuery,
  useListEmployersForDashboardQuery,
  useListEventsForDashboardQuery,
  useListGymsForDashboardQuery,
} from 'src/api/generated/graphql';
import Layout from 'src/components/ui/layout';

type DashboardProps = {
  title: string;
};

function Dashboard({title}: DashboardProps) {
  const navigate = useNavigate();
  const {data: gyms, loading: gymsLoading} = useListGymsForDashboardQuery();
  const {data: employers, loading: employersLoading} = useListEmployersForDashboardQuery();
  const {data: events, loading: eventsLoading} = useListEventsForDashboardQuery();
  const {data: articles, loading: articlesLoading} = useListArticlesForDashboardQuery();
  return (
    <Layout title={title}>
      <Row>
        <Col xs={12} md={3}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faDumbbell} />
                <span> Studios</span>
              </Card.Title>
            </Card.Header>
            <ListGroup className="list-group-flush">
              {gymsLoading && (
                <ListGroupItem>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </ListGroupItem>
              )}
              {gyms && gyms.Gym.length < 1 && <ListGroupItem>Keine Studios gefunden.</ListGroupItem>}
              {gyms &&
                gyms.Gym.length > 0 &&
                gyms.Gym.map(gym => (
                  <ListGroupItem key={gym.id as string}>
                    <Row key={gym.id as string}>
                      <Col className="text-truncate">
                        <Card.Link as={Link} to={`/gyms/${gym.id as string}`}>
                          <div className="text-truncate">{gym.name}</div>
                        </Card.Link>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </ListGroup>
            <Card.Footer>
              <Button className="float-end" onClick={() => navigate('/gyms')}>
                Zu den Studios <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faBuildings} />
                <span> Arbeitgeber</span>
              </Card.Title>
            </Card.Header>
            <ListGroup className="list-group-flush">
              {employersLoading && (
                <ListGroupItem>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </ListGroupItem>
              )}
              {employers && employers.Employer.length < 1 && <ListGroupItem>Keine Arbeitgeber gefunden.</ListGroupItem>}
              {employers &&
                employers.Employer.length > 0 &&
                employers.Employer.map(employer => (
                  <ListGroupItem key={employer.id as string}>
                    <Row key={employer.id as string}>
                      <Col className="text-truncate">
                        <Card.Link as={Link} to={`/employers/${employer.id as string}`}>
                          <div className="text-truncate">{employer.name}</div>
                        </Card.Link>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </ListGroup>
            <Card.Footer>
              <Button className="float-end" onClick={() => navigate('/employers')}>
                Zu den Arbeitgebern <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faCalendar} />
                <span> Veranstaltungen</span>
              </Card.Title>
            </Card.Header>
            <ListGroup className="list-group-flush">
              {eventsLoading && (
                <ListGroupItem>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </ListGroupItem>
              )}
              {events && events.Event.length < 1 && <ListGroupItem>Keine Veranstaltungen gefunden.</ListGroupItem>}
              {events &&
                events.Event.length > 0 &&
                events.Event.map(event => (
                  <ListGroupItem key={event.id as string}>
                    <Row key={event.id as string}>
                      <Col className="text-truncate">
                        <Card.Link as={Link} to={`/events/${event.id as string}`}>
                          <div className="text-truncate">{event.name}</div>
                        </Card.Link>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </ListGroup>
            <Card.Footer>
              <Button className="float-end" onClick={() => navigate('/events')}>
                Zu den Veranstaltungen <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FontAwesomeIcon icon={faNewspaper} />
                <span> Artikel</span>
              </Card.Title>
            </Card.Header>
            <ListGroup className="list-group-flush">
              {articlesLoading && (
                <ListGroupItem>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </ListGroupItem>
              )}
              {articles && articles.Article.length < 1 && <ListGroupItem>Keine Artikel gefunden.</ListGroupItem>}
              {articles &&
                articles.Article.length > 0 &&
                articles.Article.map(article => (
                  <ListGroupItem key={article.id as string}>
                    <Row key={article.id as string}>
                      <Col className="text-truncate">
                        <Card.Link as={Link} to={`/articles/${article.id as string}`}>
                          <div className="text-truncate">{article.name}</div>
                        </Card.Link>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </ListGroup>
            <Card.Footer>
              <Button className="float-end" onClick={() => navigate('/articles')}>
                Zu den Artikeln <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default Dashboard;
