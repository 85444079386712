import Layout from 'src/components/ui/layout';

function Unauthenticated() {
  return (
    <Layout title="Sie sind nicht angemeldet">
      <span>Bitte melden Sie sich an, um fortzufahren.</span>
    </Layout>
  );
}

export default Unauthenticated;
