/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons';
import { faDownload, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import BootstrapTable from 'react-bootstrap/Table';
import styles from 'src/components/ui/table/table.module.css';

type TableProps = PropsWithChildren<{
  columns: Array<{
    field: string;
    text: string;
    numeric?: boolean;
    sort?: string;
    hidden?: boolean;
  }>;
  data: Array<any>;
  hasAdd?: boolean;
  addCallback?: Function;
  hasClick?: boolean;
  clickCallback?: Function;
  hasDownload?: boolean;
  downloadCallback?: Function;
  hasDelete?: boolean;
  deleteCallback?: Function;
}>;

function Table({
  columns,
  data,
  hasAdd = false,
  addCallback,
  hasClick = false,
  clickCallback,
  hasDownload = false,
  downloadCallback,
  hasDelete = false,
  deleteCallback,
  children,
}: TableProps) {
  const [internalColumns, setInternalColumns] = useState([...columns]);
  const [internalData, setInternalData] = useState([...data]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  useEffect(() => {
    setInternalData(data);
  }, [data]);
  const click = (id: number) => {
    if (!hasClick) return;
    if (clickCallback) clickCallback(id);
  };
  const add = () => {
    if (!hasAdd) return;
    if (addCallback) addCallback();
  };
  const remove = (event: any) => {
    event.preventDefault();
    if (deleteCallback && deleteId) deleteCallback(deleteId);
    setShowModal(false);
  };
  const download = (id: string) => {
    if (downloadCallback && id) downloadCallback(id);
  };
  const orderBy = (col: { field: string; text: string; numeric?: boolean; sort?: string }) => {
    let ordered;
    const columns = [...internalColumns];
    if (col.numeric) {
      if (col.sort === 'asc') {
        ordered = [...internalData.sort((a, b) => b[col.field] - a[col.field])];
        const index = columns.findIndex(ic => ic.field === col.field);
        columns.forEach(col => (col.sort = undefined));
        columns[index].sort = 'desc';
        setInternalColumns([...columns]);
      } else {
        ordered = internalData.sort((a, b) => a[col.field] - b[col.field]);
        const index = columns.findIndex(ic => ic.field === col.field);
        columns.forEach(col => (col.sort = undefined));
        columns[index].sort = 'asc';
        setInternalColumns([...columns]);
      }
      setInternalData([...ordered]);
    } else {
      if (col.sort === 'asc') {
        ordered = [...internalData];
        ordered = ordered.sort((a, b) => {
          const x = a[col.field].toUpperCase();
          const y = b[col.field].toUpperCase();
          return x === y ? 0 : y > x ? 1 : -1;
        });
        const index = columns.findIndex(ic => ic.field === col.field);
        columns.forEach(col => (col.sort = undefined));
        columns[index].sort = 'desc';
        setInternalColumns([...columns]);
      } else {
        ordered = internalData.sort((a, b) => {
          const x = a[col.field].toUpperCase();
          const y = b[col.field].toUpperCase();
          return x === y ? 0 : x > y ? 1 : -1;
        });
        const index = columns.findIndex(ic => ic.field === col.field);
        columns.forEach(col => (col.sort = undefined));
        columns[index].sort = 'asc';
        setInternalColumns([...columns]);
      }
      setInternalData([...ordered]);
    }
  };
  return (
    <>
      <Modal show={showModal} backdrop="static" keyboard={false}>
        <Modal.Body>
          <span>Wollen Sie den Datensatz wirklich löschen?</span>
          <br />
          <span>Die Aktion kann nicht rückgängig gemacht werden!</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={remove}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>
      {(hasAdd || children) && (
        <Row>
          {hasAdd && (
            <Col xs={2} md={2} className="mb-3">
              <Button variant="primary" size="sm" onClick={add} style={{ display: 'inline-block' }}>
                <FontAwesomeIcon icon={faPlus} />
                <span className={styles['button-padding']}>Hinzufügen</span>
              </Button>
            </Col>
          )}
          <Col xs={10} md={10} className="mb-3">
            {children}
          </Col>
        </Row>
      )}
      <BootstrapTable responsive striped hover size="sm" className={styles.table}>
        <thead>
          <tr>
            {columns
              .filter(col => !col.hidden)
              .map(col => (
                <td key={col.field} className={styles['table-action']} onClick={() => orderBy(col)}>
                  {col.text}
                  <span className="float-end">
                    {col.sort ? (
                      col.sort === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} />
                    )}
                  </span>
                </td>
              ))}
            {hasDelete && <td></td>}
          </tr>
        </thead>
        <tbody>
          {internalData && internalData.map(row => {
            if (row) return (
              <tr key={row.id} className={styles['body-row']} onClick={() => click(row.id)}>
                {columns
                  .filter(col => !col.hidden)
                  .map(col => (
                    <td key={col.field}>{col.numeric ? row[col.field] ?? 0 : row[col.field]}</td>
                  ))}
                {hasDownload && (
                  <td className={styles.fit}>
                    {row.type === 'PDF' && (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={e => {
                          e.stopPropagation();
                          download(row.id);
                        }}
                        disabled={row.status !== 'Abgeschlossen'}>
                        <FontAwesomeIcon icon={faDownload} />
                        <span className="button-padding">Herunterladen</span>
                      </Button>
                    )}
                  </td>
                )}
                {hasDelete && (
                  <td className={styles.fit}>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={e => {
                        e.stopPropagation();
                        setDeleteId(row.id);
                        setShowModal(true);
                      }}>
                      <FontAwesomeIcon icon={faTrash} />
                      <span className="button-padding">Löschen</span>
                    </Button>
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </BootstrapTable>
    </>
  );
}

export default Table;
