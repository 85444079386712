import {IPublicClientApplication} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {NavDropdown} from 'react-bootstrap';

function handleLogout(instance: IPublicClientApplication) {
  instance.logoutPopup().catch(e => {
    console.error(e);
  });
}

export const SignOut = () => {
  const {instance} = useMsal();

  return <NavDropdown.Item onClick={() => handleLogout(instance)}>Abmelden</NavDropdown.Item>;
};
