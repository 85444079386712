import {faArrowLeft, faPrint, faQrcode} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import {Button, Image, Modal} from 'react-bootstrap';

type CheckinCodeProps = {
  url: string;
  type?: 'button' | 'text';
};

function CheckinCode({url, type = 'button'}: CheckinCodeProps) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const printCheckinCode = () => {
    const content = document.getElementById('printCheckinCode');
    const pri = (document.getElementById('ifmcontentstoprint') as HTMLIFrameElement).contentWindow;
    if (!content || !pri) return;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    handleHide();
  };
  return (
    <>
      <iframe id="ifmcontentstoprint" style={{height: '0px', width: '0px', position: 'absolute'}}></iframe>
      <div id="printCheckinCode" hidden>
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <Image src={url} alt="Checkin QR-Code" style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', margin: 'auto'}} />
        </div>
      </div>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Checkin-Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Image src={url} alt="Checkin QR-Code" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            <span className="button-padding">Abbrechen</span>
          </Button>
          <Button variant="primary" onClick={printCheckinCode}>
            <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon>
            <span className="button-padding">Drucken</span>
          </Button>
        </Modal.Footer>
      </Modal>
      {type === 'button' && (
        <Button variant="primary" onClick={handleShow}>
          <FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>
          <span className="button-padding">Checkin-Code</span>
        </Button>
      )}
      {type === 'text' && (
        <div onClick={handleShow}>
          <FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>
          <span className="button-padding">Checkin-Code</span>
        </div>
      )}
    </>
  );
}

export default CheckinCode;
