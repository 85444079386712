import {ApolloClient, ApolloProvider, NormalizedCacheObject} from '@apollo/client';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import {faCircleUser} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import {NavDropdown} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link, Outlet} from 'react-router-dom';
import {SignIn} from 'src/components/auth/signIn';
import {SignOut} from 'src/components/auth/signOut';
import createApolloClient from 'src/helpers/createApolloClient';
import useUser from 'src/helpers/useUser';
import logo from 'src/media/logo.png';
import AdminNavigation from 'src/navs/adminNavigation';
import EmployerNavigation from 'src/navs/employerNavigation';
import GymNavigation from 'src/navs/gymNavigation';

function Layout() {
  const {instance} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();

  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  useEffect(() => {
    setApolloClient(createApolloClient(instance));
  }, [instance]);

  if (isAuthenticated)
    return (
      <>
        {apolloClient && (
          <ApolloProvider client={apolloClient}>
            <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
              <Container fluid>
                <Navbar.Brand as={Link} to="/">
                  <Image src={logo as string} height="48px" alt="JUMP! Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse>
                  {user?.role === 'admin' && <AdminNavigation />}
                  {user?.role === 'employer' && <EmployerNavigation />}
                  {user?.role === 'gym' && <GymNavigation />}
                  <Nav className="me-auto"></Nav>
                  <Nav>
                    <NavDropdown
                      title={
                        <>
                          <FontAwesomeIcon size="lg" icon={faCircleUser} />
                          <span className="button-padding">{user?.displayName}</span>
                        </>
                      }>
                      <SignOut />
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <Outlet />
          </ApolloProvider>
        )}
      </>
    );
  else
    return (
      <>
        <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <Image src={logo as string} height="48px" alt="JUMP! Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse>
              <Nav className="me-auto"></Nav>
              <SignIn />
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Outlet />
      </>
    );
}

export default Layout;
