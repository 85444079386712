import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {AuthenticationResult, IPublicClientApplication} from '@azure/msal-browser';
import {silentRequest} from 'src/authConfig';

function createApolloClient(msalInstance: IPublicClientApplication) {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_GRAPHQL_URL,
  });
  const authLink = setContext(async (_, {headers}) => {
    let token;
    const authentication = await authenticate(msalInstance);
    if (authentication) token = authentication.idToken;
    return {
      headers: {
        ...(headers as Record<string, string>),
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return apolloClient;
}

const authenticate = async (msalInstance: IPublicClientApplication): Promise<AuthenticationResult | null> => {
  const account = msalInstance.getAllAccounts().find(a => a.environment === process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN);
  return account ? await msalInstance.acquireTokenSilent({...silentRequest, account}) : null;
};

export default createApolloClient;
