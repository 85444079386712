import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from 'src/authConfig';
import Layout from 'src/components/ui/layout';

function RegisterSuccess()
{
  const navigate = useNavigate();
  const { instance } = useMsal();
  const handleLogin = (instance: IPublicClientApplication) =>
  {
    instance
      .loginPopup(loginRequest)
      .then(response =>
      {
        instance.setActiveAccount(response.account);
        navigate('/');
      })
      .catch(e =>
      {
        console.error(e);
      });
  };
  return (
    <Layout title='Sie haben sich erfolgreich registriert!'>
      <Button onClick={() => handleLogin(instance)}>Loslegen</Button>
    </Layout>
  );
}

export default RegisterSuccess;
