import { useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { b2cPolicies, loginRequest } from 'src/authConfig';
import { RegisterFailed } from 'src/components/auth/register/failed';
import Layout from 'src/components/ui/layout';

export const Register = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [search] = useSearchParams();
  const register = () => {
    instance
      .loginPopup({
        ...loginRequest,
        authority: `${b2cPolicies.authorities.signUp.authority}`,
        extraQueryParameters: { ['invitation_code']: search.get('code') ?? '' },
      })
      .then(_response => {
        instance.logoutRedirect({ postLogoutRedirectUri: '/register/success' })
          .catch(e => {
            console.error(e);
          });
      })
      .catch(error => {
        console.log(error);
        navigate('/register/failed');
      });
  };
  if (search.get('code'))
    return (
      <Layout title='Möchten Sie sich für JUMP! registrieren?'>
        <Button onClick={register}>Registrieren</Button>
      </Layout>
    );
  else return <RegisterFailed />;
};
