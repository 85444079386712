import {Configuration, PopupRequest, SilentRequest} from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
    signIn: 'B2C_1A_SIGNIN_ONLY',
    signUp: 'B2C_1A_SIGNUP_INVITATION_CODE',
  },
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_AZURE_B2C_1_SIGNIN_AUTHORITY ?? '',
    },
    signUp: {
      authority: process.env.REACT_APP_AZURE_B2C_1_SIGNUP_AUTHORITY ?? '',
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN ?? '',
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? '',
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest: PopupRequest = {
  scopes: ['openid', 'profile', 'email', 'offline_access'],
};

export const silentRequest: SilentRequest = {
  scopes: ['openid', 'profile', 'email', 'offline_access'],
};
