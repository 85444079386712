import {useAccount} from '@azure/msal-react';
import {useEffect, useState} from 'react';

export type User = {
  userId: string;
  role: string;
  displayName: string;
  gymId?: string;
  employerId?: string;
};

type Claims = {
  extension_UserId: string;
  extension_UserRole: string;
  given_name: string;
  family_name: string;
  extension_EmployerId: string;
  extension_GymId: string;
};

function useUser() {
  const account = useAccount();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!account) return;
    const idTokenClaims = account.idTokenClaims as Claims;
    setUser({
      userId: idTokenClaims.extension_UserId,
      role: idTokenClaims.extension_UserRole,
      displayName: `${idTokenClaims.given_name} ${idTokenClaims.family_name}`,
      employerId: idTokenClaims.extension_EmployerId,
      gymId: idTokenClaims.extension_GymId,
    });
  }, [account]);

  return user;
}

export default useUser;
