import {faBriefcase, faCalendar, faChartArea, faNewspaper, faPeopleGroup} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import {Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useGetGymQuery} from 'src/api/generated/graphql';
import CheckinCode from 'src/components/ui/checkinCode';
import useUser from 'src/helpers/useUser';

function GymNavigation() {
  const user = useUser();
  const {data: gymData} = useGetGymQuery({variables: {id: user?.gymId}});
  const [checkinCodeUrl, setCheckinCodeUrl] = useState<string | null>();
  useEffect(() => {
    const gym = gymData?.Gym_by_pk;
    if (gym) {
      if (gym.checkin_code_blob_url) setCheckinCodeUrl(gym.checkin_code_blob_url.replace('abs://', process.env.REACT_APP_BLOB_STORAGE_URL ?? ''));
    }
  }, [gymData]);
  return (
    <Nav className="me-auto">
      <Nav.Link as={Link} to="/employees">
        <FontAwesomeIcon icon={faPeopleGroup} />
        <span> Teilnehmer</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/managers">
        <FontAwesomeIcon icon={faBriefcase} />
        <span> Administratoren</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/events">
        <FontAwesomeIcon icon={faCalendar} />
        <span> Veranstaltungen</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/articles">
        <FontAwesomeIcon icon={faNewspaper} />
        <span> Artikel</span>
      </Nav.Link>
      <Nav.Link as={Link} to="/reporting">
        <FontAwesomeIcon icon={faChartArea} />
        <span> Reporting</span>
      </Nav.Link>
      <Nav.Link>
        <CheckinCode url={checkinCodeUrl ?? ''} type="text" />
      </Nav.Link>
    </Nav>
  );
}

export default GymNavigation;
