import {IPublicClientApplication} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {faCircleUser} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Nav} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {loginRequest} from 'src/authConfig';

export const SignIn = () => {
  const navigate = useNavigate();
  const {instance} = useMsal();
  const handleLogin = (instance: IPublicClientApplication) => {
    instance
      .loginPopup(loginRequest)
      .then(response => {
        instance.setActiveAccount(response.account);
        navigate('/');
      })
      .catch(e => {
        console.error(e);
      });
  };
  return (
    <Nav>
      <Nav.Link className="ml-auto" onClick={() => handleLogin(instance)}>
        <FontAwesomeIcon size="lg" icon={faCircleUser} />
        <span className="button-padding">Anmelden</span>
      </Nav.Link>
    </Nav>
  );
};
