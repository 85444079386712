import { useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { namedOperations, useListActiveUserInvitesByEmployerQuery, useRemoveUserInviteMutation } from 'src/api/generated/graphql';
import InvitationsArchive from 'src/components/employer/invitationsArchive';
import Layout, { ToastVariant } from 'src/components/ui/layout';
import Table from 'src/components/ui/table/table';

type ListInvitationsProps = {
  title: string;
  employerId: string;
};

const columns = [
  {
    field: 'id',
    text: 'ID',
    numeric: true,
    hidden: true,
  },
  {
    field: 'surname',
    text: 'Nachname',
    sort: 'asc',
  },
  {
    field: 'given_name',
    text: 'Vorname',
  },
  {
    field: 'type',
    text: 'Typ',
  },
  {
    field: 'status',
    text: 'Status',
  },
];

function ListInvitations({ title, employerId }: ListInvitationsProps)
{
  const { loading, error, data } = useListActiveUserInvitesByEmployerQuery({ variables: { employerId } });
  const [deleteInvite] = useRemoveUserInviteMutation({ refetchQueries: [namedOperations.Query.ListActiveUserInvitesByEmployer] });

  // Local state
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<ToastVariant>();

  const toast = (message: string, error = false): void =>
  {
    setToastVariant(error ? 'danger' : 'success');
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() =>
    {
      setShowToast(false);
      setToastMessage('');
    }, 5000);
  };

  const deleteCallback = async (id: string) =>
  {
    let deleteResult;
    try
    {
      deleteResult = await deleteInvite({ variables: { id } });
    } catch {
      toast('Einladung konnte nicht gelöscht werden', true);
      return;
    }
    if (!deleteResult) return;
    toast('Einladung wurde gelöscht.');
  };

  const downloadCallback = (id: string) =>
  {
    if (!data) return;
    const invite = data.user_invite.find(e => e.id === id);
    if (!invite || !invite.blob_url) return;
    const url = invite.blob_url.replace('abs://', process.env.REACT_APP_BLOB_STORAGE_URL ?? '');
    window.location.href = url;
  };

  const getType = (type: number) =>
  {
    return {
      0: 'E-Mail',
      1: 'PDF',
    }[type];
  };

  const getStatus = (status: number) =>
  {
    return {
      0: 'Ausstehend',
      1: 'Abgeschlossen',
      2: 'Fehlgeschlagen',
    }[status];
  };

  return (
    <Layout title={title} showToast={showToast} toastMessage={toastMessage} toastVariant={toastVariant}>
      {error && <Alert variant="danger">{error.message}</Alert>}
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <InvitationsArchive employerId={employerId} toast={toast} />
      {data && (
        <Table
          columns={columns}
          data={data.user_invite.map(u => ({
            id: u.id as string,
            surname: u.surname,
            given_name: u.given_name,
            type: getType(u.type as number),
            status: getStatus(u.status as number),
          }))}
          hasDownload
          downloadCallback={downloadCallback}
          hasDelete
          deleteCallback={deleteCallback}
        />
      )}
    </Layout>
  );
}

export default ListInvitations;
