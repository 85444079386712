/* eslint-disable @typescript-eslint/no-misused-promises */
import { faFileDownload, faFileZipper } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useCreatePdfInvitesArchiveMutation, useGetEmployerQuery } from 'src/api/generated/graphql';

interface IInvitationsArchiveProps
{
  employerId: string;
  toast: (message: string, error?: boolean) => void;
}

const InvitationsArchive: React.FunctionComponent<IInvitationsArchiveProps> = ({ employerId, toast }: IInvitationsArchiveProps) =>
{
  const { data } = useGetEmployerQuery({ variables: { id: employerId } });
  const [createPdfInvitesArchive] = useCreatePdfInvitesArchiveMutation();

  const createArchive = async () =>
  {
    if (!data?.Employer_by_pk?.name)
    {
      toast('Fehler beim Erstellen des PDF-Archivs', true);
      return;
    }

    try
    {
      await createPdfInvitesArchive({ variables: { employerId: employerId, employerName: data?.Employer_by_pk?.name } });
      toast('PDF-Archiv wird erstellt');
    } catch {
      toast('Fehler beim Erstellen des PDF-Archivs', true);
      return;
    }
  };

  const downloadArchive = () =>
  {
    if (!data) return;
    const archiveUrl = data.Employer_by_pk?.user_invite_archive_blob_url;
    if (!archiveUrl) return;
    const url = archiveUrl.replace('abs://', process.env.REACT_APP_BLOB_STORAGE_URL ?? '');
    window.location.href = url;
  };

  return (
    <>
      <Button variant="primary" onClick={createArchive}>
        <FontAwesomeIcon icon={faFileZipper} />
        <span className="button-padding">PDF-Archiv erstellen</span>
      </Button>
      <Button
        variant="primary"
        style={{ marginLeft: '0.5vw' }}
        disabled={!data?.Employer_by_pk?.user_invite_archive_blob_url}
        onClick={downloadArchive}
      >
        <FontAwesomeIcon icon={faFileDownload} />
        <span className="button-padding">PDF-Archiv herunterladen</span>
      </Button>
    </>
  );
};

export default InvitationsArchive;
